import { GridApi } from 'ag-grid-community'
import SubsystemArchiveModal from './SubsystemArchiveModal'
import SubsystemDeleteModal from './SubsystemDeleteModal'
import SubsystemEditModal from './SubsystemEditModal'

interface IProps {
    data: Subsystem
    api: GridApi
    allBins: Bin[]
    allSubsystems: Subsystem[]
}

const SubsystemActions = ({ data, api, allBins, allSubsystems }: IProps) => (
    <div style={{ display: 'flex', gridGap: '8px' }}>
        <SubsystemEditModal
            data={data}
            api={api}
            allBins={allBins}
            allSubsystems={allSubsystems}
        />
        <SubsystemDeleteModal data={data} api={api} />
        <SubsystemArchiveModal data={data} api={api} />
    </div>
)

export default SubsystemActions
