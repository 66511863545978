/* eslint-disable max-len */

import { makeStyles } from '@fluentui/react-components'

const useStyles = makeStyles({
    svg: {
        width: '100px',
    },
})

const TrillionLogo = () => {
    const classes = useStyles()
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 731.86 206.68'
            className={classes.svg}
        >
            <polygon
                strokeWidth='0px'
                fill='#FFFFFF'
                points='26.03 63.78 63.03 63.78 63.03 171.57 98.63 171.57 98.63 63.78 135.63 63.78 135.63 35.18 26.03 35.18 26.03 63.78'
            />
            <path
                strokeWidth='0px'
                fill='#FFFFFF'
                d='m172.03,87.98h-.2l-.8-16.4h-29.6c.2,9.2.6,21,.6,52v48h32v-32.2c0-15.4.4-25.2,8-32.8,4.8-4.8,11.8-6.4,19.4-6.4,2,0,5.8.2,7.6.4v-31.8c-1.4-.2-4.2-.4-7-.4-13,0-22.2,5.6-30,19.6Z'
            />
            <rect
                strokeWidth='0px'
                fill='#FFFFFF'
                x='223.63'
                y='71.58'
                width='32'
                height='100'
            />
            <rect
                strokeWidth='0px'
                fill='#FFFFFF'
                x='223.42'
                y='31.18'
                width='32.4'
                height='30'
            />
            <rect
                strokeWidth='0px'
                fill='#FFFFFF'
                x='281.62'
                y='31.18'
                width='32'
                height='140.4'
            />
            <rect
                strokeWidth='0px'
                fill='#FFFFFF'
                x='339.62'
                y='31.18'
                width='32'
                height='140.4'
            />
            <rect
                strokeWidth='0px'
                fill='#FFFFFF'
                x='397.42'
                y='31.18'
                width='32.4'
                height='30'
            />
            <rect
                strokeWidth='0px'
                fill='#FFFFFF'
                x='397.62'
                y='71.58'
                width='32'
                height='100'
            />
            <path
                strokeWidth='0px'
                fill='#FFFFFF'
                d='m503.02,94.98c10.04,0,16.77,6.22,19.09,17.24h34.37c-3.53-28.97-24.16-43.84-53.06-43.84s-49.8,15.65-53.75,43.84h34.27c2.37-11.02,9.2-17.24,19.07-17.24Z'
            />
            <path
                strokeWidth='0px'
                fill='#FFFFFF'
                d='m503.02,148.17c-8.7,0-14.92-4.67-17.93-13.09h-34.87c4.93,26.24,24.93,39.69,52.41,39.69s47.59-14.12,53.01-39.69h-34.72c-3.06,8.42-9.33,13.09-17.89,13.09Z'
            />
            <rect
                strokeWidth='0px'
                fill='#FFFFFF'
                x='576.42'
                y='135.09'
                width='32'
                height='36.49'
            />
            <rect
                strokeWidth='0px'
                fill='#FFFFFF'
                x='643.22'
                y='135.09'
                width='32'
                height='36.49'
            />
            <path
                strokeWidth='0px'
                fill='#FFFFFF'
                d='m615.02,98.58c2.6-2.6,7.2-4.6,12.4-4.6,7.6,0,11.6,3.4,13.6,7.4,1.37,2.74,1.89,5.48,2.09,10.84h32.11v-2.24c0-28.8-14.6-41.6-37.6-41.6-13.4,0-25,5.8-31.4,15.2h-.2l-.6-12h-29.6c.17,7.98.5,17.92.58,40.64h32.58c.8-5.62,2.57-10.17,6.04-13.64Z'
            />
        </svg>
    )
}

export default TrillionLogo
