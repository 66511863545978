import {
    makeStyles,
    mergeClasses,
    shorthands,
} from '@fluentui/react-components'
import { useCallback, useEffect, useState } from 'react'

import { IStatusPanelParams } from 'ag-grid-community'
import { selectDarkMode } from 'slices/preferenceSlice'
import { useAppSelector } from 'store'

type FilterModel = {
    [key: string]: unknown
}

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: '#f0f0f0',
        ...shorthands.padding('0', '16px'),
        marginRight: '8px',
    },
    dark: {
        backgroundColor: '#333',
        color: '#fff',
    },
    hidden: {
        display: 'none',
    },
})

const GridHasFilterMessage = ({ api }: IStatusPanelParams) => {
    const [filterModel, setFilterModel] = useState<FilterModel>({})
    const filterCount = Object.keys(filterModel)?.length
    const classes = useStyles()

    const darkMode = useAppSelector(selectDarkMode)

    const getFilterModel = useCallback(() => {
        setFilterModel(api.getFilterModel())
    }, [api])

    useEffect(() => {
        api.addEventListener('modelUpdated', getFilterModel)
        return () => api.removeEventListener('modelUpdated', getFilterModel)
    }, [api, getFilterModel])

    return (
        <div
            className={
                filterCount <= 0
                    ? classes.hidden
                    : darkMode
                    ? mergeClasses(classes.wrapper, classes.dark)
                    : classes.wrapper
            }
        >
            {filterCount > 1
                ? `There are ${filterCount} active filters on this grid.`
                : `There is ${filterCount} active filter on this grid.`}
        </div>
    )
}

export default GridHasFilterMessage
