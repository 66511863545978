import { Button, Text, makeStyles } from '@fluentui/react-components'
import { useEffect, useState } from 'react'

import { selectDarkMode } from 'slices/preferenceSlice'
import { useAppSelector } from 'store'
import { useGrid } from 'contexts/GridContext'

const useStyle = makeStyles({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        gridGap: '8px',
        height: '100%',
    },
})

const RefreshTimer = () => {
    const [timeSinceLastFetch, setTimeSinceLastFetch] = useState(0)
    const [lastRefresh, setLastRefresh] = useState(new Date())
    const classes = useStyle()

    const darkMode = useAppSelector(selectDarkMode)
    const { gridRef } = useGrid()

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeSinceLastFetch(
                Math.floor(
                    (new Date().valueOf() - lastRefresh.valueOf()) / 1000
                )
            )
        }, 30000)

        return () => clearInterval(timer)
    }, [lastRefresh])

    const lastFetchText = `${Math.floor(timeSinceLastFetch / 60)} minutes and ${
        timeSinceLastFetch % 60
    } seconds since last refresh.`

    return (
        <div className={classes.wrapper}>
            <Button
                onClick={() => {
                    if (gridRef) {
                        gridRef.current?.api.refreshServerSide({ purge: true })
                    }
                    setTimeSinceLastFetch(0)
                    setLastRefresh(new Date())
                }}
            >
                Refresh Data
            </Button>
            <Text
                style={{
                    color:
                        timeSinceLastFetch >= 300
                            ? 'red'
                            : darkMode
                            ? 'white'
                            : 'black',
                }}
            >
                {lastFetchText}
            </Text>
        </div>
    )
}

export default RefreshTimer
