import { forwardRef, useCallback } from 'react'

import DropdownCellEditor from './DropdownCellEditor'
import { Feedback } from 'types/feedback'
import { ICellRendererParams } from 'ag-grid-community'
import { toast } from 'react-toastify'
import { useGetArtsQuery } from 'api/category'

type IArtCellEditor = ICellRendererParams<Feedback> & {
    stopEditing: () => void
    arts: Art[]
}

const ArtCellEditor = forwardRef(
    ({ data, stopEditing }: IArtCellEditor, ref) => {
        const { data: arts } = useGetArtsQuery()

        // stop editing if no Solution is selected
        if (!data.solutionId) {
            toast.info('Please select a Solution first.')
            stopEditing()
        }

        // create a filter function for filtering out arts that are not in the selected Solution
        // create a filter for filtering out subsystems that are not in the selected bin
        const filter = useCallback(
            (item: Art) =>
                item.solutionId === data.solutionId &&
                !item.isDeleted &&
                !item.isArchived,
            [data.solutionId]
        )

        return (
            <DropdownCellEditor<Art>
                data={data}
                filter={filter}
                dataKey='art'
                options={arts}
                forwardRef={ref}
                stopEditing={stopEditing}
            />
        )
    }
)

ArtCellEditor.displayName = 'Art Cell Editor'

export default ArtCellEditor
