import {
    BLANK_FILTER,
    DELETED_FILTER,
    MAX_FILTER_LENGTH,
} from 'constants/common'
import { forwardRef, useImperativeHandle, useState } from 'react'
import {
    useGetArtsQuery,
    useGetBinsQuery,
    useGetSolutionsQuery,
    useGetSubsystemsQuery,
} from 'api/category'

import { ISetFilterParams } from 'ag-grid-community'
import { SetFilter } from 'ag-grid-enterprise'

interface ISubsystemFilterParams extends ISetFilterParams {
    subsystems: Subsystem[]
}

type SubsystemOption = Partial<Subsystem & { disabled: boolean }>

const SubsystemFilter = forwardRef(
    ({ api, filterChangedCallback }: ISubsystemFilterParams, ref) => {
        const { data: subsystems } = useGetSubsystemsQuery()
        const { data: bins } = useGetBinsQuery()
        const { data: arts } = useGetArtsQuery()
        const { data: solutions } = useGetSolutionsQuery()

        const [allOptions, setAllOptions] = useState<SubsystemOption[]>(
            subsystems
                .filter(ss => !ss.isArchived)
                .sort((a, b) => a.name.localeCompare(b.name))
        )
        const [options, setOptions] = useState<SubsystemOption[]>([])
        const [searchText, setSearchText] = useState<string>('')
        const [error, setError] = useState<Error>(null)

        const filterByDependency = () => {
            const artDependencyFilter =
                api.getFilterInstance<SetFilter<Art>>('art')
            const solutionDependencyFilter =
                api.getFilterInstance<SetFilter<Solution>>('solution')
            const binDependencyFilter =
                api.getFilterInstance<SetFilter<Bin>>('bin')

            const selectedArtValues = artDependencyFilter?.getModel()
                ?.values as unknown[] as Art[]
            const selectedSolutionValues = solutionDependencyFilter?.getModel()
                ?.values as string[]
            const selectedBinValues = binDependencyFilter?.getModel()
                ?.values as unknown[] as Bin[]

            if (selectedBinValues && selectedBinValues.length) {
                return selectedBinValues.map((v: { id: number }) => v.id)
            } else if (selectedArtValues && selectedArtValues.length) {
                const matchingBins = selectedArtValues
                    .map(av => bins.filter(b => b.artId === av.id))
                    .flat()
                if (matchingBins.length) {
                    return matchingBins.map(b => b.id)
                }
            } else if (
                selectedSolutionValues &&
                selectedSolutionValues.length
            ) {
                const matchingSolutions = selectedSolutionValues.map(sv =>
                    solutions.find(s => s.name === sv)
                )
                if (matchingSolutions.length) {
                    const matchingArts = matchingSolutions
                        .map(s => arts.filter(a => a.solutionId === s?.id))
                        .flat()
                    if (matchingArts.length) {
                        const matchingBins = matchingArts
                            .map(a => bins.filter(b => b.artId === a?.id))
                            .flat()
                        if (matchingBins.length) {
                            return matchingBins.map(b => b?.id)
                        }
                    }
                }
            }
            return []
        }

        let selectedBins = filterByDependency()

        const handleClear = () => {
            setOptions([])
            // this timeout is needed to trigger the filterChangedCallback
            // after the options are cleared
            setTimeout(filterChangedCallback, 0)
        }

        const handleApply = () => {
            if (options.length > MAX_FILTER_LENGTH) {
                setError(
                    new Error(
                        // eslint-disable-next-line max-len
                        `I'm sorry but you can only select up to ${MAX_FILTER_LENGTH} options. You have selected ${options.length} options. Remove some options and try again.`
                    )
                )
                return
            }
            filterChangedCallback()
        }

        const handleSelectAll = () => {
            if (
                options.length ===
                allOptions.filter(o => !o.disabled).length + 1 // add one for the blank filter
            ) {
                setOptions([])
            } else {
                setOptions([
                    ...allOptions
                        .filter(o => !o.disabled)
                        .filter(option =>
                            option.name
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                        ),
                    { id: null, name: BLANK_FILTER },
                ])
            }
        }

        const handleSelectOption = (option: SubsystemOption) => {
            if (option.disabled) return

            if (options.includes(option)) {
                setOptions(prev => prev.filter(o => o.id !== option.id))
            } else {
                setOptions(prev => [...prev, option])
            }
        }

        const handleBlankFilter = () => {
            if (options.map(o => o.name).includes(BLANK_FILTER)) {
                setOptions(prev => prev.filter(o => o.name !== BLANK_FILTER))
            } else {
                setOptions(prev => [...prev, { id: null, name: BLANK_FILTER }])
            }
        }

        const handleDeletedFilter = () => {
            if (options.map(o => o.name).includes(DELETED_FILTER)) {
                setOptions(prev => prev.filter(o => o.name !== DELETED_FILTER))
            } else {
                setOptions(prev => [
                    ...prev,
                    { id: null, name: DELETED_FILTER },
                ])
            }
        }

        useImperativeHandle(ref, () => ({
            externalFilterPresent: null,

            isFilterActive() {
                return !!options?.length || this.externalFilterPresent
            },

            getModel() {
                if (!this.isFilterActive()) {
                    return null
                }

                return {
                    filterType: 'setOpt',
                    values: options,
                }
            },

            setModel(model: { values: Subsystem[] }) {
                if (model?.values) {
                    selectedBins = filterByDependency()
                    if (!selectedBins || selectedBins.length === 0) {
                        setAllOptions(prev =>
                            prev.map(o => ({ ...o, disabled: false }))
                        )
                    } else {
                        setAllOptions(prev =>
                            prev.map(o => ({
                                ...o,
                                disabled: !selectedBins.includes(o.binId),
                            }))
                        )
                    }

                    // set the options to the model values
                    // cast the ids to numbers and set disabled to false
                    setOptions(
                        model.values.map(v => ({
                            ...v,
                            id: parseInt(v.id?.toString(), 10),
                            binId: parseInt(v.binId?.toString(), 10),
                            disabled: false,
                        }))
                    )

                    // as the setOptions is not fast enough we have to this to
                    // make sure the filter knows that there is a filter present
                    this.externalFilterPresent = model.values
                } else {
                    handleClear()
                    this.externalFilterPresent = null
                }
            },

            refreshFilterValues() {
                selectedBins = filterByDependency()

                // find options that are not in the filter and remove them,
                // then run filterChangedCallback to update the filter
                const filteredOptions = options.filter(
                    o => selectedBins.includes(o.binId) || isNaN(o.binId)
                )

                if (filteredOptions.length !== options.length) {
                    setOptions(filteredOptions)
                    setTimeout(filterChangedCallback, 0)
                    this.externalFilterPresent = null
                }

                if (!selectedBins || selectedBins.length === 0) {
                    setAllOptions(prev =>
                        prev.map(o => ({ ...o, disabled: false }))
                    )
                } else {
                    setAllOptions(prev =>
                        prev.map(o => ({
                            ...o,
                            disabled: !selectedBins.includes(o.binId),
                        }))
                    )
                }
            },

            addOptionsToFilter(newOption) {
                setAllOptions(prev =>
                    [...prev, newOption].sort((a, b) =>
                        a.name.localeCompare(b.name)
                    )
                )
            },
        }))

        const filterDuplicates = (
            value: Subsystem,
            index: number,
            self: Subsystem[]
        ): boolean => self.findIndex(t => t.name === value.name) === index

        return (
            <>
                <div className='ag-filter-body-wrapper ag-set-filter-body-wrapper'>
                    Filter options are filtered by the selected Bin, Art or
                    Solution. If no Bin is selected or there is no Bin under the
                    Art or Solution , all options are available.
                    {error && (
                        <div style={{ padding: '4px 0 0 0', color: 'red' }}>
                            {error.message}
                        </div>
                    )}
                    <div className='ag-mini-filter'>
                        <input
                            className='ag-input-field-input ag-text-field-input'
                            type='text'
                            placeholder='Search...'
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                        />
                    </div>
                    <div className='ag-set-filter-list'>
                        <div className='ag-virtual-list-viewport ag-filter-virtual-list-viewport ag-focus-managed'>
                            <div
                                style={{ gridGap: '8px' }}
                                className='ag-virtual-list-container ag-filter-virtual-list-container'
                            >
                                <div className='ag-filter-virtual-list-item'>
                                    <div className='ag-set-filter-item'>
                                        <div
                                            className='ag-set-filter-item-checkbox ag-label-align-right'
                                            onClick={handleSelectAll}
                                        >
                                            <div className='ag-input-field-label ag-label ag-checkbox-label'>
                                                (Select All)
                                            </div>
                                            <div
                                                className={`ag-input-wrapper ag-checkbox-input-wrapper ${
                                                    options?.length ===
                                                    allOptions.length + 1
                                                        ? 'ag-checked'
                                                        : options?.length
                                                        ? 'ag-indeterminate'
                                                        : ''
                                                }`}
                                            ></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='ag-filter-virtual-list-item'>
                                    <div className='ag-set-filter-item'>
                                        <div
                                            className='ag-set-filter-item-checkbox ag-label-align-right'
                                            onClick={handleBlankFilter}
                                        >
                                            <div className='ag-input-field-label ag-label ag-checkbox-label'>
                                                {BLANK_FILTER}
                                            </div>
                                            <div
                                                className={`ag-input-wrapper ag-checkbox-input-wrapper ${
                                                    options
                                                        .map(o => o.name)
                                                        .includes(BLANK_FILTER)
                                                        ? 'ag-checked'
                                                        : ''
                                                }`}
                                            ></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='ag-filter-virtual-list-item'>
                                    <div className='ag-set-filter-item'>
                                        <div
                                            className='ag-set-filter-item-checkbox ag-label-align-right'
                                            onClick={handleDeletedFilter}
                                        >
                                            <div className='ag-input-field-label ag-label ag-checkbox-label'>
                                                {DELETED_FILTER}
                                            </div>
                                            <div
                                                className={`ag-input-wrapper ag-checkbox-input-wrapper ${
                                                    options
                                                        .map(o => o.name)
                                                        .includes(
                                                            DELETED_FILTER
                                                        )
                                                        ? 'ag-checked'
                                                        : ''
                                                }`}
                                            ></div>
                                        </div>
                                    </div>
                                </div>

                                {allOptions
                                    // filter out options that don't match the search text
                                    .filter(option =>
                                        option.name
                                            .toLowerCase()
                                            .includes(searchText.toLowerCase())
                                    )
                                    // filter out options that are not in the selected bins
                                    .filter(option =>
                                        selectedBins.length === 0
                                            ? true
                                            : selectedBins.includes(
                                                  option.binId
                                              )
                                    )
                                    // filter out options that are disabled, might be redundant
                                    .filter(option => !option.disabled)
                                    // remove duplicates options
                                    .filter(filterDuplicates)
                                    .map(option => (
                                        <div
                                            className='ag-filter-virtual-list-item'
                                            key={option.id}
                                        >
                                            <div className='ag-set-filter-item'>
                                                <div
                                                    className='ag-set-filter-item-checkbox ag-label-align-right'
                                                    onClick={() =>
                                                        handleSelectOption(
                                                            option
                                                        )
                                                    }
                                                >
                                                    <div className='ag-input-field-label ag-label ag-checkbox-label'>
                                                        {option.name}
                                                    </div>
                                                    <div
                                                        className={`ag-input-wrapper ag-checkbox-input-wrapper ${
                                                            options
                                                                .map(o => o.id)
                                                                ?.includes(
                                                                    option.id
                                                                )
                                                                ? 'ag-checked'
                                                                : ''
                                                        }`}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='ag-filter-apply-panel'>
                    <button
                        type='button'
                        className='ag-standard-button ag-filter-apply-panel-button'
                        onClick={handleClear}
                    >
                        Reset
                    </button>
                    <button
                        type='button'
                        className='ag-standard-button ag-filter-apply-panel-button'
                        onClick={handleApply}
                    >
                        Apply
                    </button>
                </div>
            </>
        )
    }
)

SubsystemFilter.displayName = 'Custom Subsystem Filter'

export default SubsystemFilter
