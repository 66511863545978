import { Field, Input } from '@fluentui/react-components'

interface IAutoSelectInputProps {
    callback: (name: string) => void
    validationMessage: string
    inputValue: string
    title: string
}

const AutoSelectInput = ({
    callback,
    validationMessage,
    inputValue,
    title,
}: IAutoSelectInputProps) => {
    return (
        <Field label={title} validationMessage={validationMessage}>
            <Input
                onChange={(_e, { value }) => callback(value)}
                defaultValue={inputValue}
            />
        </Field>
    )
}

export default AutoSelectInput
