import {
    MessageBar,
    MessageBarActions,
    MessageBarBody,
} from '@fluentui/react-components'

import CloseButton from './UI/CloseButon'
import useSessionStorageState from 'hooks/useSessionStorage'

const BACKEND_API = process.env.REACT_APP_REFLECT_BACKEND_API

const EnvironmentBar = () => {
    const [showMessage, setShowMessage] = useSessionStorageState(
        'showMessage',
        {
            defaultValue: true,
        }
    )

    const environment = BACKEND_API.includes('graysea-ad6dd5f8') ? 'QA' : null

    return showMessage && environment ? (
        <MessageBar intent='info'>
            <MessageBarBody>
                You are using the <b>{environment}</b> environment.
            </MessageBarBody>
            <MessageBarActions>
                <CloseButton onClick={() => setShowMessage(false)} />
            </MessageBarActions>
        </MessageBar>
    ) : null
}

export default EnvironmentBar
