import { ColDef, GridReadyEvent } from 'ag-grid-community'
import {
    ExtendedOdataProvider,
    getContextMenuItems,
} from 'helpers/dataGridHelper'
import { setLastOdataQuery, setRowCount } from 'slices/gridSlice'
import { useMemo, useRef } from 'react'

import ActiveFiltersPanel from 'components/Feedbacks/toolPanels/ActiveFiltersPanel'
import AgGridContainer from 'components/Layout/AgGridContainer'
import { AgGridReact } from 'ag-grid-react'
import CommaSeparatedTextFilter from 'components/Feedbacks/filters/CommaSeparatedTextFilter'
import GridHasFilterMessage from 'components/Feedbacks/statusBar/GridHasFilterMessage'
import RowCounter from 'components/Feedbacks/statusBar/RowCounter'
import { customFilters } from 'helpers/customFilters'
import { fetchOdataAPI } from 'api/genericFunctions'
import { useAppDispatch } from 'store'
import { useGrid } from 'contexts/GridContext'

const PraisesGrid = () => {
    const { setGridRef } = useGrid()
    const gridRef = useRef<AgGridReact>(null)

    const dispatch = useAppDispatch()

    const onGridReady = (params: GridReadyEvent) => {
        const gridApi = params.api

        setGridRef(gridRef)

        gridApi.setServerSideDatasource(
            new ExtendedOdataProvider({
                callApi: async options => {
                    const response: { count: number; data: Praise[] } =
                        await fetchOdataAPI<IPraise>(
                            'odata/PraisesIdeas',
                            options
                        )

                    dispatch(setRowCount(response.count))
                    dispatch(setLastOdataQuery(options))

                    return {
                        '@odata.count': response.count,
                        value: response.data,
                    }
                },
                customFilters: customFilters,
                beforeRequest: query => {
                    query['count'] = true
                },
            })
        )
    }

    const defaultColDef: ColDef = useMemo(
        () => ({
            cellEditor: 'agTextCellEditor',
            cellEditorPopup: false,
            editable: false,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
                defaultToNothingSelected: true,
            },
            resizable: true,
            sortable: true,
        }),
        []
    )

    const columnDefs = useMemo<ColDef[]>(
        () => [
            {
                checkboxSelection: true,
                colId: 'checkBoxSelect',
                lockPinned: true,
                lockPosition: true,
                maxWidth: 55,
                pinned: 'left',
                resizable: false,
                sortable: false,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                suppressFiltersToolPanel: true,
            },
            {
                field: 'id',
                flex: 1,
                sort: 'desc',
                filter: false,
            },
            {
                field: 'formTitle',
                flex: 1,
                tooltipField: 'formTitle',
            },
            {
                field: 'formDescription',
                tooltipField: 'formDescription',
                flex: 1,
                filter: false,
            },
            {
                field: 'dateSubmitted',
                flex: 1,
                filter: 'agDateColumnFilter',
            },
            {
                field: 'reportType',
                flex: 1,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: ['Idea', 'Praise'],
                },
            },
            {
                field: 'vin',
                flex: 1,
                filter: CommaSeparatedTextFilter,
            },
            {
                field: 'deliveryCountry',
                flex: 1,
            },
        ],
        []
    )

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 225,
                    maxWidth: 225,
                    width: 225,
                    toolPanelParams: {
                        suppressPivotMode: true,
                        suppressRowGroups: true,
                        suppressValues: true,
                    },
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 180,
                    maxWidth: 400,
                    width: 250,
                },
                {
                    id: 'activeFilters',
                    labelDefault: 'Active Filters',
                    labelKey: 'activeFilters',
                    iconKey: 'filter',
                    toolPanel: ActiveFiltersPanel,
                },
            ],
        }
    }, [])

    return (
        <AgGridContainer>
            <AgGridReact
                className='dataGrid'
                animateRows
                ref={gridRef}
                onGridReady={onGridReady}
                rowModelType='serverSide'
                rowSelection='multiple'
                serverSideInfiniteScroll
                maxConcurrentDatasourceRequests={1}
                getRowId={params => params.data.id.toString()}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                enableRangeSelection
                enableBrowserTooltips
                maintainColumnOrder
                sideBar={sideBar}
                suppressCsvExport={false}
                blockLoadDebounceMillis={250}
                getContextMenuItems={getContextMenuItems}
                statusBar={{
                    statusPanels: [
                        {
                            statusPanel: RowCounter,
                            align: 'left',
                        },
                        {
                            statusPanel: 'agSelectedRowCountComponent',
                            align: 'left',
                        },
                        {
                            statusPanel: GridHasFilterMessage,
                            align: 'right',
                        },
                    ],
                }}
            />
        </AgGridContainer>
    )
}

export default PraisesGrid
