import { Button, Text } from '@fluentui/react-components'

import { DeleteRegular } from '@fluentui/react-icons'
import DialogWithIconButton from 'components/UI/DialogWithIconButton'
import { GridApi } from 'ag-grid-community'
import { useDeleteCauseRemarksMutation } from 'api/category'

interface ICauseRemarkDeleteModalProps {
    data: CauseRemark
    api: GridApi
}

const CauseRemarkDeleteModal = ({
    data,
    api,
}: ICauseRemarkDeleteModalProps) => {
    const [deleteCauseRemark, { isLoading }] = useDeleteCauseRemarksMutation()

    const handleClick = async (toggleOpen: () => void) => {
        await deleteCauseRemark(data.id)

        toggleOpen()
        api.refreshCells()
    }

    return (
        <DialogWithIconButton
            icon={<DeleteRegular />}
            dialogTitle='Delete this?'
            dialogContent={() => (
                <Text>
                    Are you sure you want to delete this Cause / Remark? This
                    action cannot be undone.
                </Text>
            )}
            actionRender={toggleOpen => (
                <Button
                    appearance='primary'
                    disabled={isLoading}
                    onClick={() => handleClick(toggleOpen)}
                >
                    Confirm
                </Button>
            )}
        />
    )
}

export default CauseRemarkDeleteModal
