import { RootState } from 'store'
import { createSlice } from '@reduxjs/toolkit'

interface ICategoryState {
    activeArtId: number
    activeBinId: number
    activeSolutionId: number
    archiveState: boolean
}

const initialState: ICategoryState = {
    activeArtId: null,
    activeBinId: null,
    activeSolutionId: null,
    archiveState: false,
}

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        toggleArchiveState: state => {
            state.archiveState = !state.archiveState
        },
        setActiveArtId: (state, action) => {
            state.activeArtId = action.payload
        },
        setActiveBinId: (state, action) => {
            state.activeBinId = action.payload
        },
        setActiveSolutionId: (state, action) => {
            state.activeSolutionId = action.payload
        },
    },
})

export const {
    toggleArchiveState,
    setActiveArtId,
    setActiveBinId,
    setActiveSolutionId,
} = categorySlice.actions

export const selectArchived = (state: RootState) => state.category.archiveState
export const selectArtId = (state: RootState) => state.category.activeArtId
export const selectBinId = (state: RootState) => state.category.activeBinId
export const selectSolutionId = (state: RootState) =>
    state.category.activeSolutionId

export default categorySlice.reducer
