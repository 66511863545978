import {
    ArrowReset24Regular,
    BookArrowClockwise24Regular,
    Eye24Filled,
} from '@fluentui/react-icons'
import {
    Menu,
    MenuButton,
    MenuDivider,
    MenuGroup,
    MenuGroupHeader,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components'
import { useAppDispatch, useAppSelector } from 'store'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
    useGetPersonalViewByIdQuery,
    useGetPersonalViewsQuery,
} from 'api/personalViews'

import SaveDialog from './SaveDialog'
import ViewSubmenu from './PVSubmenu'
import { selectDefaultViews } from 'slices/preferenceSlice'
import { selectUser } from 'slices/authSlice'
import { triggerMessage } from 'slices/messageSlice'
import { useGrid } from 'contexts/GridContext'
import { useSearchParams } from 'react-router-dom'

interface IPersonalViewsProps {
    viewType: PersonalViewType
}

const PersonalViewsMenu = ({ viewType }: IPersonalViewsProps) => {
    const [selectedPersonalView, setSelectedPersonalView] =
        useState<PersonalView>()
    const [checkedValues, setCheckedValues] = useState<
        Record<string, string[]>
    >({ personalView: [''] })
    const [open, setOpen] = useState(false)

    const dispatch = useAppDispatch()

    const [searchParams, setSearchParams] = useSearchParams()

    const { gridRef } = useGrid()

    const user = useAppSelector(selectUser)
    const scope = user.view

    const { data: personalViews } = useGetPersonalViewsQuery(viewType)
    const options = useMemo(
        () =>
            personalViews?.map(pv => ({
                id: pv.id,
                viewJson: pv.viewJson,
                name:
                    pv.activeScope === scope
                        ? pv.name
                        : `${pv.name} (${pv.activeScope})`,
                disabled: pv.activeScope !== scope,
                action: () => {
                    setSelectedPersonalView(pv)
                },
            })) || [],
        [personalViews, scope]
    )

    const viewId = searchParams.get('view')
    const { data: viewFromParam } = useGetPersonalViewByIdQuery(viewId, {
        skip: !viewId,
    })

    const defaultViews = useAppSelector(selectDefaultViews)

    const compare = (a: unknown, b: unknown) => {
        return JSON.stringify(a) === JSON.stringify(b)
    }

    const applyGridChanges = useCallback(
        (viewData: PersonalView) => {
            const { columnState, filterModel } = JSON.parse(viewData.viewJson)

            const currentColumnState =
                gridRef.current?.columnApi.getColumnState()
            if (!compare(currentColumnState, columnState))
                gridRef.current?.columnApi.applyColumnState({
                    state: columnState,
                    applyOrder: true,
                })

            const currentFilterModel = gridRef.current?.api.getFilterModel()
            if (!compare(currentFilterModel, filterModel))
                gridRef.current?.api.setFilterModel(filterModel)
        },
        [gridRef]
    )

    const reset = () => {
        setSearchParams(param => {
            const params = new URLSearchParams(param)
            params.delete('view')
            params.delete('filters')
            return params
        })

        gridRef.current.columnApi.resetColumnState()
        gridRef.current.api.setFilterModel({})

        setSelectedPersonalView(null)
        setCheckedValues({ personalView: [''] })
    }

    // if we have a view from the url, set the selected view to that view
    useEffect(() => {
        if (viewFromParam) {
            setSelectedPersonalView(viewFromParam)
        }
    }, [viewFromParam])

    // if we have a default view and we don't have any searchParams,
    // set the selected view to the default view
    useEffect(() => {
        const pathView = defaultViews?.find(dv => dv.path === location.pathname)
        const selectedView = personalViews?.find(pv => pv.id === pathView?.id)

        if (
            selectedView &&
            !searchParams.has('view') &&
            !searchParams.has('filters')
        ) {
            setSelectedPersonalView(selectedView)
        }
    }, [defaultViews, personalViews, searchParams])

    // if the selected view changes, apply the changes to the grid
    useEffect(() => {
        if (selectedPersonalView) {
            if (selectedPersonalView.viewType !== viewType) {
                dispatch(
                    triggerMessage({
                        title: 'Invalid View Type',
                        message: `The view you are trying to access is of type '${selectedPersonalView.viewType}' and not '${viewType}'`,
                        intent: 'error',
                    })
                )
                return
            }

            if (selectedPersonalView.activeScope !== scope) {
                dispatch(
                    triggerMessage({
                        title: 'Invalid Scope in View',
                        message: `You don't have access to the scope of this personal view ('${selectedPersonalView.activeScope}').`,
                        intent: 'error',
                    })
                )
                return
            }

            setCheckedValues({ personalView: [selectedPersonalView.name] })
            setSearchParams(param => {
                const params = new URLSearchParams(param)
                params.set('view', selectedPersonalView.id.toString())
                return params
            })

            applyGridChanges(selectedPersonalView)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPersonalView, viewType, scope])

    // if we can't get the gridRef, we cannot do anything
    if (!gridRef.current) return null

    return (
        <>
            <Menu
                open={open}
                onOpenChange={(_e, data) => setOpen(data.open)}
                persistOnItemClick={true}
                checkedValues={checkedValues}
                onCheckedValueChange={(_e, { name, checkedItems }) => {
                    setCheckedValues(s => ({ ...s, [name]: checkedItems }))
                    setOpen(false)
                }}
            >
                <MenuTrigger disableButtonEnhancement>
                    <MenuButton
                        style={{
                            backgroundColor: '#013b4d',
                        }}
                        icon={<Eye24Filled />}
                        appearance='primary'
                        data-cy='personal-views-menu'
                    >
                        {selectedPersonalView?.name ?? 'Views'}
                    </MenuButton>
                </MenuTrigger>

                <MenuPopover>
                    <MenuList>
                        <MenuGroup>
                            <MenuGroupHeader>
                                Select your preferred view
                            </MenuGroupHeader>
                            <ViewSubmenu
                                options={options}
                                viewType={viewType}
                                reset={reset}
                            />
                        </MenuGroup>
                        <MenuDivider />
                        <MenuGroup>
                            <MenuGroupHeader>Actions</MenuGroupHeader>
                            <SaveDialog
                                intent='add'
                                onSuccessfulSave={(view: PersonalView) => {
                                    setSelectedPersonalView(view)
                                }}
                                viewType={viewType}
                            />
                            <MenuItem
                                data-cy='reset-to-default-view'
                                icon={<ArrowReset24Regular />}
                                onClick={reset}
                                disabled={!selectedPersonalView}
                            >
                                Reset to default view
                            </MenuItem>
                            <MenuItem
                                icon={<BookArrowClockwise24Regular />}
                                onClick={() => {
                                    applyGridChanges(selectedPersonalView)
                                }}
                                disabled={!selectedPersonalView}
                            >
                                Reapply selected view
                            </MenuItem>
                        </MenuGroup>
                    </MenuList>
                </MenuPopover>
            </Menu>
        </>
    )
}

export default PersonalViewsMenu
