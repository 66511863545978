import { Feedback, IFeedback } from 'types/feedback'

import { apiSlice } from './apiSlice'
import { lowerCaseKeys } from 'helpers/objectManipulation'

const feedbackApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFeedbackById: builder.query<Feedback, { id: string; view: View }>({
            query: obj => {
                let url = ''
                if (obj.view === 'CoDev') {
                    url = 'odata/Feedback'
                } else if (obj.view === 'CMQ') {
                    url = 'odata/CMQSummaryViews'
                } else if (obj.view === 'America') {
                    url = 'odata/AmericaView'
                } else if (obj.view === 'China') {
                    url = 'odata/ChinaView'
                }
                return `${url}?$filter=Id eq ${obj.id}`
            },
            transformResponse: (response: IODataResponse<IFeedback[]>) =>
                lowerCaseKeys(response.value[0]) as unknown as Feedback,
        }),
        patchFeedback: builder.mutation<
            void,
            { id: Feedback['id']; data: IPatchData<Feedback>[] }
        >({
            query: ({ id, data }) => ({
                url: `api/Feedback/${id}`,
                method: 'PATCH',
                body: data,
            }),
        }),
    }),
})

export const { useGetFeedbackByIdQuery, usePatchFeedbackMutation } = feedbackApi
