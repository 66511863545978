import DropdownCellEditor from './DropdownCellEditor'
import { Feedback } from 'types/feedback'
import { ICellRendererParams } from 'ag-grid-community'
import { forwardRef } from 'react'
import { useGetSolutionsQuery } from 'api/category'

type ISolutionCellEditor = ICellRendererParams<Feedback> & {
    stopEditing: () => void
    solutions: Solution[]
}

const SolutionCellEditor = forwardRef(
    ({ data, stopEditing }: ISolutionCellEditor, ref) => {
        const { data: solutions = [] } = useGetSolutionsQuery()
        const filter = (item: Solution) => !item.isArchived && !item.isDeleted

        return (
            <DropdownCellEditor<Solution>
                noblank
                data={data}
                filter={filter}
                dataKey='solution'
                options={solutions}
                forwardRef={ref}
                stopEditing={stopEditing}
            />
        )
    }
)

SolutionCellEditor.displayName = 'Solution Cell Editor'

export default SolutionCellEditor
