import { FormEvent, forwardRef, useImperativeHandle, useState } from 'react'

import { IFilterParams } from 'ag-grid-community'
import { Feedback } from 'types/feedback'

const CommaSeparatedTextFilter = forwardRef(
    ({ api, filterChangedCallback }: IFilterParams<Feedback>, ref) => {
        const [filterText, setFilterText] = useState<string>('')

        const refresh = () => {
            filterChangedCallback()
            api.deselectAll()
        }

        useImperativeHandle(ref, () => ({
            filterBySelection(data: string) {
                setFilterText(data)
                setTimeout(refresh, 0)
            },

            doesFilterPass() {
                return filterText !== null && filterText !== ''
            },

            isFilterActive() {
                return filterText !== null && filterText !== ''
            },

            getModel() {
                if (!this.isFilterActive()) {
                    return null
                }

                const values = filterText.split(',').map(n => n.trim())

                return {
                    filterType: 'csv',
                    values,
                }
            },

            setModel: function (model: { values: string[]; ranges: string[] }) {
                if (!model) {
                    setFilterText('')
                } else if (model.values && model.values.length > 0) {
                    setFilterText(model.values.join(','))
                }
                setTimeout(refresh, 0)
            },
        }))

        const handleChange = (event: FormEvent<HTMLInputElement>) => {
            setFilterText(event.currentTarget.value)
        }

        const handleApply = () => {
            if (filterText === '') {
                setTimeout(refresh, 0)
            }
            filterChangedCallback()
        }

        const handleClear = () => {
            setFilterText('')
            setTimeout(refresh, 0)
        }

        return (
            <div
                style={{ padding: 4, width: 200 }}
                className='ag-filter-body-wrapper ag-simple-filter-body-wrapper'
            >
                <div style={{ fontWeight: 'bold' }}>CSV Filter</div>
                <div style={{ marginBottom: 8 }}>
                    Comma separated list of strings to filter by.
                </div>
                <div>
                    <div className='ag-wrapper ag-input-wrapper ag-text-field-input-wrapper'>
                        <input
                            className='ag-input-field-input ag-text-field-input'
                            type='text'
                            value={filterText}
                            onChange={handleChange}
                            placeholder='Enter a comma separated list...'
                        />
                    </div>
                    <div className='ag-filter-apply-panel'>
                        <button
                            type='button'
                            className='ag-standard-button ag-filter-apply-panel-button'
                            onClick={handleClear}
                        >
                            Reset
                        </button>
                        <button
                            type='button'
                            className='ag-standard-button ag-filter-apply-panel-button'
                            onClick={handleApply}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        )
    }
)

CommaSeparatedTextFilter.displayName = 'Custom Text Filter'

export default CommaSeparatedTextFilter
