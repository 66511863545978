import { Button } from '@fluentui/react-components'
import { DismissRegular } from '@fluentui/react-icons'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CloseButton = ({ ...rest }: any) => {
    return (
        <Button
            appearance='subtle'
            icon={<DismissRegular />}
            title='Close'
            {...rest}
        />
    )
}

export default CloseButton
