import { Combobox, Field, useComboboxFilter } from '@fluentui/react-components'
import { useEffect, useMemo, useState } from 'react'

interface IAutoSelectDropdownProps<T> {
    callback: (option: T, reason: string) => void
    disabled?: boolean
    data: T[]
    selectedId: string | number
    title: string
    subTitle?: string
    validationMessage?: string
}

type optionType = Solution | Bin | Art | Subsystem | CauseRemark | ProblemType

const AutoSelectDropdown = <T extends optionType>({
    callback,
    disabled = false,
    data,
    selectedId,
    title,
    subTitle,
    validationMessage,
}: IAutoSelectDropdownProps<T>) => {
    const selectedOption = data.find(({ id }) => id === selectedId)
    const [value, setValue] = useState(selectedOption?.name || '')

    useEffect(() => {
        setValue(selectedOption?.name || '')
    }, [selectedOption?.name])

    const options = useMemo(() => {
        return [...data]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ id, name }) => ({ value: String(id), children: name }))
    }, [data])

    return (
        <Field
            label={title}
            validationMessage={validationMessage}
            hint={subTitle}
        >
            <Combobox
                clearable={value !== ''}
                disabled={disabled}
                value={value}
                onChange={ev => setValue(ev.target.value)}
                selectedOptions={[String(selectedId)]}
                onOptionSelect={(_e, { optionValue }) => {
                    const option = data.find(
                        ({ id }) => String(id) === optionValue
                    )
                    setValue(option?.name ?? '')

                    if (option) {
                        callback(option, 'select')
                    }
                }}
            >
                {useComboboxFilter(value, options, {
                    filter: (optionText, query) => {
                        return data
                            .find(({ id }) => String(id) === optionText)
                            ?.name.toLowerCase()
                            .includes(query.toLowerCase())
                    },
                    noOptionsMessage: 'No match your search...',
                })}
            </Combobox>
        </Field>
    )
}

export default AutoSelectDropdown
