import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components'

import { Feedback } from 'types/feedback'
import { ICellRendererParams } from 'ag-grid-community'
import { WarningRegular } from '@fluentui/react-icons'
import { usePatchFeedbackMutation } from 'api/feedback'
import { useState } from 'react'

const IsModifiedWrapperRenderer = ({
    data,
    value,
    api,
}: ICellRendererParams<Feedback>) => {
    const [open, setOpen] = useState(false)
    const [patchFeedback] = usePatchFeedbackMutation()

    const SetReviewed = () => {
        patchFeedback({
            id: data.id,
            data: [
                {
                    path: 'isModified',
                    value: false,
                },
            ],
        })

        api.forEachNode(rowNode => {
            if (rowNode.data.id === data.id) {
                rowNode.setData({ ...rowNode.data, ['isModified']: false })
            }
        })
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {value?.toString()}
                {data.isModified && (
                    <Dialog
                        open={open}
                        onOpenChange={(_e, d) => setOpen(d.open)}
                    >
                        <DialogTrigger>
                            <Button icon={<WarningRegular />}>Reviewed</Button>
                        </DialogTrigger>
                        <DialogSurface>
                            <DialogBody>
                                <DialogTitle>Set as reviewed</DialogTitle>
                                <DialogContent>
                                    Do you want to remove the modified flag?
                                </DialogContent>
                                <DialogActions>
                                    <DialogTrigger>
                                        <Button>Cancel</Button>
                                    </DialogTrigger>
                                    <Button
                                        appearance='primary'
                                        onClick={SetReviewed}
                                    >
                                        Ok
                                    </Button>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                )}
            </div>
        </>
    )
}

export default IsModifiedWrapperRenderer
