import {
    Button,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
} from '@fluentui/react-components'

import CloseButton from './UI/CloseButon'
import Preferences from './Preferences'
import ReleaseInformation from './ReleaseInformation'
import { SettingsCogMultiple24Regular } from '@fluentui/react-icons'
import { useState } from 'react'

const PreferencesPanel = () => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <Button
                appearance='subtle'
                icon={<SettingsCogMultiple24Regular />}
                style={{ color: 'white' }}
                onClick={() => setIsOpen(true)}
            />

            <Drawer
                position='end'
                open={isOpen}
                onOpenChange={(_e, data) => setIsOpen(data.open)}
            >
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={
                            <CloseButton onClick={() => setIsOpen(false)} />
                        }
                    >
                        Preferences
                    </DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody>
                    <Preferences />
                    <ReleaseInformation />
                </DrawerBody>
            </Drawer>
        </>
    )
}

export default PreferencesPanel
