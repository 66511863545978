/* eslint-disable check-file/no-index */

import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react'

import App from 'App'
import { InteractionType } from '@azure/msal-browser'
import { LicenseManager } from 'ag-grid-enterprise'
import Loader from 'components/UI/Loader'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { msalInstance } from 'authInstance'
import reportWebVitals from 'reportWebVitals'

const container = document.getElementById('app')
const root = createRoot(container)

LicenseManager.setLicenseKey(`${process.env.REACT_APP_AGGRID_LICENSE}`)

root.render(
    <StrictMode>
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                errorComponent={() => (
                    <Loader error text='Authentication failed' />
                )}
                loadingComponent={() => <Loader text='Authenticating...' />}
            >
                <App />
            </MsalAuthenticationTemplate>
        </MsalProvider>
    </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
