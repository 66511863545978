import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
} from '@fluentui/react-components'
import { Link } from 'react-router-dom'
import { ITrillionGroup } from 'types/trillionGroup'

interface IGroupProps {
    group: ITrillionGroup
}

const Group = ({ group }: IGroupProps) => {
    const getGroupType = groupName => {
        if (groupName === '9413-app-cmq-admin') {
            return 'Admins'
        } else if (groupName === '9413-app-cmq-edit') {
            return 'Editors'
        } else if (groupName === '9413-app-cmq-read') {
            return 'Readers'
        }
        return 'Unknown'
    }
    return (
        <Accordion collapsible={true}>
            {group.groupName && (
                <AccordionItem value={group.groupName}>
                    <AccordionHeader>
                        {getGroupType(group.groupName)} ({group.groupName})
                    </AccordionHeader>
                    <AccordionPanel>
                        {group.members &&
                            group.members.map((member, index) => (
                                <div
                                    key={index}
                                    style={{ paddingLeft: '27px' }}
                                >
                                    <Link
                                        title='Link to Teams Chat'
                                        to={`https://teams.microsoft.com/l/chat/0/0?users=${member.upn}`}
                                        target='_blank'
                                    >
                                        {member.name}
                                    </Link>
                                </div>
                            ))}
                    </AccordionPanel>
                </AccordionItem>
            )}
        </Accordion>
    )
}

export default Group
