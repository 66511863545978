import CauseRemarkDeleteModal from './CauseRemarkDeleteModal'
import CauseRemarkEditModal from './CauseRemarkEditModal'
import { GridApi } from 'ag-grid-community'

interface IProps {
    data: CauseRemark
    api: GridApi
    causeRemarks: CauseRemark[]
}

const CauseRemarkActions = ({ data, api, causeRemarks }: IProps) => (
    <div style={{ display: 'flex', gridGap: '8px' }}>
        <CauseRemarkEditModal
            data={data}
            api={api}
            causeRemarks={causeRemarks}
        />
        <CauseRemarkDeleteModal data={data} api={api} />
    </div>
)

export default CauseRemarkActions
