import { AddRegular } from '@fluentui/react-icons'
import { Button } from '@fluentui/react-components'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AddButton = ({ ...rest }: any) => {
    return (
        <Button
            appearance='subtle'
            icon={<AddRegular />}
            title='Add'
            {...rest}
        />
    )
}

export default AddButton
