import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Field,
    MessageBar,
    MessageBarBody,
    Switch,
    Text,
} from '@fluentui/react-components'
import { selectArtId, selectBinId } from 'slices/categorySlice'
import { useMemo, useState } from 'react'

import AutoSelectDropdown from 'components/UI/AutoSelectDropdown'
import { useAppSelector } from 'store'
import { useGrid } from 'contexts/GridContext'
import { usePatchSubsystemsMutation } from 'api/category'

const MAX_COUNT = 250

interface IBatchEditModalProps {
    isOpen: boolean
    toggleModal: () => void
    allBins: Bin[]
    selectedRows: Subsystem[]
}

interface IFormData {
    title: string // The title of the form field
    key: keyof Subsystem // The key of the form field
    type: 'boolean' | 'select' // The type of form field
    options?: Bin[] // An array of options that can be selected in this dropdown
}

interface IChangeset {
    path: keyof Subsystem // The key of the data that is being changed
    value: number | string // The value of the data that is being changed
}

interface IError {
    path: keyof Subsystem
    message: string
}

/**
 * BatchEditModal is a React component that provides a form for batch editing data.
 *
 * @param isOpen - Whether the modal is currently open.
 * @param toggleModal - A callback function that hides the modal when called.
 */
const BatchEditModal = ({
    isOpen,
    toggleModal,
    allBins,
    selectedRows,
}: IBatchEditModalProps) => {
    const { gridRef } = useGrid()

    const [patchSubsystem] = usePatchSubsystemsMutation()
    const selectedBinId = useAppSelector(selectBinId)
    const selectedArtId = useAppSelector(selectArtId)

    // Create an array of objects to define the form fields
    const formData: IFormData[] = useMemo(
        () => [
            {
                title: 'Archive',
                key: 'isArchived',
                type: 'boolean',
            },
            {
                title: 'Delete',
                key: 'isDeleted',
                type: 'boolean',
            },
            {
                title: 'Bin',
                key: 'binId',
                options: allBins.filter(bin => bin.artId === selectedArtId),
                type: 'select',
            },
        ],
        [allBins, selectedArtId]
    )

    // Initialize the toggles state variable with an array of booleans that corresponds to the form fields
    const [toggles, setToggles] = useState(formData.map(() => false))

    // Initialize the changeSets state variable with an array of IChangeset objects that corresponds to the form fields
    const [changeSets, setChangeSets] = useState<IChangeset[]>(
        formData.map(d => ({ path: d.key, value: undefined }))
    )

    // Initialize the errors state variable with an array of IError objects that corresponds to the form fields
    const [errors, setErrors] = useState<IError>()

    const handleCallback = (
        newValue: string | number,
        path: keyof Subsystem
    ) => {
        if (errors?.[path]) {
            const newErrors = { ...errors }
            delete newErrors[path]
            setErrors(newErrors)
        }

        setChangeSets(pre =>
            pre.map(p => (p.path === path ? { ...p, value: newValue } : p))
        )
    }

    const handleToggleChange = (
        currentIndex: number,
        path: keyof Subsystem,
        checked: boolean
    ) => {
        // Create a copy of the toggles array
        const temp = [...toggles]

        // Update the value of the current toggle
        temp[currentIndex] = checked

        // If the toggle is being unchecked remove the current key from the errors
        if (!checked) {
            setErrors(pre => {
                const newErrors = { ...pre }
                delete newErrors[path]
                return newErrors
            })

            // Update the state with the modified changeSets array
            setChangeSets(pre =>
                pre.map(p => (p.path === path ? { ...p, value: undefined } : p))
            )
        } else {
            // set the value of the current key to the default value
            if (formData[currentIndex].type === 'select') {
                setChangeSets(pre =>
                    pre.map(p =>
                        p.path === path ? { ...p, value: selectedBinId } : p
                    )
                )
            } else if (formData[currentIndex].type === 'boolean') {
                setChangeSets(pre =>
                    pre.map(p =>
                        p.path === path ? { ...p, value: 'false' } : p
                    )
                )
            }
        }

        // Update the state with the modified toggles array
        setToggles(temp)
    }

    const handleClose = () => {
        // clear the selectedRows to make that the user gets new data when the modal is opened again
        gridRef.current.api.deselectAll()

        // reset the toggles and changeSets when the modal is closed
        setToggles(pre => pre.map(() => false))
        setChangeSets(pre => pre.map(p => ({ ...p, value: undefined })))
        setErrors(null)

        toggleModal()
    }

    const handleSubmit = async () => {
        await Promise.all(
            selectedRows.map(row => {
                return patchSubsystem({
                    id: row.id,
                    data: changeSets.filter((_p, i) => toggles[i]),
                })
            })
        )

        handleClose()
    }

    return (
        <Dialog
            onOpenChange={(_e, { open }) => open && handleClose()}
            open={isOpen}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>
                        {`Editing ${selectedRows.length} selected row(s)`}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ marginBottom: '8px', gridGap: '8px' }}>
                            {selectedRows.length > MAX_COUNT ? (
                                <MessageBar intent='warning'>
                                    <MessageBarBody>
                                        You can edit a maximum of {MAX_COUNT}{' '}
                                        rows at a time.
                                    </MessageBarBody>
                                </MessageBar>
                            ) : null}

                            <Text>
                                All selected rows will be updated with the same
                                values. You can choose which fields to update
                                below.
                            </Text>
                        </div>

                        {formData.map(
                            ({ title, type, key, options }, index) => (
                                <div
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        gridGap: '8px',
                                        marginBottom: '16px',
                                    }}
                                >
                                    <Field label='Edit'>
                                        <Switch
                                            checked={toggles[index]}
                                            onChange={(_, { checked }) =>
                                                handleToggleChange(
                                                    index,
                                                    key,
                                                    checked
                                                )
                                            }
                                        />
                                    </Field>
                                    <div style={{ flexGrow: '1' }}>
                                        {type === 'boolean' ? (
                                            <Field
                                                label={title}
                                                validationMessage={
                                                    errors?.[key]
                                                }
                                            >
                                                <Switch
                                                    disabled={!toggles[index]}
                                                    checked={
                                                        changeSets
                                                            .find(
                                                                s =>
                                                                    s.path ===
                                                                    key
                                                            )
                                                            ?.value?.toString() ===
                                                        'true'
                                                    }
                                                    onChange={(
                                                        _,
                                                        { checked }
                                                    ) =>
                                                        handleCallback(
                                                            checked.toString(),
                                                            key
                                                        )
                                                    }
                                                />
                                            </Field>
                                        ) : type === 'select' ? (
                                            <AutoSelectDropdown
                                                callback={option =>
                                                    handleCallback(
                                                        option.id,
                                                        key
                                                    )
                                                }
                                                validationMessage={
                                                    errors?.[key]
                                                }
                                                disabled={!toggles[index]}
                                                data={options}
                                                title={title}
                                                selectedId={selectedBinId}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            )
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance='primary'
                            onClick={handleSubmit}
                            disabled={
                                !(
                                    selectedRows.length <= MAX_COUNT &&
                                    toggles.some(t => t)
                                )
                            }
                        >
                            Update
                        </Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default BatchEditModal
