import {
    Button,
    Card,
    CardFooter,
    CardPreview,
    Image,
    Text,
    ToggleButton,
} from '@fluentui/react-components'
import { IFeedbackMedia, IMediaLink } from 'types/media'

import Loader from 'components/UI/Loader'
import MediaTypeEnum from 'constants/MediaTypeEnum'
import { Open16Regular } from '@fluentui/react-icons'
import { useState } from 'react'

interface IGalleryTabsProps {
    feedbackMedia: IFeedbackMedia
    setSelectedMedia: (media: IMediaLink) => void
}

const GalleryTabs = ({
    feedbackMedia,
    setSelectedMedia,
}: IGalleryTabsProps) => {
    const media = feedbackMedia?.mediaLinks || []
    const [tab, setTab] = useState<MediaTypeEnum>(
        media[0]?.sasUri.includes('image')
            ? MediaTypeEnum.Image
            : MediaTypeEnum.Video
    )

    return (
        <>
            <div style={{ padding: 16 }}>
                <Text size={500}>
                    Media for report: {feedbackMedia.reportTitle}
                </Text>
            </div>

            {feedbackMedia?.mediaLinks.length <= 0 ? (
                <Loader
                    error
                    text={`No media found on the feedback with id ${feedbackMedia.reportId}`}
                />
            ) : (
                <>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '16px',
                            gridGap: '32px',
                        }}
                    >
                        {media.some(m => m.sasUri.includes('/image')) && (
                            <ToggleButton
                                appearance='transparent'
                                checked={tab === MediaTypeEnum.Image}
                                onClick={() => setTab(MediaTypeEnum.Image)}
                            >
                                Image
                            </ToggleButton>
                        )}

                        {media.some(m => m.sasUri.includes('/video')) && (
                            <ToggleButton
                                appearance='transparent'
                                checked={tab === MediaTypeEnum.Video}
                                onClick={() => setTab(MediaTypeEnum.Video)}
                            >
                                Video
                            </ToggleButton>
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            padding: '16px 32px',
                            gridGap: '64px',
                        }}
                    >
                        {tab === MediaTypeEnum.Image &&
                            media
                                .filter(m => m.sasUri.includes('/image'))
                                .map(item => (
                                    <Card
                                        key={item.sasUri}
                                        style={{ maxWidth: '30%' }}
                                    >
                                        <CardPreview style={{ height: '100%' }}>
                                            {item.sasUri.includes('/image') && (
                                                <Image
                                                    src={item.sasUri}
                                                    alt={item.name}
                                                    fit='contain'
                                                />
                                            )}
                                        </CardPreview>

                                        <Text>{item.name}</Text>

                                        <CardFooter>
                                            <Button
                                                icon={<Open16Regular />}
                                                onClick={() =>
                                                    setSelectedMedia(item)
                                                }
                                            >
                                                Open
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                ))}

                        {tab === MediaTypeEnum.Video &&
                            media
                                .filter(m => m.sasUri.includes('/video'))
                                .map(m => (
                                    <div
                                        key={m.sasUri}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            gridGap: '16px',
                                        }}
                                    >
                                        <video
                                            src={m.sasUri}
                                            width={300}
                                            height={300}
                                            onClick={() => {
                                                setSelectedMedia(m)
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </div>
                                ))}
                    </div>
                </>
            )}
        </>
    )
}

export default GalleryTabs
