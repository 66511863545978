import { useMemo, useState } from 'react'

import AutoSelectDropdown from 'components/UI/AutoSelectDropdown'
import AutoSelectInput from 'components/UI/AutoSelectInput'
import { Button } from '@fluentui/react-components'
import DialogWithIconButton from 'components/UI/DialogWithIconButton'
import { GridApi } from 'ag-grid-community'
import { PenRegular } from '@fluentui/react-icons'
import qs from 'qs'
import { selectSolutionId } from 'slices/categorySlice'
import { useAppSelector } from 'store'
import { useNavigate } from 'react-router'
import { usePatchBinMutation } from 'api/category'

interface IBinEditModalProps {
    data: Bin
    api: GridApi
    allBins: Bin[]
    allArts: Art[]
}

const BinEditModal = ({ data, api, allBins, allArts }: IBinEditModalProps) => {
    const navigate = useNavigate()
    const [patchBin, { isLoading }] = usePatchBinMutation()
    const selectedSolutionId = useAppSelector(selectSolutionId)

    const [inputValue, setInputValue] = useState(data.name)
    const [selectedArtId, setArtId] = useState<number>(data.artId)

    const bins = useMemo(
        () =>
            allBins.filter(
                bin => bin.artId === selectedArtId && !bin.isArchived
            ),
        [allBins, selectedArtId]
    )

    const arts = useMemo(
        () =>
            allArts
                .filter(({ solutionId }) => solutionId === selectedSolutionId)
                .sort((a, b) => a.name.localeCompare(b.name)),
        [allArts, selectedSolutionId]
    )

    const isDuplicate = useMemo(() => {
        if (inputValue === '' || inputValue === data.name) {
            return false
        }
        return bins.map(({ name }) => name).includes(inputValue)
    }, [inputValue, data.name, bins])

    const handleClick = async (toggleOpen: () => void, rebin: boolean) => {
        const result = await patchBin({
            id: data.id,
            data: [
                {
                    path: 'name',
                    value: inputValue,
                },
                {
                    path: 'artId',
                    value: selectedArtId,
                },
            ],
        })

        if (result) {
            toggleOpen()
            api.refreshCells()
        }
        if ('data' in result && rebin) {
            const filters = {
                bin: {
                    filterType: 'setOpt',
                    values: [result.data],
                },
            }
            const search = qs.stringify(filters, { encode: false })
            const param = new URLSearchParams()
            param.set('filters', search)
            const url = `/?${param.toString()}`
            navigate(url)
        }
    }

    const isEditDisabled =
        isLoading ||
        isDuplicate ||
        inputValue === '' ||
        (inputValue === data.name && selectedArtId === data.artId)

    return (
        <DialogWithIconButton
            icon={<PenRegular />}
            dialogTitle='Edit Bin'
            dialogContent={() => (
                <>
                    <AutoSelectDropdown
                        title='Art'
                        data={arts}
                        selectedId={selectedArtId}
                        callback={option => setArtId(option?.id)}
                    />

                    <AutoSelectInput
                        title='Bin'
                        callback={setInputValue}
                        validationMessage={
                            isDuplicate ? 'Bin already exists' : null
                        }
                        inputValue={inputValue}
                    />
                </>
            )}
            actionRender={toggleOpen => (
                <>
                    <Button
                        style={{ width: '250px' }}
                        appearance='primary'
                        disabled={isEditDisabled}
                        onClick={() => handleClick(toggleOpen, true)}
                    >
                        Edit and view affected feedbacks in grid
                    </Button>
                    <Button
                        appearance='primary'
                        disabled={isEditDisabled}
                        onClick={() => handleClick(toggleOpen, false)}
                    >
                        Edit
                    </Button>
                </>
            )}
        />
    )
}

export default BinEditModal
