import {
    useGetArtsQuery,
    useGetBinsQuery,
    useGetSolutionsQuery,
} from 'api/category'

import ActionsPanel from 'components/BinManagement/actionPanels/ActionsPanel'
import BinManagement from 'components/BinManagement/BinManagement'
import Content from 'components/Layout/Content'
import { DocumentDatabase24Regular } from '@fluentui/react-icons'
import Header from 'components/Layout/Header'
import Loader from 'components/UI/Loader'

const Bins = () => {
    const { data: allSolutions, isLoading: solutionsLoading } =
        useGetSolutionsQuery()
    const { data: allArts, isLoading: artsLoading } = useGetArtsQuery()
    const { data: allBins, isLoading: binsLoading } = useGetBinsQuery()

    if (solutionsLoading || binsLoading || artsLoading) {
        return <Loader text='Loading...' />
    }

    return (
        <>
            <Header
                title='Bin Management'
                icon={<DocumentDatabase24Regular />}
            />
            <ActionsPanel allArts={allArts} allBins={allBins} />
            <Content>
                <BinManagement
                    allSolutions={allSolutions}
                    allArts={allArts}
                    allBins={allBins}
                />
            </Content>
        </>
    )
}

export default Bins
