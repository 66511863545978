import { Button, Text } from '@fluentui/react-components'

import { ArchiveRegular } from '@fluentui/react-icons'
import DialogWithIconButton from 'components/UI/DialogWithIconButton'
import { GridApi } from 'ag-grid-community'
import { selectArchived } from 'slices/categorySlice'
import { useAppSelector } from 'store'
import { usePatchBinMutation } from 'api/category'

interface IBinArchiveModalProps {
    data: Bin
    api: GridApi
}

const BinArchiveModal = ({ data, api }: IBinArchiveModalProps) => {
    const showArchived = useAppSelector(selectArchived)
    const [patchBin, { isLoading }] = usePatchBinMutation()

    const handleClick = async (toggleOpen: () => void) => {
        const result = await patchBin({
            id: data.id,
            data: [
                {
                    path: 'isArchived',
                    value: !showArchived,
                },
            ],
        })

        if (result) {
            toggleOpen()
            api.refreshCells()
        }
    }

    return (
        <DialogWithIconButton
            icon={<ArchiveRegular color={showArchived ? 'red' : 'black'} />}
            dialogTitle={showArchived ? 'Unarchive this?' : 'Archive this?'}
            dialogContent={() => (
                <Text>
                    Are you sure you want to{' '}
                    {showArchived ? 'unarchive' : 'archive'} this Bin?
                </Text>
            )}
            actionRender={toggleOpen => (
                <Button
                    appearance='primary'
                    disabled={isLoading}
                    onClick={() => {
                        handleClick(toggleOpen)
                    }}
                >
                    Confirm
                </Button>
            )}
        />
    )
}

export default BinArchiveModal
