import { Spinner, Text } from '@fluentui/react-components'
import { useEffect, useState } from 'react'

import { ITooltipParams } from 'ag-grid-community'
import { selectDarkMode } from 'slices/preferenceSlice'
import { useAppSelector } from 'store'
import { useGetVehicleTestsQuery } from 'api/vehicleTest'

const VehicleTestsTooltip = ({ data }: ITooltipParams) => {
    const darkMode = useAppSelector(selectDarkMode)
    const { data: vehicleTests = [] } = useGetVehicleTestsQuery()
    const [loading, setLoading] = useState(true)
    const [filteredVehicleTests, setFilteredVehicleTests] = useState([])
    const open = data.vehicleTests !== null

    useEffect(() => {
        const getData = async () => {
            let ids = []

            ids = data.vehicleTests
                ?.split(',')
                .map(d => parseInt(d.split('::')[0]))

            let _filteredVehicleTests = []
            if (ids.length > 0) {
                _filteredVehicleTests = vehicleTests.filter(tp =>
                    ids.includes(tp.id)
                )
            }
            setFilteredVehicleTests(_filteredVehicleTests)
            setLoading(false)
        }

        if (open) {
            getData()
        }
    }, [vehicleTests, data.vehicleTests, open])

    if (!open) {
        return null
    }

    if (loading) {
        return <Spinner size='large' label='Loading Vehicle Tests ...' />
    }

    return (
        <div
            style={{
                backgroundColor: darkMode ? 'rgb(24, 29, 31)' : '#ebebeb',
                border: darkMode ? '1px solid white' : '1px solid black',
                borderRadius: 4,
                padding: 8,
            }}
        >
            <ul style={{ listStyle: 'none' }}>
                {filteredVehicleTests.map((vehicleTest, i) => (
                    <li key={i} style={{ marginBottom: '8px' }}>
                        <Text>
                            <b>Title: </b>
                            {vehicleTest.title}
                        </Text>
                        <Text>
                            <b>Description: </b>
                            {vehicleTest.description}
                        </Text>
                    </li>
                ))}
                {filteredVehicleTests.length === 0 && (
                    <Text>No Vehicle Tests Found</Text>
                )}
            </ul>
        </div>
    )
}

export default VehicleTestsTooltip
