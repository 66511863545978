import Content from 'components/Layout/Content'
import Header from 'components/Layout/Header'
import { LightbulbFilament24Regular } from '@fluentui/react-icons'
import PraisesActions from 'components/Praises/PraisesActions'
import PraisesGrid from 'components/Praises/PraisesGrid'

const Praises = () => {
    return (
        <>
            <Header
                title='Ideas and Praises'
                icon={<LightbulbFilament24Regular />}
            />
            <PraisesActions />

            <Content>
                <PraisesGrid />
            </Content>
        </>
    )
}

export default Praises
