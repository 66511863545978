import ActionsMenu from 'components/Layout/ActionsMenu'
import { ArrowExport24Regular } from '@fluentui/react-icons'
import { IActionsMenu } from 'types/ActionsMenu'
import SuccessBox from 'components/Feedbacks/actionPanel/ExportReport/SuccessBox'
import { fetchOdataAPI } from 'api/genericFunctions'
import { makeStyles } from '@fluentui/react-components'
import { useGrid } from 'contexts/GridContext'
import { useState } from 'react'

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
    },
})

const PraisesActions = () => {
    const classes = useStyles()

    const { gridRef } = useGrid()

    const [csvData, setCsvData] = useState([])
    const [exportDisabled, setExportDisabled] = useState(false)

    const gridState = {
        column: gridRef?.current?.columnApi?.getColumnState(),
    }

    const formatRow = (d: unknown) =>
        gridState.column
            .filter(x => x.colId !== 'checkBoxSelect')
            .reduce((acc, col) => {
                if (typeof d[col.colId] === 'string') {
                    // eslint-disable-next-line quotes
                    let value = d[col.colId].replaceAll('"', "'")
                    const firstChar = value.charAt(0)
                    if (
                        firstChar === '=' ||
                        firstChar === '+' ||
                        firstChar === '-'
                    ) {
                        value = `'${value}` // prevents formula to be evaluated
                    }
                    acc[col.colId] = value
                }
                return acc
            }, {})

    const onExport = async () => {
        setExportDisabled(true)
        setCsvData([])
        const response = await fetchOdataAPI<IPraise>('odata/PraisesIdeas')
        const feedbackList = response.data.map((d: unknown) => formatRow(d))
        setCsvData(feedbackList)
        setExportDisabled(false)
    }

    const items: IActionsMenu[] = [
        {
            label: 'Export to Excel',
            icon: <ArrowExport24Regular />,
            onClick: () => onExport(),
            disabled: exportDisabled,
        },
    ]

    return (
        <>
            <ActionsMenu items={items} />

            <div className={classes.wrapper}>
                {csvData.length > 0 && (
                    <div className={classes.wrapper}>
                        <SuccessBox fileName='Export.csv' csvData={csvData} />
                    </div>
                )}
            </div>
        </>
    )
}

export default PraisesActions
