/* eslint-disable max-len */

import { makeStyles } from '@fluentui/react-components'

const useStyles = makeStyles({
    svg: {
        height: '50px',
        width: '50px',
    },
})

const Reflect = () => {
    const classes = useStyles()

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 128 123.65'
            className={classes.svg}
        >
            <linearGradient
                id='linear-gradient'
                x1='504.62'
                y1='3802.18'
                x2='643.16'
                y2='4042.12'
                gradientTransform='translate(-121.77 -1013.07) scale(.27)'
                gradientUnits='userSpaceOnUse'
            >
                <stop offset='0' stopColor='#1b86d9' />
                <stop offset='.5' stopColor='#137bd0' />
                <stop offset='1' stopColor='#0078d4' />
            </linearGradient>
            <g>
                <path
                    className='rfl-5'
                    fill='#40a5ee'
                    strokeWidth='0px'
                    d='m114.17,73.63c.03.72.06,1.2.06,1.67,0,5.79-.06,11.58.03,17.37.05,2.96-.81,5.2-2.42,7.05-2.33,2.68-5.09,4.27-7.96,5.56-6.65,2.99-13.53,4.17-20.5,4.46-8.96.37-17.85-.45-26.44-4.2-2.62-1.15-5.14-2.86-7.54-4.7-2.48-1.9-3.71-4.87-3.58-8.96.18-5.48.05-10.98.05-16.47,0-.49,0-.99,0-1.47.14-.04.24-.1.27-.06,4.77,5.08,10.41,7.39,16.25,8.88,12.91,3.31,25.8,3.15,38.6-1,4.71-1.53,9.22-3.79,13.17-8.14Z'
                />
                <path
                    className='rfl-3'
                    fill='#2c7cd3'
                    strokeWidth='0px'
                    d='m45.87,51.66c6.97,7.06,14.96,9.15,23.12,10.49,8.98,1.48,17.95,1.15,26.87-.89,4.44-1.02,8.81-2.44,12.88-5.1,1.85-1.21,3.59-2.75,5.44-4.18.02.33.06.79.06,1.25,0,5.84-.05,11.67.03,17.51.04,2.9-.79,5.1-2.37,6.93-2.35,2.72-5.14,4.35-8.05,5.63-8.07,3.55-16.41,4.61-24.84,4.5-7.72-.1-15.37-1.14-22.71-4.55-2.47-1.15-4.83-2.81-7.09-4.6-2.35-1.87-3.48-4.75-3.38-8.64.16-6.02.04-12.06.04-18.35Z'
                />
                <path
                    className='rfl-4'
                    fill='#175abd'
                    strokeWidth='0px'
                    d='m45.87,29.49c5.39,5.72,11.49,8.03,17.79,9.5,12.83,3.01,25.61,2.73,38.26-1.65,4.38-1.51,8.54-3.72,12.2-7.78.05.54.11.9.11,1.27,0,6.09-.02,12.18.02,18.27.01,2.3-.62,4.16-1.8,5.75-1.8,2.42-4.01,3.94-6.32,5.21-5.18,2.82-10.61,4.22-16.13,4.97-9.68,1.32-19.32.98-28.82-2.03-2.98-.94-5.9-2.37-8.74-3.93-1.58-.87-3.02-2.33-4.39-3.76-1.54-1.62-2.23-3.84-2.2-6.56.07-5.84.03-11.68.03-17.52,0-.48,0-.96,0-1.74Z'
                />
                <path
                    className='rfl-7'
                    fill='#0078d4'
                    strokeWidth='0px'
                    d='m79.52,45.78c-7.54-.13-15.49-1.02-23.13-4.54-2.43-1.12-4.85-2.6-7-4.49-4.73-4.15-4.7-9.6-.07-13.97,3.59-3.4,7.71-5.14,11.94-6.34,13.82-3.89,27.59-3.78,41.26,1.29,2.87,1.07,5.63,2.56,8.1,4.86,4.85,4.5,4.84,9.98-.04,14.39-3.83,3.46-8.19,5.12-12.64,6.42-5.91,1.73-11.9,2.34-18.42,2.39Z'
                />
            </g>
            <g>
                <path
                    className='rfl-8'
                    opacity='0.1'
                    strokeWidth='0px'
                    d='m69.51,34.88v56.69c-.01,1.97-1.19,3.74-2.98,4.49-.57.25-1.18.37-1.8.37h-18.71c-.03-.45-.03-.88-.03-1.33,0-.44,0-.89.05-1.33.48-8.49,5.62-15.98,13.3-19.37v-4.94c-17.08-2.75-29.34-19.06-26.63-36.43.02-.12,13.16.23,13.18.11.13-.9-.27-1.78-.04-2.66l18.88-.47c2.64.01,4.77,2.18,4.78,4.86h0Z'
                />
                <path
                    className='rfl-2'
                    opacity='0.2'
                    strokeWidth='0px'
                    d='m46.02,96.43c.28,1.01.57,1.68,1.03,2.66h14.83c1.94-.01,3.68-1.21,4.42-3.03.24-.58.37-1.2.37-1.83v-56.69c0-2.68-2.14-4.85-4.78-4.86h0s-29.35,0-29.35,0c-2.9,17.34,9.17,33.79,26.22,36.74.52.09,1.03.17,1.55.23-7.23,3.49-12.71,12.7-14.16,21.14-.17,1.01-.29,2.02-.34,3'
                />
                <path
                    className='rfl-2'
                    opacity='0.2'
                    strokeWidth='0px'
                    d='m62.12,32.67h-29.35c-2.9,17.34,9.17,33.79,26.23,36.74.35.06.7.11,1.05.16-7.84,4.19-13.51,15.25-14,24.2h16.07c2.63-.02,4.76-2.19,4.78-4.86v-51.38c-.01-2.68-2.14-4.85-4.78-4.86Z'
                />
                <path
                    className='rfl-2'
                    opacity='0.2'
                    strokeWidth='0px'
                    d='m59.5,32.67h-26.73c-2.74,16.37,7.95,32.13,23.78,36.21-5.99,6.96-10.73,15.32-10.73,24.89h13.68c2.64-.01,4.77-2.18,4.78-4.86v-51.38c0-2.68-2.14-4.86-4.78-4.86h0Z'
                />
                <path
                    className='rfl-1'
                    strokeWidth='0px'
                    fill='url(#linear-gradient)'
                    d='m10.99,32.67h47.9c3.25,0,5.4,2.18,5.4,4.87v48.71c0,2.69-2.14,4.87-4.79,4.87H11.6c-2.64,0-4.79-2.18-4.79-4.87v-48.71c0-2.69,2.14-4.87,4.18-4.87Z'
                />
                <path
                    className='rfl-6'
                    fill='#fff'
                    d='m51.81,79.79h-9.16l-5.51-9.43c-.41-.71-.81-1.35-1.19-1.92-.38-.56-.76-1.04-1.15-1.44-.39-.4-.79-.71-1.21-.92s-.88-.32-1.37-.32h-2.15v14.03h-7.98v-36.65h12.65c8.6,0,12.89,3.32,12.89,9.97,0,1.28-.19,2.46-.57,3.54-.38,1.08-.91,2.06-1.61,2.93-.69.87-1.53,1.62-2.51,2.25-.98.63-2.07,1.12-3.27,1.48v.1c.53.17,1.04.45,1.53.83.49.38.97.83,1.43,1.34.46.51.9,1.06,1.32,1.65.42.59.8,1.16,1.15,1.72l6.69,10.84Zm-21.74-30.46v10.2h3.46c1.71,0,3.09-.51,4.13-1.53,1.05-1.04,1.58-2.33,1.58-3.86,0-3.2-1.85-4.8-5.56-4.8h-3.61Z'
                />
            </g>
        </svg>
    )
}

export default Reflect
