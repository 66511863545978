import { makeStyles, shorthands } from '@fluentui/react-components'

export const useMessageStyles = makeStyles({
    textRow: {
        display: 'flex',
        justifyContent: 'space-between',
        ...shorthands.padding('8px', '0'),
    },

    incoming: {
        maxWidth: '200px',
        whiteSpace: 'initial',
        wordWrap: 'break-word',
        alignSelf: 'start',
    },

    incomingInner: {
        backgroundColor: '#EBEBEB',
        color: '#000000',
        ...shorthands.padding('16px'),
        ...shorthands.borderRadius('8px', '8px', '8px', '0px'),
    },

    outgoing: {
        maxWidth: '200px',
        whiteSpace: 'initial',
        wordWrap: 'break-word',
        alignSelf: 'end',
    },

    outgoingInner: {
        backgroundColor: '#284E80',
        color: 'white',
        ...shorthands.padding('16px'),
        ...shorthands.borderRadius('8px', '8px', '0px', '8px'),
    },

    twoWayComMedia: {
        width: '70%',
        alignSelf: 'start',
    },

    twoWayComMediaInner: {
        backgroundColor: '#EBEBEB',
        fontStyle: 'italic',
        ...shorthands.padding('16px'),
        ...shorthands.borderRadius('8px', '8px', '8px', '0px'),
    },

    wrapper: {
        width: '100%',
        height: 'auto',
        ...shorthands.border('1px solid #D5D5D5'),
        ...shorthands.padding('8px'),
    },

    innerWrapper: {
        ...shorthands.borderRadius('1px solid #D5D5D5'),
        ...shorthands.padding('8px'),
    },

    link: {
        color: '#2A609D',
        ...shorthands.textDecoration('none'),
    },

    historyBox: {
        display: 'flex',
        flexDirection: 'column',
        gridGap: '16px',
        ...shorthands.overflow('auto'),
        ...shorthands.border('1px', 'solid', '#D5D5D5'),
        ...shorthands.padding('16px'),
        ...shorthands.borderRadius('8px'),
    },

    sendButton: {
        display: 'flex',
        justifyContent: 'end',
        marginBottom: '8px',
    },

    actionButtonsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gridGap: '8px',
    },

    conversationWrapper: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '580px',
        maxHeight: 'calc(100vh - 450px)',
        maxWidth: '400px',
    },
})
