import { useMemo, useState } from 'react'

import AutoSelectDropdown from 'components/UI/AutoSelectDropdown'
import AutoSelectInput from 'components/UI/AutoSelectInput'
import { Button } from '@fluentui/react-components'
import DialogWithIconButton from 'components/UI/DialogWithIconButton'
import { GridApi } from 'ag-grid-community'
import { PenRegular } from '@fluentui/react-icons'
import { selectArtId } from 'slices/categorySlice'
import { useAppSelector } from 'store'
import { usePatchSubsystemsMutation } from 'api/category'

interface ISubsystemEditModalProps {
    data: Subsystem
    api: GridApi
    allBins: Bin[]
    allSubsystems: Subsystem[]
}

const SubsystemEditModal = ({
    data,
    api,
    allBins,
    allSubsystems,
}: ISubsystemEditModalProps) => {
    const selectedArtId = useAppSelector(selectArtId)

    const [inputValue, setInputValue] = useState(data.name)
    const [selectedBinId, setBinId] = useState(data.binId)

    const [patchSubsystem, { isLoading }] = usePatchSubsystemsMutation()

    const subsystems = useMemo(
        () =>
            allSubsystems.filter(
                system => system.binId === selectedBinId && !system.isArchived
            ),
        [allSubsystems, selectedBinId]
    )

    const bins = useMemo(
        () =>
            allBins
                .filter(({ artId }) => artId === selectedArtId)
                .sort((a, b) => a.name.localeCompare(b.name)),
        [allBins, selectedArtId]
    )

    const isDuplicate = useMemo(() => {
        if (inputValue === '' || inputValue === data.name) {
            return false
        }
        return subsystems.map(({ name }) => name).includes(inputValue)
    }, [inputValue, data.name, subsystems])

    const handleClick = async (toggleOpen: () => void) => {
        const result = await patchSubsystem({
            id: data.id,
            data: [
                {
                    path: 'name',
                    value: inputValue,
                },
                {
                    path: 'binId',
                    value: selectedBinId,
                },
            ],
        })

        if (result) {
            toggleOpen()
            api.refreshCells()
        }
    }

    return (
        <DialogWithIconButton
            icon={<PenRegular />}
            dialogTitle='Edit Subsystem'
            dialogContent={() => (
                <>
                    <AutoSelectDropdown
                        title='Bin'
                        data={bins}
                        selectedId={selectedBinId}
                        callback={option => setBinId(option?.id)}
                    />
                    <AutoSelectInput
                        title='Subsystem'
                        callback={setInputValue}
                        validationMessage={
                            isDuplicate ? 'Subsystem already exists' : null
                        }
                        inputValue={inputValue}
                    />
                </>
            )}
            actionRender={toggleOpen => (
                <Button
                    appearance='primary'
                    disabled={
                        isLoading ||
                        isDuplicate ||
                        inputValue === '' ||
                        (inputValue === data.name &&
                            selectedBinId === data.binId)
                    }
                    onClick={() => handleClick(toggleOpen)}
                >
                    Edit
                </Button>
            )}
        />
    )
}

export default SubsystemEditModal
