import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
} from '@fluentui/react-components'
import { ReactNode, useState } from 'react'

interface IProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: any
    dialogTitle: string
    dialogContent?: () => ReactNode
    actionRender?: (toggleOpen: () => void) => ReactNode
}

const DialogWithIconButton = ({
    icon,
    dialogTitle,
    dialogContent = () => null,
    actionRender = () => null,
}: IProps) => {
    const [isOpen, setOpen] = useState(false)
    const toggleOpen = () => {
        setOpen(prev => !prev)
    }
    return (
        <Dialog
            open={isOpen}
            onOpenChange={(_event, data) => setOpen(data.open)}
        >
            <Button title={dialogTitle} onClick={toggleOpen} icon={icon} />

            <DialogSurface>
                <DialogBody>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>{dialogContent()}</DialogContent>
                    <DialogActions>
                        {actionRender(toggleOpen)}
                        <Button onClick={toggleOpen}>Cancel</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default DialogWithIconButton
