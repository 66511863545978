import { format, parseISO } from 'date-fns'
import { useEffect, useState } from 'react'

import { Text } from '@fluentui/react-components'
import reflectApiAxiosInstance from 'helpers/Axios/ReflectApiAxios'
import { releaseName } from 'utils/envUtils'
import { toast } from 'react-toastify'

const ReleaseInformation = () => {
    const [BEBuildInfo, setBEBuildInfo] = useState<IBuildInfo>()
    const [BEFormattedBuildInfo, setBEFormattedBuildInfo] = useState('')

    useEffect(() => {
        const controller = new AbortController()
        const { signal } = controller

        const getBEBuildInfo = async () => {
            try {
                const response = await reflectApiAxiosInstance.get<IBuildInfo>(
                    'api/BuildInfo/api/build',
                    { signal }
                )
                setBEBuildInfo(response.data)
            } catch (error) {
                toast.error(error)
            }
        }
        getBEBuildInfo()

        return () => {
            controller.abort()
        }
    }, [])

    const formatBuildNumber = rawBuildNumber => {
        if (!rawBuildNumber) return '' // does not work when when run vs (BE) manually
        const buildNumber = rawBuildNumber.split('_')[1]
        const [date, version] = buildNumber.split('.')
        const formattedDate = format(parseISO(date), 'dd/MM/yyyy')
        return `${formattedDate} V-${version}`
    }

    useEffect(() => {
        if (BEBuildInfo)
            setBEFormattedBuildInfo(
                formatBuildNumber(BEBuildInfo?.buildNumber?.split('-')[4])
            )
    }, [BEBuildInfo])

    return (
        <>
            <h3>Release Information</h3>
            <Text italic block>
                BE: {BEFormattedBuildInfo}
            </Text>
            <Text italic block>
                FE: {releaseName}
            </Text>
        </>
    )
}

export default ReleaseInformation
