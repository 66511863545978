import ConversationStatusEnum from 'constants/ConversationStatusEnum'
import { IConversation } from 'types/conversations'
import { apiSlice } from './apiSlice'
import { lowerCaseKeys } from 'helpers/objectManipulation'

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ['Messages'],
})

const messageApi = apiWithTags.injectEndpoints({
    endpoints: builder => ({
        getMessages: builder.query<IConversation[], number>({
            query: formAnswerId =>
                `odata/Conversation?expand=Codev_ReportConversationMessages,Codev_Art&filter=FormAnswerId eq ${formAnswerId}`,
            transformResponse: (response: IODataResponse<IConversation[]>) =>
                lowerCaseKeys(response.value) as unknown as IConversation[],
            providesTags: ['Messages'],
        }),
        postNewConversation: builder.mutation<
            IConversation,
            { formAnswerId: number; message: string; artId?: number }
        >({
            query: ({ formAnswerId, message, artId }) => ({
                url: 'api/Conversation/OpenNew',
                method: 'POST',
                body: { formAnswerId, message, artId },
            }),
            invalidatesTags: ['Messages'],
        }),
        postSendMessage: builder.mutation<
            IConversation,
            { conversationId: number; message: string }
        >({
            query: ({ conversationId, message }) => ({
                url: 'api/Conversation/AddMessage',
                method: 'POST',
                body: { conversationId, message },
            }),
            invalidatesTags: ['Messages'],
        }),
        postCloseConversation: builder.mutation<
            IConversation,
            { conversationId: number; note: string }
        >({
            query: ({ conversationId, note }) => ({
                url: 'api/Conversation/Close',
                method: 'POST',
                body: { conversationId, note },
            }),
            invalidatesTags: ['Messages'],
        }),
        postReOpenConversation: builder.mutation<IConversation, number>({
            query: conversationId => ({
                url: 'api/Conversation/ReOpen',
                method: 'POST',
                body: { conversationId },
            }),
            invalidatesTags: ['Messages'],
        }),
        postUpdateConversationNote: builder.mutation<
            IConversation,
            { conversationId: number; note: string }
        >({
            query: ({ conversationId, note }) => ({
                url: 'api/Conversation/UpdateNote',
                method: 'POST',
                body: { conversationId, note },
            }),
            invalidatesTags: ['Messages'],
        }),
        getConversationStatuses: builder.query<
            typeof ConversationStatusEnum[keyof typeof ConversationStatusEnum][],
            void
        >({
            query: () => '/api/Lists/Values/ConversationStatuses',
        }),
        postMarkAsRead: builder.mutation<
            IConversation,
            { conversationId: number }
        >({
            query: ({ conversationId }) => ({
                url: 'api/Conversation/SetReadByTrillion',
                method: 'POST',
                body: { conversationId },
            }),
            invalidatesTags: ['Messages'],
        }),
    }),
})

export const {
    useGetMessagesQuery,
    usePostNewConversationMutation,
    usePostSendMessageMutation,
    usePostCloseConversationMutation,
    usePostReOpenConversationMutation,
    usePostUpdateConversationNoteMutation,
    useGetConversationStatusesQuery,
    usePostMarkAsReadMutation,
} = messageApi
