import { Button } from '@fluentui/react-components'
import { Question24Filled } from '@fluentui/react-icons'
import { TRILLION_SUPPORT_URL } from 'constants/common'

const SupportButton = () => (
    <Button
        appearance='subtle'
        icon={<Question24Filled />}
        style={{ color: 'white' }}
        onClick={() => window.open(TRILLION_SUPPORT_URL, '_blank')}
    />
)

export default SupportButton
