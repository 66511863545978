import { IFeedbackMedia } from 'types/media'
import { apiSlice } from './apiSlice'
import { lowerCaseKeys } from 'helpers/objectManipulation'

const mediaLinkApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getReportsMedia: builder.query<IFeedbackMedia, number>({
            query: reportId => `/api/ReportsMedia/${reportId}`,
            transformResponse: (response: IFeedbackMedia) =>
                lowerCaseKeys(response) as unknown as IFeedbackMedia,
        }),
    }),
})

export const { useGetReportsMediaQuery } = mediaLinkApi
