import { Divider, Text } from '@fluentui/react-components'

import { DATETIME_FORMAT } from 'constants/common'
import { Feedback } from 'types/feedback'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import routes from 'constants/routes'
import { useGetReportsMediaQuery } from 'api/reportsMedia'
import { useMessageStyles } from 'components/styles/messages'

interface IReportDetailProps<T> {
    data: T
}

const ReportDetail = ({ data }: IReportDetailProps<Feedback>) => {
    const { textRow } = useMessageStyles()
    const { data: report } = useGetReportsMediaQuery(data.id)

    return (
        <div
            style={{
                paddingTop: 16,
                overflowY: 'auto',
                maxHeight: 700,
            }}
        >
            <h3>{data.title}</h3>
            <div className={textRow}>
                <Text>{data.description}</Text>
            </div>
            <Divider />

            <div className={textRow}>
                <Text>Bin: </Text>
                <Text>{data.bin || '---'}</Text>
            </div>
            <Divider />

            <div className={textRow}>
                <Text>Reported Date: </Text>
                <Text>
                    {data.reportedDateAtEvent
                        ? format(
                              new Date(data.reportedDateAtEvent),
                              DATETIME_FORMAT
                          )
                        : '---'}
                </Text>
            </div>
            <Divider />

            <div className={textRow}>
                <Text>Engine Description: </Text>
                <Text>{data.engineDescription || '---'}</Text>
            </div>
            <Divider />

            <div className={textRow}>
                <Text>Frequency (last added): </Text>
                <Text>{data.frequency || '---'}</Text>
            </div>
            <Divider />

            <div className={textRow}>
                <Text>Odomoter: </Text>
                <Text>{data.reportedMileageAtEvent || '---'}</Text>
            </div>
            <Divider />

            <div className={textRow}>
                <Text>Temperature: </Text>
                <Text>{data.temperature || '---'}</Text>
            </div>
            <Divider />

            <div className={textRow}>
                <Text>Weather: </Text>
                <Text>{data.weather || '---'}</Text>
            </div>
            <Divider />

            <div
                style={{
                    flexDirection: 'column',
                    gridGap: '16px',
                    padding: '10px 0',
                }}
            >
                {report?.mediaLinks.length > 0 ? (
                    <>
                        <div className={textRow}>
                            <Text>Media:</Text>
                            <Link
                                target='_blank'
                                to={`${routes.media}/${report.reportId}`}
                            >
                                View attached media
                            </Link>
                        </div>

                        <Divider />
                    </>
                ) : (
                    <Text>
                        <i>No media found for this report</i>
                    </Text>
                )}
            </div>
        </div>
    )
}

export default ReportDetail
