import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
} from '@fluentui/react-components'
import { useMemo, useState } from 'react'

import AutoSelectInput from 'components/UI/AutoSelectInput'
import { usePostCauseRemarksMutation } from 'api/category'

interface IProps {
    isOpen?: boolean
    toggleModal: () => void
    causeRemarks: CauseRemark[]
}

const AddModal = ({ isOpen, toggleModal, causeRemarks }: IProps) => {
    const [inputValue, setInputValue] = useState<string>()

    const [postCauseRemark] = usePostCauseRemarksMutation()

    const isDuplicate = useMemo(() => {
        if (inputValue === '') {
            return false
        }
        return causeRemarks.map(({ name }) => name).includes(inputValue)
    }, [causeRemarks, inputValue])

    const handleClick = async (toggleOpen: () => void) => {
        const data = await postCauseRemark({ name: inputValue })

        if (data) {
            setInputValue('')
            toggleOpen()
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={toggleModal}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Add Cause Remark</DialogTitle>
                    <DialogContent>
                        <AutoSelectInput
                            title='Cause Remark'
                            inputValue={inputValue}
                            callback={name => setInputValue(name)}
                            validationMessage={
                                isDuplicate
                                    ? 'Cause Remark already exists'
                                    : null
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance='primary'
                            disabled={!inputValue || isDuplicate}
                            onClick={() => handleClick(toggleModal)}
                        >
                            Save
                        </Button>
                        <Button onClick={toggleModal}>Cancel</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default AddModal
