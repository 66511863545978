import {
    Button,
    Card,
    CardFooter,
    CardPreview,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Image,
    Spinner,
    Text,
    makeStyles,
} from '@fluentui/react-components'
import { ImageRegular, Open16Regular } from '@fluentui/react-icons'
import { useMemo, useState } from 'react'

import Appgrade from 'components/UI/AppIcons/Appgrade'
import { Feedback } from 'types/feedback'
import { ICellRendererParams } from 'ag-grid-community'
import { IMediaLink } from 'types/media'
import MediaTypeEnum from 'constants/MediaTypeEnum'
import useDialogStyles from 'components/styles/dialog'
import { useGetReportsMediaQuery } from 'api/reportsMedia'
import { useNavigate } from 'react-router'

const useStyles = makeStyles({
    dialogContent: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gridAutoRows: '250px',
        gridGap: '8px',
    },
    thumbnail: {
        height: '85%',
    },
    image: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
})

const MediaModal = ({ node }: ICellRendererParams<Feedback>) => {
    const { id, images, videos, sounds, hasCdpMedia } = node.data

    const [open, setOpen] = useState(false)
    const [selectedImageSrc, setSelectedImageSrc] = useState<string | null>(
        null
    )
    const [selectedVideoSrc, setSelectedVideoSrc] = useState<string | null>(
        null
    )

    const navigate = useNavigate()
    const classes = useStyles()

    const dialogClasses = useDialogStyles()

    const { data: report, isLoading } = useGetReportsMediaQuery(id, {
        skip: !open,
    })

    const mediaObjects = useMemo(() => {
        const temp: IMediaLink[] = []
        if (report?.mediaLinks) {
            temp.push(...report.mediaLinks)
        }
        if (temp.length <= 0) {
            ;['Image', 'Video', 'Sound'].forEach(type => {
                const media = node.data[`${type.toLowerCase()}s`]
                if (media) {
                    temp.push({
                        mediaType: MediaTypeEnum[type],
                        name: `Appgrade ${type}s`,
                        sasUri: media,
                        appgrade: true,
                    })
                }
            })
        }
        return temp
    }, [report?.mediaLinks, node.data])

    if (!hasCdpMedia && !images && !videos && !sounds) {
        return null
    }

    return (
        <Dialog open={open} onOpenChange={(_e, data) => setOpen(data.open)}>
            <DialogTrigger>
                <Button icon={<ImageRegular />} title='Uploaded media' />
            </DialogTrigger>
            <DialogSurface className={dialogClasses.dialogSurface}>
                <DialogBody className={dialogClasses.dialogBody}>
                    <DialogTitle>Uploaded media</DialogTitle>
                    <DialogContent
                        className={
                            selectedImageSrc || selectedVideoSrc
                                ? classes.image
                                : classes.dialogContent
                        }
                    >
                        {isLoading ? (
                            <Spinner label='Loading media...' />
                        ) : mediaObjects.length > 0 ? (
                            <>
                                {selectedImageSrc ? (
                                    <Image
                                        src={selectedImageSrc}
                                        alt='Selected image'
                                        fit='contain'
                                    />
                                ) : selectedVideoSrc ? (
                                    <video src={selectedVideoSrc} controls />
                                ) : (
                                    mediaObjects.map(item => (
                                        <Card key={item.name}>
                                            <CardPreview
                                                className={classes.thumbnail}
                                            >
                                                {item.sasUri.includes(
                                                    '/image'
                                                ) && (
                                                    <Image
                                                        src={item.sasUri}
                                                        alt={item.name}
                                                        fit='contain'
                                                    />
                                                )}
                                                {item.sasUri.includes(
                                                    '/video'
                                                ) && (
                                                    <video
                                                        src={item.sasUri}
                                                        controls
                                                    />
                                                )}
                                                {item.sasUri.includes(
                                                    '/sound'
                                                ) && (
                                                    <audio
                                                        src={item.sasUri}
                                                        controls
                                                    />
                                                )}
                                                {item.appgrade && <Appgrade />}
                                            </CardPreview>

                                            <CardFooter>
                                                <Button
                                                    icon={<Open16Regular />}
                                                    onClick={() => {
                                                        if (item.appgrade) {
                                                            window.open(
                                                                item.sasUri,
                                                                '_blank'
                                                            )
                                                        } else if (
                                                            item.sasUri.includes(
                                                                '/image'
                                                            )
                                                        ) {
                                                            setSelectedImageSrc(
                                                                item.sasUri
                                                            )
                                                        } else if (
                                                            item.sasUri.includes(
                                                                '/video'
                                                            )
                                                        ) {
                                                            setSelectedVideoSrc(
                                                                item.sasUri
                                                            )
                                                        }
                                                    }}
                                                >
                                                    Preview
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    ))
                                )}
                            </>
                        ) : (
                            <Text>No media found for this report</Text>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {(selectedImageSrc || selectedVideoSrc) && (
                            <Button
                                aria-label='close'
                                onClick={() => {
                                    setSelectedImageSrc(null)
                                    setSelectedVideoSrc(null)
                                }}
                            >
                                Close Preview
                            </Button>
                        )}
                        {report?.mediaLinks.length > 0 ? (
                            <Button
                                appearance='primary'
                                onClick={() => navigate(`/media/${id}`)}
                            >
                                Go to gallery page
                            </Button>
                        ) : null}
                        <DialogTrigger>
                            <Button>Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default MediaModal
