import { Button, Field, Select, makeStyles } from '@fluentui/react-components'
import FilterModelEnum, { defaultFilterModel } from 'constants/FilterModelEnum'
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react'

import { DatePicker } from '@fluentui/react-datepicker-compat'
import { Freeform } from 'components/UI/Freeform'
import filterTypeDropdown from 'constants/FilterTypeDropdownValues'

export enum FilterVariantEnum {
    SelectStructureWeeks = 'selectStructureWeeks',
    SelectDateRange = 'selectDateRange',
}

type StructureWeekFilterValuesType = {
    filterVariant: FilterVariantEnum
    filterType: string
    structureWeekIn: Array<string>
    structureWeekFrom: Date | undefined
    structureWeekTo: Date | undefined
    type: FilterModelEnum
}

const defaultFilterValues: StructureWeekFilterValuesType = {
    filterVariant: FilterVariantEnum.SelectStructureWeeks,
    filterType: 'structureWeek',
    structureWeekIn: [],
    structureWeekFrom: undefined,
    structureWeekTo: undefined,
    type: defaultFilterModel.key,
}

interface IStructureWeekFilterProps {
    filterChangedCallback: () => void
    values: string[]
}

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gridGap: '8px',
    },
    inner: {
        display: 'flex',
        gridGap: '8px',
        marginTop: '16px',
    },
})

export const CustomStructureWeekFilter = forwardRef(
    ({ filterChangedCallback, values }: IStructureWeekFilterProps, ref) => {
        const [filterValues, setFilterValues] = useState(defaultFilterValues)

        const classes = useStyles()

        const options = useMemo(() => {
            return values
                .map(option => ({
                    key: option,
                    text: option ?? '(Blanks)',
                }))
                .sort((a, b) => b.text.localeCompare(a.text))
        }, [values])

        useEffect(() => {
            if (
                filterValues.filterVariant ===
                    FilterVariantEnum.SelectStructureWeeks &&
                (filterValues.structureWeekFrom || filterValues.structureWeekTo)
            ) {
                setFilterValues({
                    ...filterValues,
                    structureWeekFrom: undefined,
                    structureWeekTo: undefined,
                })
            }
            if (
                filterValues.filterVariant ===
                    FilterVariantEnum.SelectDateRange &&
                filterValues.structureWeekIn &&
                filterValues.structureWeekIn.length > 0
            ) {
                setFilterValues({
                    ...filterValues,
                    structureWeekIn: [],
                })
            }
        }, [filterValues])

        const onClearClicked = () => {
            setFilterValues(defaultFilterValues)

            setTimeout(filterChangedCallback, 0)
        }

        const onApplyClicked = () => {
            filterChangedCallback()
        }

        useImperativeHandle(ref, () => ({
            externalFilter: null,

            isFilterActive() {
                if (this.externalFilter) {
                    return true
                }
                if (
                    filterValues.filterVariant ===
                    FilterVariantEnum.SelectStructureWeeks
                ) {
                    return filterValues.structureWeekIn?.length
                }
                return (
                    (filterValues.type === FilterModelEnum.Any ||
                        filterValues.type === FilterModelEnum.None) &&
                    filterValues.structureWeekFrom
                )
            },

            getModel() {
                if (
                    filterValues.filterVariant ===
                        FilterVariantEnum.SelectStructureWeeks &&
                    filterValues.structureWeekIn?.length
                ) {
                    return {
                        filterVariant: filterValues.filterVariant,
                        filterType: 'structureWeek',
                        structureWeekIn: filterValues.structureWeekIn,
                        type: FilterModelEnum.In,
                    }
                }

                if (
                    filterValues.filterVariant ===
                    FilterVariantEnum.SelectDateRange
                ) {
                    if (
                        (filterValues.type === FilterModelEnum.GreaterThan ||
                            filterValues.type === FilterModelEnum.LessThan) &&
                        filterValues.structureWeekFrom
                    ) {
                        return {
                            filterVariant: filterValues.filterVariant,
                            filterType: 'structureWeek',
                            structureWeekFrom: filterValues.structureWeekFrom,
                            structureWeekTo: null,
                            type: filterValues.type,
                        }
                    }
                    return {
                        filterVariant: filterValues.filterVariant,
                        filterType: 'structureWeek',
                        structureWeekFrom:
                            filterValues.structureWeekFrom ?? undefined,
                        structureWeekTo:
                            filterValues.structureWeekTo ?? undefined,
                        type: filterValues.type,
                    }
                }

                return undefined
            },

            setModel(model: StructureWeekFilterValuesType) {
                if (!model) {
                    this.externalFilter = null
                    setFilterValues(defaultFilterValues)
                }

                if (model) {
                    this.externalFilter = model
                    setFilterValues(model)
                }
                filterChangedCallback()
            },
        }))

        const onChangeSelectedStructureWeeks = (selected: string[]) => {
            setFilterValues(prev => ({
                ...prev,
                structureWeekIn: selected,
            }))
        }

        return (
            <div className='ag-filter-body-wrapper ag-simple-filter-body-wrapper'>
                <div className={classes.wrapper}>
                    <Field label='Filter by'>
                        <Select
                            value={filterValues.filterVariant}
                            onChange={(_ev, data) =>
                                setFilterValues({
                                    ...filterValues,
                                    filterVariant:
                                        data.value as FilterVariantEnum,
                                })
                            }
                        >
                            <option
                                value={FilterVariantEnum.SelectStructureWeeks}
                            >
                                Select structure week(s)
                            </option>
                            <option value={FilterVariantEnum.SelectDateRange}>
                                Select a week in a date picker
                            </option>
                        </Select>
                    </Field>

                    {filterValues.filterVariant ===
                        FilterVariantEnum.SelectStructureWeeks && (
                        <Freeform
                            label='Structure Week'
                            options={options}
                            placeholder='Select one or several structure weeks'
                            onSelect={onChangeSelectedStructureWeeks}
                            selected={filterValues.structureWeekIn}
                        />
                    )}
                    {filterValues.filterVariant ===
                        FilterVariantEnum.SelectDateRange && (
                        <>
                            <Field label='Type of filter'>
                                <Select
                                    value={filterValues.type}
                                    onChange={(_event, data) => {
                                        setFilterValues({
                                            ...filterValues,
                                            type: data.value as FilterModelEnum,
                                        })
                                    }}
                                >
                                    {filterTypeDropdown.map(o => (
                                        <option key={o.key} value={o.key}>
                                            {o.text}
                                        </option>
                                    ))}
                                </Select>
                            </Field>

                            <Field
                                label={
                                    filterValues.type ===
                                    FilterModelEnum.InRange
                                        ? 'From week'
                                        : 'Week'
                                }
                            >
                                <DatePicker
                                    placeholder='Select a date...'
                                    value={
                                        filterValues.structureWeekFrom
                                            ? new Date(
                                                  filterValues.structureWeekFrom
                                              )
                                            : new Date()
                                    }
                                    onSelectDate={date => {
                                        setFilterValues({
                                            ...filterValues,
                                            structureWeekFrom: date,
                                        })
                                    }}
                                    showWeekNumbers
                                />
                            </Field>

                            {filterValues.type === FilterModelEnum.InRange && (
                                <Field label='To week'>
                                    <DatePicker
                                        placeholder='Select a date...'
                                        value={
                                            filterValues.structureWeekTo
                                                ? new Date(
                                                      filterValues.structureWeekTo
                                                  )
                                                : new Date()
                                        }
                                        onSelectDate={date => {
                                            setFilterValues({
                                                ...filterValues,
                                                structureWeekTo: date,
                                            })
                                        }}
                                        showWeekNumbers
                                    />
                                </Field>
                            )}
                        </>
                    )}

                    <div className={classes.inner}>
                        <Button onClick={onClearClicked}>Clear</Button>
                        <Button onClick={onApplyClicked}>Apply</Button>
                    </div>
                </div>
            </div>
        )
    }
)

CustomStructureWeekFilter.displayName = 'Structure Week Filter'

export default CustomStructureWeekFilter
