import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Field,
    MessageBar,
    MessageBarBody,
    Spinner,
    Switch,
    Text,
    Textarea,
} from '@fluentui/react-components'
import { Feedback, IFeedback } from 'types/feedback'
import { useCallback, useMemo, useState } from 'react'
import {
    useGetArtsQuery,
    useGetBinsQuery,
    useGetCauseRemarksQuery,
    useGetSolutionsQuery,
    useGetSubsystemsQuery,
} from 'api/category'

import AutoSelectDropdown from 'components/UI/AutoSelectDropdown'
import { putAPI } from 'api/genericFunctions'
import { useGrid } from 'contexts/GridContext'

const MAX_COUNT = 250

interface IBatchEditModalProps {
    isOpen: boolean
    hideBatchEditModal: () => void
    selectedRows: Feedback[]
}

// Define the types of options that can be selected in the form fields
type OptionType = Bin | Art | Subsystem | Solution | CauseRemark | ProblemType

interface IFormdata {
    dependencies?: number[] // An array of indices that correspond to the form fields that this field depends on
    options?: OptionType[] // An array of options that can be selected in this dropdown
    title: string // The title of the form field
    key: keyof Feedback // The key of the form field
    type: 'select' | 'text' // The type of form field
    validate?: (value: string) => string // A function that validates the value of the form field
}

interface IChangeset {
    path: keyof Feedback // The key of the data that is being changed
    value?: OptionType | string
}

interface IError {
    path: keyof Feedback
    message: string | OptionType
}

/**
 * BatchEditModal is a React component that provides a form for batch editing data.
 *
 * @param isOpen - Whether the modal is currently open.
 * @param hideBatchEditModal - A callback function that hides the modal when called.
 */
const BatchEditModal = ({
    isOpen,
    hideBatchEditModal,
    selectedRows,
}: IBatchEditModalProps) => {
    const { data: solutions = [] } = useGetSolutionsQuery()
    const { data: arts = [] } = useGetArtsQuery()
    const { data: bins = [] } = useGetBinsQuery()
    const { data: causeRemarks = [] } = useGetCauseRemarksQuery()
    const { data: subsystems = [] } = useGetSubsystemsQuery()

    const [patching, setPatching] = useState(false)

    const { gridRef } = useGrid()

    const sortOptions = (a: OptionType, b: OptionType) =>
        a.name?.localeCompare(b.name)

    // Create an array of objects to define the form fields
    const formData: IFormdata[] = useMemo(
        () => [
            {
                title: 'Solution',
                key: 'solution',
                options: solutions.filter(x => !x.isArchived).sort(sortOptions),
                type: 'select',
                validate: (value: string) => {
                    if (!value || value === '') {
                        return 'Solution cannot be blank'
                    }
                },
            },
            {
                title: 'Art',
                key: 'art',
                options: arts.filter(x => !x.isArchived).sort(sortOptions),
                dependencies: [0], // Art depends on Solution
                type: 'select',
            },
            {
                title: 'Bin',
                key: 'bin',
                options: bins.filter(x => !x.isArchived).sort(sortOptions),
                dependencies: [0, 1], // Bin depends on Solution and Art
                type: 'select',
            },
            {
                title: 'Subsystem',
                key: 'subSystem',
                options: subsystems
                    .filter(x => !x.isArchived)
                    .sort(sortOptions),
                dependencies: [0, 1, 2], // Subsystem depends on Solution, Art, and Bin
                type: 'select',
            },
            {
                title: 'Cause Remark',
                key: 'causeRemark',
                options: [...causeRemarks].sort(sortOptions),
                type: 'select',
            },
            {
                title: 'Comment',
                key: 'comment',
                type: 'text',
            },
            {
                title: 'SW/HW',
                key: 'swHw',
                options: [
                    { id: 0, name: 'Blank' },
                    { id: 1, name: 'Sw' },
                    { id: 2, name: 'Hw' },
                    { id: 3, name: 'SwHw' },
                ],
                type: 'select',
            },
        ],
        [arts, bins, causeRemarks, solutions, subsystems]
    )

    // Initialize the toggles state variable with an array of booleans that corresponds to the form fields
    const [toggles, setToggles] = useState(formData.map(() => false))

    // Initialize the changeSets state variable with an array of IChangeset objects that corresponds to the form fields
    const [changeSets, setChangeSets] = useState<IChangeset[]>(
        formData.map(d => ({ path: d.key }))
    )

    const [errors, setErrors] = useState<IError>()

    /**
     * handleCallback is a callback function that updates the changeSets state variable when the value of a form field changes.
     *
     * @param newValue - The new value for the form field.
     * @param path - The path (or "key") of the form field.
     * @param currentIndex - The index of the form field in the formData array.
     * @param dependencies - An optional array of indices that specifies which form fields this field depends on.
     * @param reason - An optional string that specifies the reason for the change.
     */
    const handleCallback = (
        newValue: OptionType | string,
        path: keyof Feedback,
        currentIndex: number,
        dependencies: number[] = [],
        reason = ''
    ) => {
        const changeSet = []
        const keys = formData.map(d => d.key)

        // add to the changeSet by looping through dependency array backwards
        for (let i = dependencies.length - 1; i >= 0; i--) {
            const lastDependency = dependencies[i]

            // select the target id ether from the last item
            // in the changeSet or from the newValue
            const targetId = changeSet.length
                ? changeSet[changeSet.length - 1].value?.[
                      `${keys[lastDependency]}Id`
                  ]
                : newValue?.[`${keys[lastDependency]}Id`]

            // push the last dependency to the changeSet
            changeSet.push({
                path: keys[lastDependency],
                value: formData[lastDependency].options.find(
                    ({ id }) => id === targetId
                ),
            })
        }

        // Remove the changes with a dependency on the current value
        const pathsToRemove = formData
            .map(data =>
                data.dependencies?.includes(currentIndex) ? data.key : null
            )
            .filter(Boolean)

        // if the reason is clear, push the path to the pathsToRemove array
        // otherwise push the current change to the changeSet
        if (reason === 'clear') {
            pathsToRemove.push(path)
        } else {
            changeSet.push({ path, value: newValue })
        }

        if (errors?.[path]) {
            const newErrors = { ...errors }
            delete newErrors[path]
            setErrors(newErrors)
        }

        // update the changeSet
        setChangeSets(pre =>
            pre.map(p => {
                // if the change is in the changeSet and the path is in the pathsToRemove array
                // set the value to undefined
                if (pathsToRemove.includes(p.path)) {
                    return { ...p, value: undefined }
                }

                const change = changeSet.find(c => c.path === p.path)
                return {
                    ...p,
                    value: change?.value ?? p.value,
                }
            })
        )
    }

    /**
     * handleToggle is a callback function that updates the toggles state variable when the user toggles
     * a form field.
     *
     * @param currentIndex - The index of the form field in the formData array.
     * @param path - The path (or "key") of the form field.
     * @param checked - Whether the form field is checked.
     * @param dependencies - An optional array of indices that specifies which form fields this field depends on.
     */
    const handleToggleChange = (
        currentIndex: number,
        path: keyof Feedback,
        checked: boolean,
        dependencies?: number[]
    ) => {
        // Create a copy of the toggles array
        const temp = [...toggles]

        // Update the value of the current toggle
        temp[currentIndex] = checked

        // If there are dependencies and the toggle is being checked,
        // set the dependent toggles to true
        if (dependencies && checked) {
            dependencies.forEach(
                dependencyIndex => (temp[dependencyIndex] = true)
            )
        }

        // If the toggle is being unchecked, remove all dependent
        // toggles and remove the current key from the changeset
        if (!checked) {
            const pathsToRemove = formData
                .map((data, index) => {
                    if (data.dependencies?.includes(currentIndex)) {
                        temp[index] = false
                        return data.key
                    }
                    return null
                })
                .filter(Boolean)

            pathsToRemove.push(path)

            setChangeSets(pre =>
                pre.map(p =>
                    pathsToRemove.includes(p.path)
                        ? { ...p, value: undefined }
                        : p
                )
            )

            setErrors(pre => {
                if (typeof pre === 'object') {
                    const newErrors = { ...pre }
                    pathsToRemove.forEach(p => {
                        if (Object.hasOwn(newErrors, p)) {
                            delete newErrors[p]
                        }
                    })
                    return newErrors
                }
                return pre
            })
        }

        // Update the state with the modified toggles array
        setToggles(temp)
    }

    // create a filterfuntion to filter the options based on the values of the dependencies
    const getFilteredOptions = useCallback(
        (options: OptionType[], dependencies: number[] = []) => {
            // if there are dependencies, get the last dependency and filter the options
            if (dependencies.length) {
                const lastDependency = dependencies[dependencies.length - 1]
                const key = formData[lastDependency].key
                const value = changeSets[lastDependency].value

                // if the value is undefined, return the options without filtering
                if (changeSets[lastDependency].value === undefined)
                    return options

                // if the value is an object, filter the options by the id
                const id = typeof value === 'object' ? value.id : null
                return options?.filter(o => o[`${key}Id`] === id)
            }
            return options
        },
        [changeSets, formData]
    )

    const handleClose = () => {
        hideBatchEditModal()

        // clear the selectedRows to make that the user gets new data when the modal is opened again
        gridRef.current.api.deselectAll()

        // reset the toggles and changeSets when the modal is closed
        setToggles(pre => pre.map(() => false))
        setChangeSets(pre => pre.map(p => ({ ...p, value: undefined })))
        setErrors(null)
    }

    const handleSubmit = async () => {
        const submitErrors = {}
        // create a new array of objects with the changed data
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const updatedData: Feedback[] = selectedRows.map((row: any) => {
            // clone the row data
            const newRow = { ...row }

            // loop through the changeSets array
            changeSets.forEach(({ path, value }, index) => {
                //only update the rows if toggles[index] is true
                if (toggles[index]) {
                    // check if the field has a validate function
                    if (typeof formData[index].validate === 'function') {
                        const error = formData[index].validate(
                            typeof value === 'object' ? value.name : value
                        )

                        if (error) {
                            submitErrors[path] = error
                        }
                    }

                    // if the value is an object, set the id and name properties
                    if (typeof value == 'object') {
                        newRow[`${path}Id`] = value.id
                        newRow[path] = value.name
                        // if the value is a string, set the property
                    } else if (typeof value == 'string') {
                        newRow[path] = value
                        // else set the property to null
                    } else {
                        newRow[path] = null
                        if (`${path}Id` in newRow) {
                            newRow[`${path}Id`] = null
                        }
                    }
                } else {
                    // get the dependency property from the formData array
                    const dependency = formData[index].dependencies

                    // if any of the dependencies are toggled meaning that
                    // they have been altered, set the property to null
                    if (dependency && dependency.some(d => toggles[d])) {
                        newRow[path] = null
                        if (`${path}Id` in newRow) {
                            newRow[`${path}Id`] = null
                        }
                    }
                }
            })

            return newRow
        })

        if (Object.keys(submitErrors).length <= 0) {
            setPatching(true)
            const response = await putAPI<IFeedback>(
                'api/Feedback',
                updatedData
            )

            if (response) {
                // refresh the table data
                gridRef.current?.api.refreshServerSide({ purge: true })

                // close the modal when the data has been updated
                handleClose()
            }
            setPatching(false)
        } else {
            setErrors(submitErrors as IError)
        }
    }

    // get id from key (i.e. path) in changeSets
    const getSelectedIdFromKey = (key: string) => {
        const value = changeSets.find(s => s.path === key)?.value
        return typeof value === 'object' ? value?.id : null
    }

    // check if all selected rows have the same value for a property
    const isSameValue = (key: string) => {
        if (selectedRows?.length === 0 || selectedRows?.length === undefined)
            return false
        const value = selectedRows[0]?.[key]
        return selectedRows.every(row => row?.[key] === value)
    }

    return (
        <Dialog onOpenChange={handleClose} open={isOpen}>
            <DialogSurface>
                <DialogBody
                    style={{
                        maxWidth: '800px !important',
                        width: '90% !important',
                    }}
                >
                    <DialogTitle>
                        {`Editing ${selectedRows?.length} selected row(s)`}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ marginBottom: '8px', gridGap: '8px' }}>
                            {selectedRows?.length > MAX_COUNT ? (
                                <MessageBar intent='info'>
                                    <MessageBarBody>
                                        You can edit a maximum of {MAX_COUNT}{' '}
                                        rows at a time.
                                    </MessageBarBody>
                                </MessageBar>
                            ) : null}

                            {!isSameValue('solutionId') ? (
                                <MessageBar
                                    intent='warning'
                                    style={{ marginBottom: '16px' }}
                                >
                                    <MessageBarBody>
                                        The feedbacks you have selected have
                                        different solutions.
                                    </MessageBarBody>
                                </MessageBar>
                            ) : null}

                            <Text>
                                All selected feedbacks will be updated with the
                                values you specify below. All fields are
                                optional and you can choose which fields to
                                update by switching the toggle to Yes. If you
                                leave a field blank, it will remove the data
                                from the selected field for all selected
                                feedbacks.
                            </Text>
                            <Text>
                                If you change a field that has a dependency, the
                                dependent fields will be automatically cleard.
                                For example, if you change the
                                &apos;Solution&apos; field, the &apos;Art&apos;
                                field will be cleared even if you have not
                                selected it to be updated.
                            </Text>
                        </div>

                        {formData.map(
                            (
                                { options, title, dependencies, type, key },
                                index
                            ) => (
                                <div
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        gridGap: '8px',
                                        marginBottom: '16px',
                                    }}
                                >
                                    <Field label='Edit'>
                                        <Switch
                                            checked={toggles[index]}
                                            onChange={(_, { checked }) =>
                                                handleToggleChange(
                                                    index,
                                                    key,
                                                    checked,
                                                    dependencies
                                                )
                                            }
                                        />
                                    </Field>
                                    <div style={{ flexGrow: '1' }}>
                                        {type === 'text' ? (
                                            <Field
                                                label={title}
                                                validationMessage={
                                                    errors?.[key]
                                                }
                                            >
                                                <Textarea
                                                    disabled={!toggles[index]}
                                                    value={
                                                        changeSets
                                                            .find(
                                                                s =>
                                                                    s.path ===
                                                                    key
                                                            )
                                                            ?.value?.toString() ??
                                                        ''
                                                    }
                                                    onChange={(_event, data) =>
                                                        handleCallback(
                                                            data.value,
                                                            key,
                                                            index
                                                        )
                                                    }
                                                />
                                            </Field>
                                        ) : type === 'select' ? (
                                            <AutoSelectDropdown
                                                callback={(option, reason) =>
                                                    handleCallback(
                                                        option,
                                                        key,
                                                        index,
                                                        dependencies,
                                                        reason
                                                    )
                                                }
                                                disabled={!toggles[index]}
                                                data={getFilteredOptions(
                                                    options,
                                                    dependencies
                                                )}
                                                title={title}
                                                validationMessage={
                                                    errors?.[key]
                                                }
                                                subTitle={
                                                    dependencies
                                                        ? ` (dependent on ${dependencies
                                                              .map(
                                                                  dep =>
                                                                      formData[
                                                                          dep
                                                                      ].title
                                                              )
                                                              .reverse()
                                                              .join(' > ')})`
                                                        : null
                                                }
                                                selectedId={getSelectedIdFromKey(
                                                    key
                                                )}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            )
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            appearance='primary'
                            onClick={handleSubmit}
                            disabled={
                                !(
                                    selectedRows?.length <= MAX_COUNT &&
                                    toggles.some(t => t) &&
                                    !patching
                                )
                            }
                            icon={patching ? <Spinner /> : undefined}
                        >{`${patching ? 'Updating' : 'Update'} ${
                            selectedRows?.length
                        } feedback(s)`}</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default BatchEditModal
