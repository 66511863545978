import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Field,
    MessageBar,
    MessageBarBody,
    Switch,
    Text,
} from '@fluentui/react-components'
import { useMemo, useState } from 'react'

import { useGrid } from 'contexts/GridContext'
import { usePatchCauseRemarksMutation } from 'api/category'

const MAX_COUNT = 250

interface IBatchEditModalProps {
    isOpen: boolean
    toggleModal: () => void
    selectedRows: CauseRemark[]
}

interface IFormData {
    title: string // The title of the form field
    key: keyof CauseRemark // The key of the form field
    type: 'boolean' // The type of form field
}

interface IChangeset {
    path: keyof CauseRemark // The key of the data that is being changed
    value: boolean | string // The value of the data that is being changed
}

interface IError {
    path: keyof CauseRemark
    message: string
}

/**
 * BatchEditModal is a React component that provides a form for batch editing data.
 *
 * @param isOpen - Whether the modal is currently open.
 * @param toggleModal - A callback function that hides the modal when called.
 */
const BatchEditModal = ({
    isOpen,
    toggleModal,
    selectedRows,
}: IBatchEditModalProps) => {
    const { gridRef } = useGrid()

    const [patchCauseRemark] = usePatchCauseRemarksMutation()

    // Create an array of objects to define the form fields
    const formData: IFormData[] = useMemo(
        () => [
            {
                title: 'Delete',
                key: 'isDeleted',
                type: 'boolean',
            },
        ],
        []
    )

    // Initialize the toggles state variable with an array of booleans that corresponds to the form fields
    const [toggles, setToggles] = useState(formData.map(() => false))

    // Initialize the changeSets state variable with an array of IChangeset objects that corresponds to the form fields
    const [changeSets, setChangeSets] = useState<IChangeset[]>(
        formData.map(d => ({ path: d.key, value: undefined }))
    )

    const [errors, setErrors] = useState<IError>()

    const handleCallback = (newValue: string, path: keyof CauseRemark) => {
        if (errors?.[path]) {
            const newErrors = { ...errors }
            delete newErrors[path]
            setErrors(newErrors)
        }

        setChangeSets(pre =>
            pre.map(p => (p.path === path ? { ...p, value: newValue } : p))
        )
    }

    const handleToggleChange = (
        currentIndex: number,
        path: keyof CauseRemark,
        checked: boolean
    ) => {
        // Create a copy of the toggles array
        const temp = [...toggles]

        // Update the value of the current toggle
        temp[currentIndex] = checked

        // If the toggle is being unchecked remove the current key from the changeset and the errors
        if (!checked) {
            setErrors(pre => {
                const newErrors = { ...pre }
                delete newErrors[path]
                return newErrors
            })

            // Update the state with the modified changeSets array
            setChangeSets(pre =>
                pre.map(p => (p.path === path ? { ...p, value: undefined } : p))
            )
        } else {
            // set the value of the current key to the default value
            if (formData[currentIndex].type === 'boolean') {
                setChangeSets(pre =>
                    pre.map(p =>
                        p.path === path ? { ...p, value: 'false' } : p
                    )
                )
            }
        }

        // Update the state with the modified toggles array
        setToggles(temp)
    }

    const handleClose = () => {
        // clear the selectedRows to make that the user gets new data when the modal is opened again
        gridRef.current.api.deselectAll()

        // reset the toggles and changeSets when the modal is closed
        setToggles(pre => pre.map(() => false))
        setChangeSets(pre => pre.map(p => ({ ...p, value: undefined })))
        setErrors(null)

        toggleModal()
    }

    const handleSubmit = async () => {
        await Promise.all(
            selectedRows.map(row => {
                return patchCauseRemark({
                    id: row.id,
                    data: changeSets.filter((_p, i) => toggles[i]),
                })
            })
        )
        handleClose()
    }

    return (
        <Dialog onOpenChange={handleClose} open={isOpen}>
            <DialogSurface>
                <DialogBody
                    style={{
                        maxWidth: '800px !important',
                        width: '90% !important',
                    }}
                >
                    <DialogTitle>
                        {`Editing ${selectedRows.length} selected row(s)`}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ marginBottom: '8px', gridGap: '8px' }}>
                            {selectedRows.length > MAX_COUNT ? (
                                <MessageBar intent='info'>
                                    <MessageBarBody>
                                        You can edit a maximum of {MAX_COUNT}{' '}
                                        rows at a time.
                                    </MessageBarBody>
                                </MessageBar>
                            ) : null}

                            <Text>
                                All selected rows will be updated with the same
                                values. You can choose which fields to update
                                below.
                            </Text>
                        </div>

                        {formData.map(({ title, type, key }, index) => (
                            <div
                                key={key}
                                style={{
                                    display: 'flex',
                                    gridGap: '8px',
                                    marginBottom: '16px',
                                }}
                            >
                                <Field label='Edit'>
                                    <Switch
                                        checked={toggles[index]}
                                        onChange={(_, { checked }) =>
                                            handleToggleChange(
                                                index,
                                                key,
                                                checked
                                            )
                                        }
                                    />
                                </Field>
                                <div style={{ flexGrow: '1' }}>
                                    {type === 'boolean' ? (
                                        <Field
                                            label={title}
                                            validationMessage={errors?.[key]}
                                        >
                                            <Switch
                                                disabled={!toggles[index]}
                                                checked={
                                                    changeSets
                                                        .find(
                                                            s => s.path === key
                                                        )
                                                        ?.value?.toString() ===
                                                    'true'
                                                }
                                                onChange={(_, { checked }) =>
                                                    handleCallback(
                                                        checked.toString(),
                                                        key
                                                    )
                                                }
                                            />
                                        </Field>
                                    ) : null}
                                </div>
                            </div>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance='primary'
                            onClick={handleSubmit}
                            disabled={
                                !(
                                    selectedRows.length <= MAX_COUNT &&
                                    toggles.some(t => t)
                                )
                            }
                        >
                            Update
                        </Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default BatchEditModal
