import { apiSlice } from './apiSlice'
import { lowerCaseKeys } from 'helpers/objectManipulation'

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ['Solutions', 'Arts', 'Bins', 'Subsystems', 'CauseRemarks'],
})

const categoryApi = apiWithTags.injectEndpoints({
    endpoints: builder => ({
        getSolutions: builder.query<Solution[], string | void>({
            query: (options = '') => `odata/Solution${options}`,
            transformResponse: (response: IODataResponse<Solution>) =>
                (lowerCaseKeys(response.value) as unknown as Solution[]).filter(
                    ({ isDeleted }) => !isDeleted
                ),
            providesTags: ['Solutions'],
        }),

        getArts: builder.query<Art[], string | void>({
            query: (options = '') => `odata/Art${options}`,
            transformResponse: (response: IODataResponse<Art>) =>
                (lowerCaseKeys(response.value) as unknown as Art[]).filter(
                    ({ isDeleted }) => !isDeleted
                ),
            providesTags: ['Arts'],
        }),

        getBins: builder.query<Bin[], string | void>({
            query: (options = '') => `odata/Bin${options}`,
            transformResponse: (response: IODataResponse<Bin[]>) =>
                (lowerCaseKeys(response.value) as unknown as Bin[]).filter(
                    ({ isDeleted }) => !isDeleted
                ),
            providesTags: ['Bins'],
        }),
        postBin: builder.mutation<
            Bin,
            Pick<Bin, 'name' | 'artId' | 'isArchived'>
        >({
            query: data => ({
                url: 'api/Bin',
                method: 'POST',
                body: data,
            }),
            // Optimistic update
            // Could possibly be a future problem if several requests are made in a short time
            // as we are guessing the id, but for now, it's fine... I guess
            onQueryStarted(data, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    categoryApi.util.updateQueryData(
                        'getBins',
                        undefined,
                        draft => {
                            draft.push({
                                ...data,
                                id: draft[draft.length - 1].id + 1,
                                isDeleted: false,
                            })
                        }
                    )
                )
                queryFulfilled.catch(patchResult.undo)
            },
            transformResponse: (response: Bin) => {
                return lowerCaseKeys(response) as unknown as Bin
            },
            invalidatesTags: ['Bins'],
        }),
        deleteBin: builder.mutation<Bin, Bin['id']>({
            query: id => ({
                url: `api/Bin/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Bins'],
        }),
        patchBin: builder.mutation<
            Bin,
            { id: Bin['id']; data: IPatchData<Bin>[] }
        >({
            query: ({ id, data }) => ({
                url: `api/Bin/${id}`,
                method: 'PATCH',
                body: data,
            }),
            transformResponse: (response: Bin) =>
                lowerCaseKeys(response) as unknown as Bin,
            invalidatesTags: ['Bins'],
        }),

        getSubsystems: builder.query<Subsystem[], string | void>({
            query: (options = '') => `odata/Subsystems${options}`,
            transformResponse: (response: IODataResponse<Subsystem>) =>
                (
                    lowerCaseKeys(response.value) as unknown as Subsystem[]
                ).filter(({ isDeleted }) => !isDeleted),
            providesTags: ['Subsystems'],
        }),
        postSubsystems: builder.mutation<
            Subsystem,
            Pick<Subsystem, 'name' | 'binId'>
        >({
            query: data => ({
                url: 'api/Subsystems',
                method: 'POST',
                body: data,
            }),
            // Optimistic update
            // Could possibly be a future problem if several requests are made in a short time
            // as we are guessing the id, but for now, it's fine... I guess
            onQueryStarted(data, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    categoryApi.util.updateQueryData(
                        'getSubsystems',
                        undefined,
                        draft => {
                            draft.push({
                                ...data,
                                id: draft[draft.length - 1].id + 1,
                                isDeleted: false,
                                isArchived: false,
                            })
                        }
                    )
                )
                queryFulfilled.catch(patchResult.undo)
            },
            transformResponse: (response: Subsystem) => {
                return lowerCaseKeys(response) as unknown as Subsystem
            },
            invalidatesTags: ['Subsystems'],
        }),
        deleteSubsystems: builder.mutation<Subsystem, Subsystem['id']>({
            query: id => ({
                url: `api/Subsystems/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, arg) => [
                { type: 'Subsystems', id: arg },
            ],
        }),
        patchSubsystems: builder.mutation<
            Subsystem,
            { id: Subsystem['id']; data: IPatchData<Subsystem>[] }
        >({
            query: ({ id, data }) => ({
                url: `api/Subsystems/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Subsystems'],
        }),

        getCauseRemarks: builder.query<CauseRemark[], void>({
            query: () => 'api/CauseRemark',
            transformResponse: (response: ICauseRemark) =>
                (lowerCaseKeys(response) as unknown as CauseRemark[]).filter(
                    ({ isDeleted }) => !isDeleted
                ),
            providesTags: ['CauseRemarks'],
        }),
        postCauseRemarks: builder.mutation<
            CauseRemark,
            Pick<CauseRemark, 'name'>
        >({
            query: data => ({
                url: 'api/CauseRemark',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['CauseRemarks'],
        }),
        deleteCauseRemarks: builder.mutation<CauseRemark, CauseRemark['id']>({
            query: id => ({
                url: `api/CauseRemark/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['CauseRemarks'],
        }),
        patchCauseRemarks: builder.mutation<
            CauseRemark,
            { id: CauseRemark['id']; data: IPatchData<CauseRemark>[] }
        >({
            query: ({ id, data }) => ({
                url: `api/CauseRemark/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['CauseRemarks'],
        }),
    }),
})

export const {
    useGetSolutionsQuery,

    useGetArtsQuery,

    useGetBinsQuery,
    usePostBinMutation,
    useDeleteBinMutation,
    usePatchBinMutation,

    useGetSubsystemsQuery,
    usePostSubsystemsMutation,
    useDeleteSubsystemsMutation,
    usePatchSubsystemsMutation,

    useGetCauseRemarksQuery,
    usePostCauseRemarksMutation,
    useDeleteCauseRemarksMutation,
    usePatchCauseRemarksMutation,
} = categoryApi
