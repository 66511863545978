import {
    Button,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridProps,
    DataGridRow,
    TableColumnDefinition,
    TableRowId,
    Text,
    Textarea,
    createTableColumn,
} from '@fluentui/react-components'

import { DATETIME_FORMAT_SMALL } from 'constants/common'
import { IConversation } from 'types/conversations'
import Restricted from 'components/Restricted'
import { format } from 'date-fns'
import { permissions } from 'slices/authSlice'
import { toast } from 'react-toastify'
import { useAppSelector } from 'store'
import { usePostUpdateConversationNoteMutation } from 'api/messages'
import { useState } from 'react'

interface IConversationProps {
    items: IConversation[]
    selectedItem: IConversation
    handleSelection: (item: IConversation) => void
}

export interface IConversationImperativeDeclarations {
    resetSelection: () => void
}

const ConversationHistory = ({
    items,
    selectedItem,
    handleSelection,
}: IConversationProps) => {
    const [note, setNote] = useState<string>(selectedItem?.note || '')
    const [selectedRows, setSelectedRows] = useState(
        new Set<TableRowId>([items.findIndex(x => x.id === selectedItem?.id)])
    )
    const onSelectionChange: DataGridProps['onSelectionChange'] = (
        _e,
        data
    ) => {
        const selected = items[[...data.selectedItems][0]]
        setSelectedRows(data.selectedItems)
        handleSelection(selected)
        setNote(selected?.note || '')
    }

    const [updateConversationNote] = usePostUpdateConversationNoteMutation()

    const hasManageTwoWayComPermission =
        useAppSelector(permissions).manageTwoWayCom

    const columns: TableColumnDefinition<IConversation>[] = [
        createTableColumn({
            columnId: 'art',
            compare: (a, b) => (a.artId || 0) - (b.artId || 0),
            renderHeaderCell: () => 'Art',
            renderCell: item => item.codev_Art?.name,
        }),
        createTableColumn({
            columnId: 'startedDate',
            compare: (a, b) =>
                new Date(a.startedDate).getTime() -
                new Date(b.startedDate).getTime(),
            renderHeaderCell: () => 'Opened',
            renderCell: item =>
                format(new Date(item.startedDate), DATETIME_FORMAT_SMALL),
        }),
        createTableColumn({
            columnId: 'endedDate',
            compare: (a, b) =>
                new Date(a.endedDate).getTime() -
                new Date(b.endedDate).getTime(),
            renderHeaderCell: () => 'Closed',
            renderCell: item =>
                item.endedDate
                    ? format(new Date(item.endedDate), DATETIME_FORMAT_SMALL)
                    : 'OPEN',
        }),
        createTableColumn({
            columnId: 'messageCount',
            compare: (a, b) =>
                (a.codev_ReportConversationMessages?.length || 0) -
                (b.codev_ReportConversationMessages?.length || 0),
            renderHeaderCell: () => '#',
            renderCell: item => item?.codev_ReportConversationMessages?.length,
        }),
    ]

    const handleUpdateNote = () => {
        updateConversationNote({
            conversationId: selectedItem?.id,
            note,
        }).then(() => {
            toast.success('The note is updated Successfully')
        })
    }

    return (
        <div style={{ paddingTop: 8 }}>
            <DataGrid
                sortable
                selectionMode='single'
                items={items}
                columns={columns}
                selectedItems={selectedRows}
                onSelectionChange={onSelectionChange}
                style={{ marginBottom: '16px' }}
            >
                <DataGridHeader>
                    <DataGridRow>
                        {({ renderHeaderCell }) => (
                            <DataGridHeaderCell>
                                {renderHeaderCell()}
                            </DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody>
                    {({ item, rowId }) => (
                        <DataGridRow key={rowId}>
                            {({ renderCell }) => (
                                <DataGridCell>{renderCell(item)}</DataGridCell>
                            )}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>

            <Restricted to='readTwoWayCom'>
                <div
                    style={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '16px',
                    }}
                >
                    <div>
                        <Text block size={500}>
                            Conversation Notes
                        </Text>
                        <Text size={200}>
                            Internal notes, driver will not see this.
                        </Text>
                    </div>
                    <Button
                        appearance='primary'
                        onClick={handleUpdateNote}
                        disabled={
                            !selectedItem || !hasManageTwoWayComPermission
                        }
                    >
                        Update notes
                    </Button>
                </div>

                <Textarea
                    value={note}
                    style={{ width: '100%' }}
                    rows={5}
                    placeholder='Conversation notes goes here...'
                    onChange={(_e, { value }) => setNote(value)}
                    disabled={!hasManageTwoWayComPermission}
                />
            </Restricted>
        </div>
    )
}

export default ConversationHistory
