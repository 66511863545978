import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { acquireApiAccessTokenRoles } from 'authInstance'
import Permissions from 'constants/PermissionsEnum'
import ViewsEnum from 'constants/views'
import { loadState } from 'helpers/localStorage'
import { RootState } from '../store'

interface IAuthorizationState {
    views: View[]
    user: {
        name: string
        roles: {
            key: string
            isDisabled?: boolean
        }[]
        view?: View
    }
}

// load persisted state from local storage
const persistedState = loadState()
const initialState: IAuthorizationState = {
    views: [],
    user: persistedState?.user ?? {
        name: '',
        roles: [],
    },
}

const availableViews = apiRoles => {
    const views: View[] = []

    if (apiRoles.includes('Trillion.Views.CMQ')) {
        views.push(ViewsEnum.cmq)
    }

    if (apiRoles.includes('Trillion.Views.CoDev')) {
        views.push(ViewsEnum.codev)
    }

    if (apiRoles.includes('Trillion.Views.Market.Americas')) {
        views.push(ViewsEnum.america)
    }

    if (apiRoles.includes('Trillion.Views.Market.China')) {
        views.push(ViewsEnum.china)
    }

    if (apiRoles.includes('Trillion.Views.Media')) {
        views.push(ViewsEnum.media)
    }

    return [...new Set(views)]
}

// fetch user from auth provider and return user and available views
// if user has changed, return new user and views otherwise return initial state
export const fetchUser = createAsyncThunk(
    'authorization/fetchUser',
    async () => {
        const {
            apiAccessTokenRoles,
            preferredUsername,
        }: {
            apiAccessTokenRoles: string[]
            preferredUsername: string
        } = await acquireApiAccessTokenRoles()

        const views = availableViews(apiAccessTokenRoles)

        //compare user name from auth provider with persisted user name
        const newName = initialState.user.name !== preferredUsername

        // compare user roles from auth provider with persisted user roles
        const newRoles =
            apiAccessTokenRoles.some(
                r => !initialState.user.roles.find(role => role.key === r)
            ) || apiAccessTokenRoles.length !== initialState.user.roles.length

        // if user name or roles have changed, return new user and views
        if (newName || newRoles) {
            return {
                user: {
                    name: preferredUsername,
                    roles: apiAccessTokenRoles.map(r => ({
                        key: r,
                        isDisabled: false,
                    })),
                    view: views[0],
                },
                views: views,
            } as IAuthorizationState
        }

        return {
            user: initialState.user,
            views: views,
        }
    }
)

export const authSlice = createSlice({
    name: 'authorization',
    initialState,
    reducers: {
        updateRole: (state, action) => {
            const role = state.user.roles.find(
                r => r.key === action.payload.key
            )
            if (role) {
                role.isDisabled = action.payload.isDisabled
            }
        },
        setView: (state, action) => {
            state.user.view = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.user = action.payload.user
            state.views = action.payload.views
        })
    },
})

export const { updateRole, setView } = authSlice.actions

export const selectViews = (state: RootState) => state.auth.views
export const selectUser = (state: RootState) => state.auth.user

// creates a memoized selector that returns the active views
export const selectActiveRoles = createSelector(selectUser, user =>
    user.roles.filter(r => !r.isDisabled).map(r => r.key)
)

// creates a memoized selector that returns the permissions for the active view
export const permissions = createSelector([selectActiveRoles], roles => ({
    managementBin: roles.includes(Permissions.managementBin),
    managementSubsystem: roles.includes(Permissions.managementSubsystem),
    managementCauseRemark: roles.includes(Permissions.managementCauseRemark),
    readTwoWayCom: roles.includes(Permissions.readTwoWayCom),
    manageTwoWayCom: roles.includes(Permissions.manageTwoWayCom),
    inlineEdit: roles.includes(Permissions.inlineEdit),
    batchEdit: roles.includes(Permissions.batchEdit),
    noShow: roles.includes(Permissions.noShow),
    media: roles.includes(Permissions.media),
    praises: roles.includes(Permissions.praises),
}))

export default authSlice.reducer
