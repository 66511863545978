import { Button, Text } from '@fluentui/react-components'
import { useCallback, useEffect, useState } from 'react'

import CloseButton from 'components/UI/CloseButon'
import FilterModelEnum from 'constants/FilterModelEnum'
import { IToolPanelParams } from 'ag-grid-community'
import formatISO9075 from 'date-fns/formatISO9075'

type FilterModel = {
    dateFrom?: string
    dateTo?: string
    type?: string
    operator?: string
}

interface IFilterModel {
    [key: string]: unknown
}

const getDateFilterString = (filter: FilterModel) => {
    if (!filter) return ''

    let returnString = ''

    const format = (date: Date) =>
        date ? formatISO9075(date, { representation: 'date' }) : ''

    // This might fail if there are more then 9 conditions to a filter.
    // I don't know if that is possible, but if it is, this will need to be changed
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9]

    numbers.forEach(number => {
        if (Object.keys(filter).includes(`condition${number}`)) {
            const condition = filter[`condition${number}`]

            const fromDate = new Date(condition.dateFrom)
            const toDate = new Date(condition.dateTo)

            returnString += ` ${condition.type} ${format(fromDate)}${
                condition.type === FilterModelEnum.InRange
                    ? ` to ${format(toDate)}`
                    : ''
            } ${filter.operator}`
        }
    })

    if (returnString) return ` (${returnString.replace(/(AND|OR)$/, '')})`

    const fromDate = new Date(filter.dateFrom)
    const toDate = new Date(filter.dateTo)

    return ` (${filter.type} ${format(fromDate)}${
        filter.type === FilterModelEnum.InRange ? ` to ${format(toDate)}` : ''
    })`
}

const getSetFilterString = (values: Array<string>) => {
    if (!values) return ''

    const valuesArray = values.slice(0)

    if (valuesArray.length <= 3) {
        return ` (${valuesArray.join(', ')})`
    }

    const newValuesArray = valuesArray.slice(0, 3)

    return ` (${newValuesArray.join(', ')} +${
        valuesArray.length - newValuesArray.length
    } more)`
}

const getFilterValues = (filterKey: string, filterModel: FilterModel) => {
    const filter = filterModel[filterKey]

    switch (filter.filterType) {
        case 'date':
            return getDateFilterString(filter)
        case 'setOpt':
            return getSetFilterString(filter.values.map(v => v.name))
        case 'valueRange':
            return getSetFilterString(filter.values.concat(filter.ranges))
        default:
            return getSetFilterString(filter.values)
    }
}

const ActiveFiltersPanel = ({ api }: IToolPanelParams) => {
    const [filterModel, setFilterModel] = useState<IFilterModel>({})
    const filterCount = Object.keys(filterModel)?.length ?? 0

    const getFilterModel = useCallback(() => {
        setFilterModel(api.getFilterModel())
    }, [api])

    useEffect(() => {
        api.addEventListener('modelUpdated', getFilterModel)
        return () => api.removeEventListener('modelUpdated', getFilterModel)
    }, [api, getFilterModel])

    const clearAllFilters = () => {
        api?.setFilterModel({})
    }

    const removeFilterFromModel = (filterKey: string) => {
        const newFilterModel = filterModel
        if (newFilterModel && newFilterModel[filterKey]) {
            delete newFilterModel[filterKey]
            api?.setFilterModel(newFilterModel)
        }
    }

    return (
        <div style={{ padding: '8px' }}>
            <Button
                appearance='primary'
                onClick={clearAllFilters}
                disabled={filterCount <= 0}
            >
                Clear all filters
            </Button>
            {filterCount > 0 && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gridGap: '8px',
                        marginTop: '8px',
                    }}
                >
                    <Text block>
                        <strong>Reset filter for column:</strong>
                    </Text>
                    {Object.keys(filterModel).map(filterColumn => (
                        <div key={filterColumn}>
                            <CloseButton
                                title={`Remove filter for column ${filterColumn}`}
                                onClick={() =>
                                    removeFilterFromModel(filterColumn)
                                }
                            >{`${filterColumn}${
                                getFilterValues(filterColumn, filterModel) ?? ''
                            }`}</CloseButton>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default ActiveFiltersPanel
