import Content from 'components/Layout/Content'
import GalleryModal from 'components/MediaGallery/GalleryModal'
import GalleryTabs from 'components/MediaGallery/GalleryTabs'
import GalleryTimer from 'components/MediaGallery/GalleryTimer'
import Header from 'components/Layout/Header'
import { IMediaLink } from 'types/media'
import Loader from 'components/UI/Loader'
import Restricted from 'components/Restricted'
import { useGetReportsMediaQuery } from 'api/reportsMedia'
import { useParams } from 'react-router-dom'
import { useState } from 'react'

const Media = () => {
    const { feedbackId } = useParams()
    const {
        data: feedbackMedia,
        isError,
        isSuccess,
    } = useGetReportsMediaQuery(parseInt(feedbackId), {
        skip: isNaN(+feedbackId),
    })

    const [selectedMedia, setSelectedMedia] = useState<IMediaLink>()

    return (
        <>
            <Header title='Media Gallery' />

            <Content>
                <Restricted
                    to='media'
                    fallback={
                        <Loader
                            error
                            text='You do not have permission to access this page.'
                        />
                    }
                >
                    {isError && (
                        <Loader
                            error
                            text='Something went wrong, please try again later. If the issue persist please contact support'
                        />
                    )}
                    {isSuccess && (
                        <>
                            <GalleryTabs
                                feedbackMedia={feedbackMedia}
                                setSelectedMedia={media =>
                                    setSelectedMedia(media)
                                }
                            />
                        </>
                    )}
                    {selectedMedia && (
                        <GalleryModal
                            selectedMedia={selectedMedia}
                            toggleModal={() => setSelectedMedia(undefined)}
                        />
                    )}
                    <GalleryTimer />
                </Restricted>
            </Content>
        </>
    )
}

export default Media
