import { Dropdown, Option } from '@fluentui/react-components'
import { ForwardedRef, useImperativeHandle, useMemo, useState } from 'react'

import CloseButton from 'components/UI/CloseButon'
import { Feedback } from 'types/feedback'
import { ICellRendererParams } from 'ag-grid-community'

type IDropdownCellEditorProps<DataType> = {
    data: ICellRendererParams<Feedback>['data']
    filter: (item: DataType) => boolean
    forwardRef: ForwardedRef<unknown>
    dataKey: keyof Feedback
    noblank?: boolean
    options: DataType[]
    stopEditing: () => void
}

/**
 * Creates a dropdown cell editor with a close button it takes a filter function to filter the options
 *
 * @param prop { data, filter, forwardRef, key, options, placeholder, stopEditing }
 */
const DropdownCellEditor = <DataType,>({
    data,
    filter,
    forwardRef,
    dataKey,
    noblank,
    options,
    stopEditing,
}: IDropdownCellEditorProps<DataType>) => {
    // filter the options based on the filter function
    const filteredOptions: string[] = useMemo(
        () =>
            options.length > 0
                ? options
                      .filter(filter)
                      .map((a: DataType) => a['name'])
                      .filter(Boolean)
                      .sort((a, b) =>
                          a.toLowerCase().localeCompare(b.toLowerCase())
                      )
                : [],
        [filter, options]
    )

    const [selectedItem, setSelectedItem] = useState<string[]>([
        data[dataKey] as string,
    ])

    // expose the getValue, isCancelBeforeStart and isCancelAfterEnd method to the grid
    useImperativeHandle(forwardRef, () => ({
        getValue() {
            return selectedItem[0] ?? data[dataKey]
        },
        isCancelBeforeStart() {
            return false
        },
        isCancelAfterEnd() {
            return false
        },
    }))

    return (
        <>
            <Dropdown
                value={selectedItem[0]}
                selectedOptions={selectedItem}
                onOptionSelect={(_e, { selectedOptions }) => {
                    setSelectedItem(selectedOptions)
                    setTimeout(stopEditing, 0)
                }}
                open
                size='large'
                style={{
                    display: 'inline-flex',
                    maxWidth: noblank ? '100%' : 'calc(100% - 32px)',
                    width: '100%',
                    overflow: 'hidden',
                }}
            >
                {!noblank ? <Option value=''>{null}</Option> : null}
                {filteredOptions.map((option, index) => (
                    <Option key={index} value={option}>
                        {option}
                    </Option>
                ))}
            </Dropdown>
            {noblank ? null : <CloseButton onClick={stopEditing} />}
        </>
    )
}

export default DropdownCellEditor
