import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    Image,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'

import { IMediaLink } from 'types/media'

interface IGalleryModalProps {
    selectedMedia: IMediaLink
    toggleModal: () => void
}

const useStyles = makeStyles({
    dialogSurface: {
        height: '90vh',
        minWidth: '90vw',
        ...shorthands.overflow('auto'),
    },
    dialogContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
})

const GalleryModal = ({ selectedMedia, toggleModal }: IGalleryModalProps) => {
    const classes = useStyles()

    return (
        <Dialog open={!!selectedMedia} onOpenChange={toggleModal}>
            <DialogSurface className={classes.dialogSurface}>
                <DialogBody>
                    <DialogContent className={classes.dialogContent}>
                        {selectedMedia.sasUri.includes('/image') && (
                            <Image
                                src={selectedMedia.sasUri}
                                alt={selectedMedia.name}
                                onClick={toggleModal}
                                fit='contain'
                            />
                        )}
                        {selectedMedia.sasUri.includes('/video') && (
                            <video
                                src={selectedMedia.sasUri}
                                controls
                                width='100%'
                                height='100%'
                            />
                        )}
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default GalleryModal
