import { Radio, RadioGroup, Switch } from '@fluentui/react-components'
import {
    selectDarkMode,
    selectTextDisplaySetting,
    setTextDisplaySetting,
    toggleDarkMode,
} from 'slices/preferenceSlice'
import { useAppDispatch, useAppSelector } from 'store'

const displayOptions = [
    { value: 'hover', label: 'Hover' },
    { value: 'inline', label: 'Inline' },
]

const Preferences = () => {
    const dispatch = useAppDispatch()
    const darkMode = useAppSelector(selectDarkMode)
    const textDisplaySetting = useAppSelector(selectTextDisplaySetting)

    return (
        <>
            <h3>Dark Mode</h3>
            <Switch
                checked={darkMode}
                onChange={() => dispatch(toggleDarkMode())}
                label={darkMode ? 'On' : 'Off'}
            />

            <h3>Title/Description Display</h3>
            <RadioGroup
                defaultValue={textDisplaySetting}
                layout='horizontal'
                onChange={(_e, option) =>
                    dispatch(setTextDisplaySetting(option.value))
                }
            >
                {displayOptions.map(i => (
                    <Radio key={i.value} value={i.value} label={i.label} />
                ))}
            </RadioGroup>
        </>
    )
}

export default Preferences
