import './binManagementStyles.scss'

import { Checkbox, Field, makeStyles } from '@fluentui/react-components'
import {
    selectArchived,
    selectArtId,
    selectSolutionId,
    setActiveArtId,
    setActiveSolutionId,
    toggleArchiveState,
} from 'slices/categorySlice'
import { useAppDispatch, useAppSelector } from 'store'
import { useMemo, useRef, useState } from 'react'

import AgGridContainer from 'components/Layout/AgGridContainer'
import { AgGridReact } from 'ag-grid-react'
import AutoSelectDropdown from 'components/UI/AutoSelectDropdown'
import BinActions from './cellRenderers/BinActions'
import { ColDef } from 'ag-grid-community'
import { getContextMenuItems } from 'helpers/dataGridHelper'
import { useGrid } from 'contexts/GridContext'

interface IBinManagementProps {
    allSolutions: Solution[]
    allArts: Art[]
    allBins: Bin[]
}

const useStyle = makeStyles({
    wrapper: {
        display: 'flex',
        gridGap: '16px',
        alignItems: 'center',
        marginBottom: '16px',
    },
})

const BinManagement = ({
    allSolutions,
    allArts,
    allBins,
}: IBinManagementProps) => {
    const gridRef = useRef<AgGridReact>(null)
    const dispatch = useAppDispatch()

    const classes = useStyle()

    const { setGridRef } = useGrid()

    const selectedSolutionId = useAppSelector(selectSolutionId)
    const selectedArtId = useAppSelector(selectArtId)
    const showArchived = useAppSelector(selectArchived)

    const solutions = useMemo(
        () =>
            allSolutions
                .filter(s => !s.isArchived)
                .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                ),
        [allSolutions]
    )

    const arts = useMemo(
        () =>
            allArts
                .filter(
                    a => a.solutionId === selectedSolutionId && !a.isArchived
                )
                .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                ),
        [allArts, selectedSolutionId]
    )

    const bins = useMemo(
        () =>
            allBins.filter(
                a => a.artId == selectedArtId && a.isArchived === showArchived
            ),
        [allBins, selectedArtId, showArchived]
    )

    const customComparator = (a: string, b: string) => {
        return a.toLowerCase().localeCompare(b.toLowerCase())
    }

    const [columnDefs] = useState<ColDef[]>([
        {
            checkboxSelection: true,
            colId: 'checkBoxSelect',
            lockPinned: true,
            lockPosition: true,
            maxWidth: 55,
            pinned: 'left',
            resizable: false,
            sortable: false,
            suppressColumnsToolPanel: true,
            suppressMenu: true,
            suppressFiltersToolPanel: true,
        },
        { field: 'name', flex: 1, sort: 'asc', comparator: customComparator },
        {
            headerName: '',
            field: 'actions',
            width: 140,
            flex: 0,
            editable: false,
            cellRenderer: BinActions,
            cellRendererParams: {
                allBins,
                allArts,
            },
        },
    ])

    const handleSolutionChange = (option: Solution) => {
        dispatch(setActiveSolutionId(option?.id))
        dispatch(setActiveArtId(null))
    }

    const handleArtChange = (option: Art) => {
        dispatch(setActiveArtId(option?.id))
    }

    const handleGridReady = () => {
        setGridRef(gridRef)
    }

    return (
        <AgGridContainer offsetHeight={70}>
            <div className={classes.wrapper}>
                <div style={{ flexGrow: '2' }}>
                    <AutoSelectDropdown
                        title='Solution'
                        data={solutions}
                        selectedId={selectedSolutionId}
                        callback={handleSolutionChange}
                    />
                </div>

                <div style={{ flexGrow: '2' }}>
                    <AutoSelectDropdown
                        title='Art'
                        data={arts}
                        disabled={!selectedSolutionId}
                        selectedId={selectedArtId}
                        callback={handleArtChange}
                    />
                </div>

                <Field label='Show Archived' style={{ flexGrow: 1 }}>
                    <Checkbox
                        checked={showArchived}
                        onChange={() => dispatch(toggleArchiveState())}
                        disabled={!selectedArtId}
                    />
                </Field>
            </div>

            <AgGridReact
                enableRangeSelection
                ref={gridRef}
                rowSelection='multiple'
                className='binManagement'
                rowData={bins}
                columnDefs={columnDefs}
                getContextMenuItems={getContextMenuItems}
                onGridReady={handleGridReady}
            />
        </AgGridContainer>
    )
}

export default BinManagement
