import { Button, Spinner, Textarea } from '@fluentui/react-components'
import { useEffect, useRef, useState } from 'react'
import {
    usePostCloseConversationMutation,
    usePostMarkAsReadMutation,
    usePostNewConversationMutation,
    usePostReOpenConversationMutation,
    usePostSendMessageMutation,
} from 'api/messages'

import ConversationStatusEnum from 'constants/ConversationStatusEnum'
import { Feedback } from 'types/feedback'
import { IConversation } from 'types/conversations'
import MessageItem from './MessageItem'
import Restricted from 'components/Restricted'
import { toast } from 'react-toastify'
import { useMessageStyles } from '../styles/messages'

interface IConversationProps {
    vehicleStatus: string
    conversation: IConversation
    feedback: Feedback
    hasActiveConversation: boolean
    onNewConversation: () => void
}

const Conversation = ({
    vehicleStatus,
    conversation,
    feedback,
    hasActiveConversation,
    onNewConversation,
}: IConversationProps) => {
    const {
        historyBox,
        sendButton,
        actionButtonsWrapper,
        conversationWrapper,
    } = useMessageStyles()
    const [message, setMessage] = useState<string>('')
    const wrapperRef = useRef<HTMLDivElement>(null)

    const [sendMessage, { isLoading: isSendingMessage }] =
        usePostSendMessageMutation()
    const [newConversation, { isLoading: isNewConversation }] =
        usePostNewConversationMutation()
    const [closeConversation] = usePostCloseConversationMutation()
    const [reOpenConversation] = usePostReOpenConversationMutation()
    const [postMarkAsRead] = usePostMarkAsReadMutation()

    const isConversationEnded =
        conversation?.statusId === ConversationStatusEnum.Ended
    const isVehicleEnded = vehicleStatus === 'Ended'

    useEffect(() => {
        if (wrapperRef.current) {
            wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight
        }
    }, [conversation])

    const handleSendMessage = async () => {
        if (!message) {
            toast.warning('Please type a message!')
            return
        }

        if (!conversation) {
            await newConversation({
                formAnswerId: feedback.id,
                message,
                artId: feedback.artId,
            })
        } else {
            await sendMessage({ conversationId: conversation.id, message })
        }

        setMessage(null)
    }

    const handleMarkAsRead = () => {
        postMarkAsRead({
            conversationId: conversation.id,
        })
            .unwrap()
            .then(() => {
                toast.success(
                    'The conversation was marked as read Successfully'
                )
            })
            .catch(error => {
                toast.error(error.data ?? 'An error occurred')
            })
    }

    const handleCloseConversation = () => {
        closeConversation({
            conversationId: conversation.id,
            note: conversation.note,
        })
            .unwrap()
            .then(() => {
                toast.success('The conversation is closed Successfully')
            })
            .catch(error => {
                toast.error(error.data ?? 'An error occurred')
            })
    }

    const handleReOpenConversation = () => {
        reOpenConversation(conversation.id)
            .unwrap()
            .then(() => {
                toast.success('The conversation is reopened Successfully')
            })
            .catch(error => {
                toast.error(error.data ?? 'An error occurred')
            })
    }

    const handleNewConversation = () => {
        onNewConversation()
    }

    const isButtonLoading = isSendingMessage || isNewConversation

    const MarkConversationAsReadButton = () =>
        conversation.statusId === ConversationStatusEnum.RespondedByDriver && (
            <Button onClick={handleMarkAsRead}>
                Mark conversation as read
            </Button>
        )

    const CloseConversation = () =>
        conversation.statusId !== ConversationStatusEnum.Ended && (
            <Button onClick={handleCloseConversation}>
                Close conversation
            </Button>
        )

    const ReopenConversation = () =>
        !hasActiveConversation &&
        isConversationEnded && (
            <Button
                disabled={isVehicleEnded}
                onClick={handleReOpenConversation}
            >
                Reopen conversation
            </Button>
        )

    const StartNewConversation = () =>
        !hasActiveConversation && (
            <Button
                appearance='primary'
                onClick={handleNewConversation}
                disabled={isVehicleEnded}
            >
                Start new conversation
            </Button>
        )

    return (
        <div className={conversationWrapper}>
            {conversation && (
                <div className={historyBox} ref={wrapperRef}>
                    {conversation.codev_ReportConversationMessages?.map(
                        _message => (
                            <MessageItem message={_message} key={_message.id} />
                        )
                    )}
                </div>
            )}
            <Restricted to='manageTwoWayCom'>
                <Textarea
                    style={{ width: '100%', margin: '8px 0' }}
                    rows={3}
                    value={message ?? ''}
                    placeholder='Type your message here...'
                    onChange={(_e, { value }) => setMessage(value)}
                    disabled={isConversationEnded || isVehicleEnded}
                />

                <div className={sendButton}>
                    <Button
                        icon={isButtonLoading ? <Spinner size='tiny' /> : null}
                        appearance='primary'
                        disabledFocusable={isButtonLoading}
                        disabled={
                            !message || isConversationEnded || isVehicleEnded
                        }
                        onClick={handleSendMessage}
                    >
                        {isButtonLoading
                            ? 'Loading'
                            : conversation
                            ? 'Send'
                            : 'Start new conversation'}
                    </Button>
                </div>

                {conversation && (
                    <div className={actionButtonsWrapper}>
                        <MarkConversationAsReadButton />
                        <CloseConversation />
                        <ReopenConversation />
                        <StartNewConversation />
                    </div>
                )}
            </Restricted>
        </div>
    )
}

export default Conversation
