import {
    ChevronRight16Regular,
    Delete24Regular,
    Heart24Filled,
    Heart24Regular,
    Pen24Regular,
    Share24Regular,
} from '@fluentui/react-icons'
import {
    Menu,
    MenuItem,
    MenuItemRadio,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components'
import {
    selectDefaultViews,
    setDefaultPersonalView,
} from 'slices/preferenceSlice'
import { useAppDispatch, useAppSelector } from 'store'

import SaveDialog from './SaveDialog'
import { SerializedError } from '@reduxjs/toolkit'
import { triggerMessage } from 'slices/messageSlice'
import { useDeletePersonalViewsMutation } from 'api/personalViews'
import { useGrid } from 'contexts/GridContext'

interface IViewSubmenu {
    options: {
        id: number
        name: string
        viewJson: string
        action: () => void
        disabled: boolean
    }[]
    viewType: string
    reset: () => void
}

const ViewSubmenu = ({ reset, viewType, options }: IViewSubmenu) => {
    const defaultViews = useAppSelector(selectDefaultViews)
    const dispatch = useAppDispatch()

    const { gridRef } = useGrid()

    const [deletePersonalView] = useDeletePersonalViewsMutation()

    const handleRemove = async (id: number) => {
        const response = await deletePersonalView(id)
        if ('data' in response) {
            reset()
            dispatch(
                triggerMessage({
                    title: 'Personal View Removed',
                    message: 'The personal view has been removed successfully',
                    intent: 'success',
                })
            )
        } else {
            dispatch(
                triggerMessage({
                    title: 'Error',
                    message:
                        (response.error as SerializedError).message ??
                        'An error occurred',
                    intent: 'error',
                })
            )
        }
    }

    const handleShare = (id: number) => {
        navigator.clipboard.writeText(
            `${location.origin}${location.pathname}?view=${id}`
        )
        dispatch(
            triggerMessage({
                title: 'URL Copied',
                message: 'The URL is copied into the clipboard',
                intent: 'info',
            })
        )
    }

    const setDefaultViews = (id: number) => {
        dispatch(
            setDefaultPersonalView({
                path: location.pathname,
                id: defaultViews?.find(dv => dv.id === id) ? undefined : id,
            })
        )
    }

    const applyPartialView = (viewJson: string) => {
        const { columnState } = JSON.parse(viewJson)

        const currentColumnState = gridRef.current?.columnApi.getColumnState()
        if (currentColumnState !== columnState)
            gridRef.current?.columnApi.applyColumnState({
                state: columnState,
                applyOrder: true,
            })
    }

    return (
        <>
            {options
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(option => (
                    <Menu key={option.id} persistOnItemClick>
                        <MenuTrigger disableButtonEnhancement>
                            <MenuItemRadio
                                hasSubmenu
                                name='personalView'
                                value={option.name}
                                disabled={option.disabled}
                                onClick={option.action}
                                className='personal-view-submenu-item'
                            >
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: defaultViews?.find(
                                            dv => dv.id === option.id
                                        )
                                            ? 'bold'
                                            : 'normal',
                                    }}
                                >
                                    {option.name}
                                    {!option.disabled && (
                                        <ChevronRight16Regular
                                            style={{ marginLeft: 'auto' }}
                                        />
                                    )}
                                </span>
                            </MenuItemRadio>
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList data-cy='personal-views-submenu'>
                                <SaveDialog
                                    intent='update'
                                    onSuccessfulSave={option.action}
                                    viewType={viewType}
                                    viewId={option.id}
                                    viewName={option.name}
                                />
                                <MenuItem
                                    icon={<Pen24Regular />}
                                    onClick={() =>
                                        applyPartialView(option.viewJson)
                                    }
                                >
                                    Apply partial view
                                </MenuItem>
                                <MenuItem
                                    icon={<Share24Regular />}
                                    onClick={() => handleShare(option.id)}
                                >
                                    Share URL
                                </MenuItem>
                                <MenuItem
                                    icon={
                                        defaultViews?.find(
                                            dv => dv.id === option.id
                                        ) ? (
                                            <Heart24Filled />
                                        ) : (
                                            <Heart24Regular />
                                        )
                                    }
                                    onClick={() => setDefaultViews(option.id)}
                                >
                                    Set as default view
                                </MenuItem>
                                <MenuItem
                                    icon={<Delete24Regular />}
                                    onClick={() => handleRemove(option.id)}
                                >
                                    Remove view
                                </MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                ))}
        </>
    )
}

export default ViewSubmenu
