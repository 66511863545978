import {
    Button,
    DialogTrigger,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'

import ConversationStatusEnum from 'constants/ConversationStatusEnum'

interface ITwoWayComButtonProps {
    disabled?: boolean
    status: string
}

const useStyles = makeStyles({
    ongoing: {
        backgroundColor: 'powderblue',
        ...shorthands.borderColor('#a4cfd4'),
    },
    closed: {
        backgroundColor: 'powderblue',
        ...shorthands.borderColor('#a4cfd4'),
        color: '#828282',
    },
})

const TwoWayComButton = ({ disabled, status }: ITwoWayComButtonProps) => {
    const styles = useStyles()
    let buttonContent
    const label = '2-way'

    switch (status) {
        case ConversationStatusEnum.RespondedByTrillion:
        case ConversationStatusEnum.RespondedByDriver:
        case ConversationStatusEnum.ReadByDriver:
        case ConversationStatusEnum.ReadByTrillion:
        case ConversationStatusEnum.Created:
            buttonContent = <Button className={styles.ongoing}>{label}</Button>
            break
        case ConversationStatusEnum.Ended:
            buttonContent = <Button className={styles.closed}>{label}</Button>
            break
        default:
            buttonContent = (
                <Button
                    title={disabled ? 'Enabled for EU reports only' : null}
                    disabled={disabled}
                >
                    {label}
                </Button>
            )
            break
    }
    return <DialogTrigger>{buttonContent}</DialogTrigger>
}

export default TwoWayComButton
