import { makeStyles } from '@fluentui/react-components'

const useDialogStyles = makeStyles({
    dialogSurface: {
        width: '100%',
        maxWidth: '800px',
    },
    dialogBody: {
        minHeight: '650px',
    },
})

export default useDialogStyles
