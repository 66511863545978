import { Button, Text } from '@fluentui/react-components'

import { DeleteRegular } from '@fluentui/react-icons'
import DialogWithIconButton from 'components/UI/DialogWithIconButton'
import { GridApi } from 'ag-grid-community'
import { useDeleteBinMutation } from 'api/category'

interface IBinDeleteModalProps {
    data: Bin
    api: GridApi
}

const BinDeleteModal = ({ data, api }: IBinDeleteModalProps) => {
    const [deleteBin, { isLoading }] = useDeleteBinMutation()

    const handleClick = async (toggleOpen: () => void) => {
        await deleteBin(data.id)
        toggleOpen()
        api.refreshCells()
    }

    return (
        <DialogWithIconButton
            icon={<DeleteRegular />}
            dialogTitle='Delete this?'
            dialogContent={() => (
                <Text>
                    Are you sure you want to delete this Bin? This action cannot
                    be undone.
                </Text>
            )}
            actionRender={toggleOpen => (
                <Button
                    appearance='primary'
                    disabled={isLoading}
                    onClick={() => handleClick(toggleOpen)}
                >
                    Confirm
                </Button>
            )}
        />
    )
}

export default BinDeleteModal
