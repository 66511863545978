import {
    DocumentDatabase24Regular,
    Key24Regular,
    LightbulbFilament24Regular,
    PeopleCommunity24Regular,
    VehicleCar24Regular,
} from '@fluentui/react-icons'
import {
    MenuButton,
    MenuGroup,
    MenuGroupHeader,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Menu as RawMenu,
} from '@fluentui/react-components'
import { permissions, selectViews } from 'slices/authSlice'

import { Fragment } from 'react'
import ROUTES from 'constants/routes'
import { selectDarkMode } from 'slices/preferenceSlice'
import { useAppSelector } from 'store'
import { useNavigate } from 'react-router-dom'

interface IMenuProps {
    title: string
    icon: JSX.Element
}

const Menu = ({ title, icon }: IMenuProps) => {
    const navigate = useNavigate()
    const darkMode = useAppSelector(selectDarkMode)
    const views = useAppSelector(selectViews)

    const {
        managementBin: hasBinPermission,
        managementCauseRemark: hasCauseRemarkPermission,
        managementSubsystem: hasSubsystemPermission,
        praises: hasPraisesPermission,
    } = useAppSelector(permissions)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (ev?: React.MouseEvent<HTMLElement>, item?: any) => {
        ev?.preventDefault()
        navigate(item?.url || '/')
    }

    const navLinkGroups = [
        {
            name: 'Feedbacks',
            links: [
                {
                    name: 'Home',
                    url: ROUTES.home,
                    icon: <VehicleCar24Regular />,
                    onClick: handleClick,
                },
            ],
        },
        hasPraisesPermission && {
            name: 'Ideas And Praises',
            links: [
                {
                    name: 'Ideas And Praises',
                    url: ROUTES.praisesIdeas,
                    icon: <LightbulbFilament24Regular />,
                    onClick: handleClick,
                },
            ],
        },
        (hasBinPermission ||
            hasCauseRemarkPermission ||
            hasSubsystemPermission) && {
            name: 'Admin',
            links: [
                hasBinPermission && {
                    name: 'Bin Management',
                    url: ROUTES.binManagement,
                    icon: <DocumentDatabase24Regular />,
                    onClick: handleClick,
                },
                hasSubsystemPermission && {
                    name: 'Subsystem Management',
                    url: ROUTES.subsystemManagement,
                    icon: <DocumentDatabase24Regular />,
                    onClick: handleClick,
                },
                hasCauseRemarkPermission && {
                    name: 'Cause / Remark Management',
                    url: ROUTES.causeRemarkManagement,
                    icon: <DocumentDatabase24Regular />,
                    onClick: handleClick,
                },
            ],
        },
        {
            name: 'Settings',
            links: [
                {
                    name: 'Role Picker',
                    url: ROUTES.rolePicker,
                    icon: <Key24Regular />,
                    onClick: handleClick,
                },
                {
                    name: 'Members',
                    url: ROUTES.members,
                    icon: <PeopleCommunity24Regular />,
                    onClick: handleClick,
                },
            ],
        },
    ]

    return (
        <RawMenu>
            <MenuTrigger>
                <MenuButton
                    style={{
                        backgroundColor: '#013b4d',
                        width: '200px !important',
                        maxWidth: '300px',
                        display: 'flex',
                        height: '30px !important',
                        maxHeight: '50px',
                        border: 'none',
                        color: 'white',
                    }}
                    disabled={views.length === 1 && views[0] === 'Media'}
                    icon={icon}
                >
                    {title}
                </MenuButton>
            </MenuTrigger>
            <MenuPopover
                style={{
                    backgroundColor: darkMode ? '#000000' : '#FFFFFF',
                }}
            >
                <MenuList>
                    <MenuGroup>
                        {navLinkGroups.map(navGroup => (
                            <Fragment key={navGroup.name}>
                                <MenuGroupHeader>
                                    {navGroup.name}
                                </MenuGroupHeader>
                                {navGroup.links?.map(navLink => (
                                    <MenuItem
                                        key={navLink.name}
                                        icon={navLink.icon}
                                        onClick={() => navigate(navLink.url)}
                                        style={{
                                            backgroundColor: darkMode
                                                ? '#000000'
                                                : '#FFFFFF',
                                            color: darkMode
                                                ? '#FFFFFF'
                                                : '#000000',
                                        }}
                                    >
                                        {navLink.name}
                                    </MenuItem>
                                ))}
                            </Fragment>
                        ))}
                    </MenuGroup>
                </MenuList>
            </MenuPopover>
        </RawMenu>
    )
}

export default Menu
