enum Permissions {
    managementBin = 'Trillion.Functions.Bin.Manage',
    managementSubsystem = 'Trillion.Functions.Subsystem.Manage',
    managementCauseRemark = 'Trillion.Functions.Causeremarks.Manage',
    readTwoWayCom = 'Trillion.Functions.TwoWayCom.Read',
    manageTwoWayCom = 'Trillion.Functions.TwoWayCom.Manage',
    inlineEdit = 'Trillion.Functions.Inlineediting',
    batchEdit = 'Trillion.Functions.BatchEditing',
    noShow = 'Trillion.Functions.NoShow',
    media = 'Trillion.Views.Media',
    praises = 'Trillion.Views.IdeasPraises',
}

export default Permissions
