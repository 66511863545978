import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Text,
} from '@fluentui/react-components'
import { useEffect, useRef, useState } from 'react'

const GalleryTimer = () => {
    const [timeSinceLastFetch, setTimeSinceLastFetch] = useState(0)
    const time = useRef(new Date())
    const [isTimeout, setTimeout] = useState(false)

    useEffect(() => {
        const startTime = time.current.valueOf()
        const timer = setInterval(() => {
            setTimeSinceLastFetch(
                Math.floor((new Date().valueOf() - startTime) / 60000)
            )
        }, 60000)

        return () => clearInterval(timer)
    }, [])

    useEffect(() => {
        if (timeSinceLastFetch >= 20 && !isTimeout) {
            setTimeout(true)
        }
    }, [isTimeout, timeSinceLastFetch])

    return (
        <>
            <Text
                style={{
                    margin: 'auto 0 16px 16px',
                    paddingTop: 16,
                    color: timeSinceLastFetch >= 15 ? 'red' : 'black',
                }}
            >
                {`${timeSinceLastFetch} minutes since last refresh.`}
            </Text>
            <Dialog
                modalType='alert'
                open={isTimeout}
                onOpenChange={(_e, { open }) => setTimeout(open)}
            >
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Data Timeout</DialogTitle>
                        <DialogContent>
                            You have been inactive for 20 minutes. Would you
                            like to refresh the data?
                        </DialogContent>
                        <DialogActions>
                            <Button
                                appearance='primary'
                                onClick={() => {
                                    window.location.reload()
                                }}
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    )
}

export default GalleryTimer
