export default {
    home: '/',
    media: '/media',
    binManagement: '/bins',
    rolePicker: '/rolepicker',
    subsystemManagement: '/subsystems',
    causeRemarkManagement: '/causeremarks',
    praisesIdeas: '/praises',
    members: '/members',
}
