import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
} from '@fluentui/react-components'
import { useMemo, useState } from 'react'

import AutoSelectInput from 'components/UI/AutoSelectInput'
import { selectArtId } from 'slices/categorySlice'
import { useAppSelector } from 'store'
import { usePostBinMutation } from 'api/category'

interface IProps {
    isOpen?: boolean
    toggleModal: () => void
    allBins: Bin[]
}

const AddModal = ({ isOpen, toggleModal, allBins }: IProps) => {
    const [inputValue, setInputValue] = useState('')

    const selectedArtId = useAppSelector(selectArtId)

    const [postBin] = usePostBinMutation()

    const bins = useMemo(
        () => allBins.filter(bin => bin.artId === selectedArtId),
        [allBins, selectedArtId]
    )

    const isDuplicate = useMemo(() => {
        if (inputValue === '') {
            return false
        }
        return bins.map(({ name }) => name).includes(inputValue)
    }, [bins, inputValue])

    const handleClick = async (toggleOpen: () => void) => {
        const response = await postBin({
            name: inputValue,
            artId: selectedArtId,
            isArchived: false,
        })

        if ('data' in response) {
            setInputValue('')
            toggleOpen()
        }
    }

    const cannotAdd = !selectedArtId || !inputValue || isDuplicate

    return (
        <Dialog open={isOpen} onOpenChange={toggleModal}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Add Bin</DialogTitle>
                    <DialogContent>
                        <AutoSelectInput
                            title='Bin'
                            inputValue={inputValue}
                            callback={name => setInputValue(name)}
                            validationMessage={
                                isDuplicate ? 'Bin already exists' : null
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance='primary'
                            disabled={cannotAdd}
                            onClick={() => handleClick(toggleModal)}
                        >
                            Save
                        </Button>
                        <Button onClick={toggleModal}>Cancel</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default AddModal
