import { apiSlice } from './apiSlice'

export const vehicleTestsApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getVehicleTests: builder.query<IVehicleTest[], void>({
            query: () => 'api/VehicleTests',
        }),
    }),
})

export const { useGetVehicleTestsQuery } = vehicleTestsApi
