import {
    ArrowExport24Regular,
    DocumentOnePage24Filled,
    DocumentOnePage24Regular,
    Pen24Regular,
} from '@fluentui/react-icons'
import {
    Dialog,
    DialogBody,
    DialogSurface,
    DialogTitle,
} from '@fluentui/react-components'

import ActionsMenu from 'components/Layout/ActionsMenu'
import BatchEditModal from './BatchEditModal'
import ExportReportModal from './ExportReport/ExportReportModal'
import { IActionsMenu } from 'types/ActionsMenu'
import { useGrid } from 'contexts/GridContext'
import { useState } from 'react'

export interface IExportReportAndSelected {
    exportReport: boolean
    exportSelected: boolean
}

const ActionsPanel = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(null)

    const openDialog = (body: JSX.Element, title: string) => {
        setIsDialogOpen(true)
        setDialogContent({ body, title })
    }

    const [isBatchEditModalOpen, setIsBatchEditModalOpen] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])

    const { gridRef } = useGrid()
    gridRef?.current?.api.addEventListener('selectionChanged', () => {
        setSelectedRows(gridRef?.current?.api.getSelectedRows())
    })

    const items: IActionsMenu[] = [
        {
            label: 'Batch Edit',
            icon: <Pen24Regular />,
            onClick: () => setIsBatchEditModalOpen(true),
            disabled: selectedRows?.length === 0,
            restricted: 'batchEdit',
        },
        {
            label: 'Export to Excel',
            icon: <ArrowExport24Regular />,
            children: [
                {
                    label: 'Export filtered feedbacks',
                    icon: <DocumentOnePage24Regular />,
                    onClick: () =>
                        openDialog(
                            <ExportReportModal
                                toggleOpen={() => setIsDialogOpen(false)}
                            />,
                            'Export Report'
                        ),
                },
                {
                    label: 'Export selected feedbacks',
                    icon: <DocumentOnePage24Filled />,
                    disabled: selectedRows?.length === 0,
                    onClick: () =>
                        openDialog(
                            <ExportReportModal
                                exportSelected
                                toggleOpen={() => setIsDialogOpen(false)}
                            />,
                            'Export Selected Report'
                        ),
                },
            ],
        },
    ]

    return (
        <>
            <ActionsMenu items={items} />

            <Dialog
                open={isDialogOpen}
                onOpenChange={(_event, data) => setIsDialogOpen(data.open)}
            >
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>{dialogContent?.title}</DialogTitle>
                        {dialogContent?.body}
                    </DialogBody>
                </DialogSurface>
            </Dialog>

            <BatchEditModal
                isOpen={isBatchEditModalOpen}
                hideBatchEditModal={() => setIsBatchEditModalOpen(false)}
                selectedRows={selectedRows}
            />
        </>
    )
}

export default ActionsPanel
