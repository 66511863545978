import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { apiSlice } from 'api/apiSlice'
import authReducer from 'slices/authSlice'
import categoryReducer from 'slices/categorySlice'
import gridReducer from 'slices/gridSlice'
import messageReducer from 'slices/messageSlice'
import preferenceReducer from 'slices/preferenceSlice'

const rootReducer = combineReducers({
    auth: authReducer,
    category: categoryReducer,
    preference: preferenceReducer,
    grid: gridReducer,
    message: messageReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
