import { Button, Input } from '@fluentui/react-components'

import formatISO from 'date-fns/formatISO'
import lastDayOfMonth from 'date-fns/lastDayOfMonth'
import previousSunday from 'date-fns/previousSunday'
import startOfMonth from 'date-fns/startOfMonth'
import sub from 'date-fns/sub'
import { useGrid } from 'contexts/GridContext'
import { useState } from 'react'

const FilterButtons = () => {
    const { gridRef } = useGrid()
    const [number, setNumber] = useState(30)

    const handleWeekFilter = () => {
        const date = new Date()
        date.setHours(0, 0, 0, 0)
        const lastSunday = previousSunday(date)
        const firstMonday = sub(lastSunday, { days: 6 })

        const filterModel = gridRef.current.api.getFilterModel()
        gridRef.current.api.setFilterModel({
            ...filterModel,
            dateSubmitted: {
                filterType: 'date',
                type: 'inRange',
                dateFrom: formatISO(firstMonday),
                dateTo: formatISO(lastSunday),
            },
        })
    }

    const handleCustomFilter = () => {
        const date = new Date()
        date.setDate(date.getDate() - (number + 1))

        const filterModel = gridRef.current.api.getFilterModel()
        gridRef.current.api.setFilterModel({
            ...filterModel,
            dateSubmitted: {
                filterType: 'date',
                type: 'greaterThan',
                dateFrom: formatISO(date),
            },
        })
    }

    const handleMonthFilter = () => {
        const date = new Date()
        date.setHours(0, 0, 0, 0)
        const firstDayInMonth = startOfMonth(sub(date, { months: 1 }))
        const lastDayInMonth = lastDayOfMonth(firstDayInMonth)

        const filterModel = gridRef.current.api.getFilterModel()
        gridRef.current.api.setFilterModel({
            ...filterModel,
            dateSubmitted: {
                filterType: 'date',
                type: 'inRange',
                dateFrom: formatISO(firstDayInMonth),
                dateTo: formatISO(lastDayInMonth),
            },
        })
    }

    return (
        <div style={{ display: 'flex', gap: '4px' }}>
            <Button onClick={handleWeekFilter}>Last Week</Button>
            <Input
                type='number'
                value={String(number)}
                onChange={(_e, { value }) => setNumber(Number(value))}
                style={{
                    width: 60,
                }}
            />
            <Button onClick={handleCustomFilter}>X Days</Button>
            <Button onClick={handleMonthFilter}>Last Month</Button>
        </div>
    )
}

export default FilterButtons
