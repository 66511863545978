import { IS_DEBUG_ON } from 'constants/common'

/**
 * Function that will error something to the console if IS_DEBUG_ON is true
 * @param postData data
 */

const consoleErrorData = (postData: unknown) => {
    if (IS_DEBUG_ON) {
        // eslint-disable-next-line no-console
        console.error(postData)
    }
}

export default consoleErrorData
