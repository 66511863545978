import ActionsPanel from 'components/CauseRemarkManagement/actionPanels/ActionsPanel'
import CauseRemarkManagement from 'components/CauseRemarkManagement/CauseRemarkManagement'
import Content from 'components/Layout/Content'
import { DocumentDatabase24Regular } from '@fluentui/react-icons'
import Header from 'components/Layout/Header'
import Loader from 'components/UI/Loader'
import { useGetCauseRemarksQuery } from 'api/category'

const CauseRemarks = () => {
    const { data: causeRemarks, isLoading } = useGetCauseRemarksQuery()

    if (isLoading) {
        return <Loader text='Loading...' />
    }

    return (
        <>
            <Header
                title='Cause / Remark Management'
                icon={<DocumentDatabase24Regular />}
            />
            <ActionsPanel causeRemarks={causeRemarks} />

            <Content>
                <CauseRemarkManagement causeRemarks={causeRemarks} />
            </Content>
        </>
    )
}

export default CauseRemarks
