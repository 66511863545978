import { Spinner, Text } from '@fluentui/react-components'

import { WarningRegular } from '@fluentui/react-icons'

interface IAuthLoading {
    text?: string
    error?: boolean
}

const Loader = ({ text = '', error = false }: IAuthLoading) => {
    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
            }}
        >
            {error ? (
                <>
                    <WarningRegular
                        style={{
                            color: 'red',
                            marginBottom: '64px',
                        }}
                    />
                    <Text size={500}>
                        {text ||
                            'There was an error loading the page. Please try again.'}
                    </Text>
                </>
            ) : (
                <Spinner label={text} />
            )}
        </div>
    )
}

export default Loader
