import { ISchema } from 'types/schema'
import { apiSlice } from './apiSlice'
import { lowerCaseKeys } from 'helpers/objectManipulation'

const schemaApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAmericasSchema: builder.query<ISchema[], void>({
            query: () => 'api/AmericaView/schema',
            transformResponse: (response: ISchema) =>
                lowerCaseKeys(response) as unknown as ISchema[],
        }),
        getChinaSchema: builder.query<ISchema[], void>({
            query: () => 'api/ChinaView/schema',
            transformResponse: (response: ISchema) =>
                lowerCaseKeys(response) as unknown as ISchema[],
        }),
        getCMQSummarySchemas: builder.query<ISchema[], void>({
            query: () => 'api/CMQSummaryViews/schema',
            transformResponse: (response: ISchema) =>
                lowerCaseKeys(response) as unknown as ISchema[],
        }),
        getFeedbackSchema: builder.query<ISchema[], void>({
            query: () => 'api/Feedback/schema',
            transformResponse: (response: ISchema) =>
                lowerCaseKeys(response) as unknown as ISchema[],
        }),
    }),
})

export const {
    useGetAmericasSchemaQuery,
    useGetChinaSchemaQuery,
    useGetCMQSummarySchemasQuery,
    useGetFeedbackSchemaQuery,
} = schemaApi
