import { Link, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { permissions, selectUser } from 'slices/authSlice'

import Bins from 'pages/Bins'
import CauseRemarks from 'pages/CauseRemarks'
import Home from 'pages/Home'
import Layout from 'pages/Layout'
import Media from 'pages/Media'
import NoMatch from 'pages/NoMatch'
import NoViews from 'pages/NoViews'
import Praises from 'pages/Praises'
import RolePicker from 'pages/RolePicker'
import Subsystem from 'pages/Subsystems'
import routes from 'constants/routes'
import { useAppSelector } from 'store'
import Members from 'pages/Members'

const Router = () => {
    const user = useAppSelector(selectUser)
    const permissionsCollection = useAppSelector(permissions)

    const router = createBrowserRouter([
        {
            path: '/',
            element: user?.view?.length > 0 ? <Layout /> : <NoViews />,
            handle: {
                crumb: <Link to={routes.home}>Home</Link>,
            },
            children: [
                {
                    index: true,
                    element: <Home />,
                },
                {
                    path: routes.rolePicker,
                    element: <RolePicker />,
                    handle: {
                        crumb: <Link to={routes.rolePicker}>Role Picker</Link>,
                    },
                },
                permissionsCollection.managementBin && {
                    path: routes.binManagement,
                    element: <Bins />,
                    handle: {
                        crumb: (
                            <Link to={routes.binManagement}>
                                Bin Management
                            </Link>
                        ),
                    },
                },
                permissionsCollection.managementSubsystem && {
                    path: routes.subsystemManagement,
                    element: <Subsystem />,
                    handle: {
                        crumb: (
                            <Link to={routes.subsystemManagement}>
                                Subsystem Management
                            </Link>
                        ),
                    },
                },
                permissionsCollection.managementCauseRemark && {
                    path: routes.causeRemarkManagement,
                    element: <CauseRemarks />,
                    handle: {
                        crumb: (
                            <Link to={routes.causeRemarkManagement}>
                                Cause / Remark Management
                            </Link>
                        ),
                    },
                },
                permissionsCollection.media && {
                    path: `${routes.media}/:feedbackId?`,
                    element: <Media />,
                    handle: {
                        crumb: <Link to={routes.media}>Feedback Media</Link>,
                    },
                },
                permissionsCollection.praises && {
                    path: routes.praisesIdeas,
                    element: <Praises />,
                    handle: {
                        crumb: (
                            <Link to={routes.praisesIdeas}>
                                Ideas And Praises
                            </Link>
                        ),
                    },
                },
                {
                    path: routes.members,
                    element: <Members />,
                },
                {
                    path: '*',
                    element: <NoMatch />,
                },
            ],
        },
    ])

    return <RouterProvider router={router} />
}

export default Router
