import { toast } from 'react-toastify'
import { sanitizeInput } from 'helpers/sanitizers'

import AxiosErrorEnum from '../../constants/AxiosErrorEnum'

const HttpRequestHelper = {
    GetGlobalErrorMessage: (
        errorNumber: number | undefined = undefined,
        url: string | undefined = undefined,
        message: string | undefined = undefined
    ) => {
        const sanitizedErrorNumber = sanitizeInput(errorNumber)
        const sanitizedUrl = sanitizeInput(url)
        const santizedMessage = sanitizeInput(message)

        switch (errorNumber) {
            case AxiosErrorEnum.NotAuthorized:
                toast.error(sanitizedErrorNumber)
                break
            case AxiosErrorEnum.Forbidden:
                toast.error(`
                    ${sanitizedErrorNumber}
                    Your user role does not allow usage of this function.
                    The server responded that your connection is forbidden.
                    Please contact technical support.
                `)
                break
            case AxiosErrorEnum.NotFound:
                toast.error(`
                    ${sanitizedErrorNumber}
                    The requested endpoint
                    ${sanitizedUrl ? `(${sanitizedUrl}) ` : ''}
                    was not found. Please contact technical support.
                `)
                break
            case AxiosErrorEnum.UnknownBackendError:
                toast.error(`
                    ${sanitizedErrorNumber}
                    An unknown error occured in the backend while processing your request.
                    Please contact technical support.
                `)
                break
            case AxiosErrorEnum.BadRequest:
                toast.error(`
                    ${sanitizedErrorNumber}
                    ${santizedMessage}
                `)
                break
            default:
                toast.error(`
                    ${sanitizedErrorNumber ? `(${sanitizedErrorNumber}) ` : ''}
                    An unknown error occured while processing your request.
                    Please contact technical support.
                `)
        }
    },
}

export default HttpRequestHelper
