import {
    Button,
    DialogActions,
    DialogContent,
    Field,
    Input,
    Spinner,
} from '@fluentui/react-components'
import { selectLastOdataQuery, selectRowCount } from 'slices/gridSlice'

import AxiosStateTypeEnum from 'constants/axiosStateEnum'
import { IFeedback } from 'types/feedback'
import SuccessBox from './SuccessBox'
import { fetchOdataAPI } from 'api/genericFunctions'
import sanitize from 'sanitize-filename'
import { selectUser } from 'slices/authSlice'
import { toast } from 'react-toastify'
import { useAppSelector } from 'store'
import { useGrid } from 'contexts/GridContext'
import { useState } from 'react'

const EXPORT_LIMIT = 1000
const COLUMN_EXCEPTION = ['checkBoxSelect', 'messageBox', 'ihuLogsLink']

interface IExportReportModalProps {
    toggleOpen: () => void
    exportSelected?: boolean
}

const ExportReportModal = ({
    toggleOpen,
    exportSelected,
}: IExportReportModalProps) => {
    const [filename, setFilename] = useState<string>()
    const [csvDataState, setCsvDataState] = useState<AxiosStateTypeEnum>(
        AxiosStateTypeEnum.Unknown
    )
    const [csvData, setCsvData] = useState([])
    const [isCollectingData, setIsCollectingData] = useState(false)

    const { gridRef } = useGrid()
    const selectedRows = gridRef?.current?.api.getSelectedRows() || []

    const lastODataQuery = useAppSelector(selectLastOdataQuery)
    const rowCount = useAppSelector(selectRowCount)
    const user = useAppSelector(selectUser)

    const gridState = {
        column: gridRef.current.columnApi.getColumnState(),
    }

    const toPascalCase = (str: string) =>
        `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`

    const formatRow = (d: unknown) =>
        gridState.column
            .filter(col => !col.hide && !COLUMN_EXCEPTION.includes(col.colId))
            .reduce((acc, col) => {
                if (typeof d[col.colId] === 'string') {
                    // eslint-disable-next-line quotes
                    let value = d[col.colId].replaceAll('"', "'")
                    const firstChar = value.charAt(0)
                    if (
                        firstChar === '=' ||
                        firstChar === '+' ||
                        firstChar === '-'
                    ) {
                        value = `'${value}` // prevents formula to be evaluated
                    }
                    acc[col.colId] = value
                } else if (typeof d[col.colId] === 'number') {
                    acc[col.colId] = d[col.colId]
                }
                return acc
            }, {})

    const collectSelectedFeedbacks = async () => {
        let response
        const nextQuery =
            `?$filter=Id in (${selectedRows.map(({ id }) => id).join(',')})` +
            '&$select=' +
            gridState.column
                .filter(
                    col => !col.hide && !COLUMN_EXCEPTION.includes(col.colId)
                )
                .map(col => toPascalCase(col.colId))
                .join(',')
        if (user.view === 'CoDev') {
            response = await fetchOdataAPI<IFeedback>(
                'odata/Feedback',
                nextQuery
            )
        } else if (user.view === 'CMQ') {
            response = await fetchOdataAPI<IFeedback>(
                'odata/CMQSummaryViews',
                nextQuery
            )
        } else if (user.view === 'America') {
            response = await fetchOdataAPI<IFeedback>(
                'odata/AmericaView',
                nextQuery
            )
        } else if (user.view === 'China') {
            response = await fetchOdataAPI<IFeedback>(
                'odata/ChinaView',
                nextQuery
            )
        }

        const feedbackList = response.data.map((d: unknown) => formatRow(d))
        setCsvDataState(AxiosStateTypeEnum.Success)
        setCsvData(feedbackList)
        setIsCollectingData(false)
    }

    const collectAllFeedbacks = async () => {
        let response
        let nextQuery =
            lastODataQuery.replace('$top=100', `$top=${EXPORT_LIMIT}`) +
            '&$select=' +
            gridState.column
                .filter(
                    col => !col.hide && !COLUMN_EXCEPTION.includes(col.colId)
                )
                .map(col => toPascalCase(col.colId))
                .join(',')

        // The skip param is used by AGGrid for paging the view
        // when doing export we want all data, disregaring the skip param
        // will query for all data and not just the paged data
        if (nextQuery.includes('$skip')) {
            const params = new URLSearchParams(nextQuery.split('?')[1])
            params.delete('$skip')
            nextQuery = `${nextQuery.split('?')[0]}?${params.toString()}`
        }
        if (user.view === 'CoDev') {
            response = await fetchOdataAPI<IFeedback>(
                'odata/Feedback',
                nextQuery
            )
        } else if (user.view === 'CMQ') {
            response = await fetchOdataAPI<IFeedback>(
                'odata/CMQSummaryViews',
                nextQuery
            )
        }

        const feedbackList = response.data.map((d: unknown) => formatRow(d))
        setCsvDataState(AxiosStateTypeEnum.Success)
        setCsvData(feedbackList)
        setIsCollectingData(false)
    }

    const onDownloadReport = () => {
        if (exportSelected) {
            if (selectedRows.length === 0) {
                toast.error('Please select at least one row inside the table')
                toggleOpen()
            } else {
                setIsCollectingData(true)
                setCsvDataState(AxiosStateTypeEnum.Unknown)
                collectSelectedFeedbacks()
            }
        } else {
            if (
                gridState.column.filter(
                    col => !col.hide && col.colId !== 'checkBoxSelect'
                ).length === 0
            ) {
                toast.error(
                    'Please select at least one column inside the table'
                )
                toggleOpen()
            } else {
                setIsCollectingData(true)
                setCsvDataState(AxiosStateTypeEnum.Unknown)
                collectAllFeedbacks()
            }
        }
    }

    const selectionTooLarge =
        (exportSelected && selectedRows?.length >= EXPORT_LIMIT) ||
        (!exportSelected && rowCount >= EXPORT_LIMIT)

    return (
        <>
            <DialogContent>
                <Field
                    label='Filename'
                    validationMessage={
                        selectionTooLarge
                            ? `The selection is too large, please limit your selection to ${EXPORT_LIMIT}`
                            : null
                    }
                    hint={
                        exportSelected
                            ? `Export ${selectedRows.length} selected feedbacks`
                            : `Export ${rowCount} filtered feedbacks`
                    }
                >
                    <Input
                        placeholder='Filename'
                        value={filename ?? ''}
                        onChange={(_e, { value }) => {
                            setFilename(sanitize(value))
                        }}
                    />
                </Field>

                {csvDataState === AxiosStateTypeEnum.Success &&
                    csvData?.length && (
                        <SuccessBox csvData={csvData} fileName={filename} />
                    )}
            </DialogContent>

            <DialogActions>
                <Button disabled={isCollectingData} onClick={toggleOpen}>
                    Close
                </Button>
                <Button
                    appearance='primary'
                    disabled={
                        isCollectingData ||
                        selectionTooLarge ||
                        !filename ||
                        csvData?.length > 0
                    }
                    onClick={onDownloadReport}
                >
                    {isCollectingData ? (
                        <Spinner label='loading...' size='small' />
                    ) : (
                        'Generate report'
                    )}
                </Button>
            </DialogActions>
        </>
    )
}

export default ExportReportModal
