import { ColDef, ICellRendererParams, SideBarDef } from 'ag-grid-community'
import {
    EDITABLE_COLUMNS_CMQ,
    EDITABLE_COLUMNS_CODEV,
    HIDDEN_COLUMNS,
    NONE_BLANK_COLUMNS,
} from 'constants/gridColumns'
import { permissions, selectUser } from 'slices/authSlice'
import {
    selectDarkMode,
    selectTextDisplaySetting,
} from 'slices/preferenceSlice'
import {
    useGetAmericasSchemaQuery,
    useGetCMQSummarySchemasQuery,
    useGetChinaSchemaQuery,
    useGetFeedbackSchemaQuery,
} from 'api/schemas'

import ActiveFiltersPanel from 'components/Feedbacks/toolPanels/ActiveFiltersPanel'
import ArtCellEditor from 'components/Feedbacks/cellEditors/ArtCellEditor'
import ArtFilter from 'components/Feedbacks/filters/ArtFilter'
import { BLANK_FILTER } from 'constants/common'
import BinCellEditor from 'components/Feedbacks/cellEditors/BinCellEditor'
import BinFilter from 'components/Feedbacks/filters/BinFilter'
import CauseRemarkCellEditor from 'components/Feedbacks/cellEditors/CauseRemarkCellEditor'
import CommaSeparatedTextFilter from 'components/Feedbacks/filters/CommaSeparatedTextFilter'
import { Feedback } from 'types/feedback'
import { ISchema } from 'types/schema'
import IsModifiedWrapperRenderer from 'components/Feedbacks/cellRenderers/IsModifiedWrapperRenderer'
import MediaModal from 'components/Feedbacks/cellRenderers/MediaModal'
import MessageModal from 'components/Feedbacks/cellRenderers/MessageModal/MessageModal'
import SolutionCellEditor from 'components/Feedbacks/cellEditors/SolutionCellEditor'
import StructureWeekFilter from 'components/Feedbacks/filters/StructureWeekFilter'
import SubsystemCellEditor from 'components/Feedbacks/cellEditors/SubsystemCellEditor'
import SubsystemFilter from 'components/Feedbacks/filters/SubsystemFilter'
import ValueRangeFilter from 'components/Feedbacks/filters/ValueRangeFilter'
import VehicleTestsTooltip from 'components/Feedbacks/tooltipComponents/VehicleTestsTooltip'
import ViewsEnum from 'constants/views'
import { useAppSelector } from 'store'
import { useGetCauseRemarksQuery } from 'api/category'
import { useGetConversationStatusesQuery } from 'api/messages'
import { useMemo } from 'react'

const useFeedbackGrid = () => {
    const { data: conversationStatuses = [] } =
        useGetConversationStatusesQuery()
    const { data: causeRemarks = [] } = useGetCauseRemarksQuery()

    const hasNoShowPermission = useAppSelector(permissions).noShow
    const hasInlinePermission = useAppSelector(permissions).inlineEdit
    const hasTwoWayComPermission = useAppSelector(permissions).readTwoWayCom
    const user = useAppSelector(selectUser)
    const darkMode = useAppSelector(selectDarkMode)
    const textDisplaySetting = useAppSelector(selectTextDisplaySetting)

    const { data: americasSchema } = useGetAmericasSchemaQuery(null, {
        skip: user.view !== ViewsEnum.america,
    })
    const { data: chinaSchema } = useGetChinaSchemaQuery(null, {
        skip: user.view !== ViewsEnum.china,
    })
    const { data: cmqSchema } = useGetCMQSummarySchemasQuery(null, {
        skip: user.view !== ViewsEnum.cmq,
    })
    const { data: feedbackSchema } = useGetFeedbackSchemaQuery(null, {
        skip: user.view !== ViewsEnum.codev,
    })

    const schemas = useMemo(() => {
        const toCamelCase = (str = '') =>
            `${str.substring(0, 1).toLowerCase()}${str.substring(1)}`

        const formatSchema = (schema: ISchema[]) =>
            schema?.map((s: ISchema) => ({
                ...s,
                colId: toCamelCase(s.colId) as keyof Feedback,
                setFilterValues: s.setFilterValues
                    ? Object.values(s.setFilterValues)
                    : null,
            }))

        switch (user.view) {
            case ViewsEnum.america:
                return formatSchema(americasSchema) ?? []
            case ViewsEnum.china:
                return formatSchema(chinaSchema) ?? []
            case ViewsEnum.cmq:
                return formatSchema(cmqSchema) ?? []
            case ViewsEnum.codev:
                return formatSchema(feedbackSchema) ?? []
            default:
                return []
        }
    }, [user.view, americasSchema, chinaSchema, cmqSchema, feedbackSchema])

    const defaultColDef: ColDef = useMemo(
        () => ({
            cellEditor: 'agTextCellEditor',
            cellEditorPopup: false,
            editable: false,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
                defaultToNothingSelected: true,
            },
            resizable: true,
            sortable: true,
            headerClass: params => {
                return params.column.isFilterActive()
                    ? darkMode
                        ? 'ag-header-cell-filtered--dark'
                        : 'ag-header-cell-filtered--light'
                    : ''
            },
        }),
        [darkMode]
    )

    const columnDefs: ColDef[] = useMemo(() => {
        const rows = schemas
            .filter(
                s =>
                    !HIDDEN_COLUMNS.includes(s.colId) &&
                    !(s.colId === 'noShow' && !hasNoShowPermission)
            )
            .map(schema => {
                const columnDef: ColDef = {
                    field: schema.colId,
                    wrapText: textDisplaySetting === 'inline',
                    autoHeight: textDisplaySetting === 'inline',
                    tooltipField:
                        textDisplaySetting !== 'inline' ? schema.colId : null,
                }

                let editable = []
                switch (user.view) {
                    case 'CoDev':
                        editable = hasInlinePermission
                            ? EDITABLE_COLUMNS_CODEV
                            : []
                        break
                    case 'CMQ':
                        editable = hasInlinePermission
                            ? EDITABLE_COLUMNS_CMQ
                            : []
                        break
                    default:
                        editable = []
                }
                columnDef.editable = editable?.includes(schema.colId)

                if (schema.columnType === 'date') {
                    columnDef.filter = 'agDateColumnFilter'
                }

                const prependBlanks = (arr: string[] = []) => {
                    if (!Array.isArray(arr)) return [BLANK_FILTER]

                    const result = [...arr]
                    result.unshift(BLANK_FILTER)
                    return result
                }

                if (
                    schema.columnType === 'setfilter' &&
                    schema.setFilterValues
                ) {
                    columnDef.filter = 'agSetColumnFilter'
                    columnDef.filterParams = {
                        values: NONE_BLANK_COLUMNS.includes(schema.colId)
                            ? schema.setFilterValues
                            : prependBlanks(schema.setFilterValues),
                    }
                    columnDef.cellEditor = 'agSelectCellEditor'
                    columnDef.cellEditorParams = {
                        values: schema.setFilterValues,
                    }
                }

                if (schema.columnType === 'number') {
                    columnDef.filter = 'agNumberColumnFilter'
                }

                switch (schema.colId) {
                    case 'id':
                        columnDef.cellRenderer = IsModifiedWrapperRenderer
                        columnDef.filter = ValueRangeFilter
                        columnDef.width = 100
                        break

                    case 'isModified':
                        columnDef.cellRenderer = IsModifiedWrapperRenderer
                        break

                    case 'vin':
                        columnDef.filter = CommaSeparatedTextFilter
                        break

                    case 'solution':
                        columnDef.cellEditor = SolutionCellEditor

                        columnDef.suppressKeyboardEvent = params =>
                            params.event.key === 'Delete'
                        break

                    case 'art':
                        columnDef.cellEditor = ArtCellEditor
                        columnDef.filter = ArtFilter

                        columnDef.suppressKeyboardEvent = params =>
                            params.event.key === 'Delete'

                        break

                    case 'bin':
                        columnDef.cellEditor = BinCellEditor
                        columnDef.filter = BinFilter

                        columnDef.suppressKeyboardEvent = params =>
                            params.event.key === 'Delete'
                        break

                    case 'subSystem':
                        columnDef.cellEditor = SubsystemCellEditor
                        columnDef.filter = SubsystemFilter

                        columnDef.suppressKeyboardEvent = params =>
                            params.event.key === 'Delete'
                        break

                    case 'causeRemark':
                        columnDef.cellEditor = CauseRemarkCellEditor

                        columnDef.filter = 'agSetColumnFilter'
                        columnDef.filterParams = {
                            values: prependBlanks(
                                causeRemarks.map(c => c.name)
                            ),
                        }
                        columnDef.suppressKeyboardEvent = params =>
                            params.event.key === 'Delete'
                        break

                    case 'conversationStatus': {
                        columnDef.filter = 'agSetColumnFilter'
                        columnDef.filterParams = {
                            values: [BLANK_FILTER, ...conversationStatuses],
                        }
                        break
                    }

                    case 'mediaBlobPath':
                        columnDef.cellRenderer = MediaModal
                        columnDef.headerName = 'Media'
                        columnDef.width = 120
                        columnDef.filter = 'agSetColumnFilter'
                        columnDef.filterParams = {
                            values: [BLANK_FILTER, 'Has Media'],
                        }
                        break

                    case 'vehicleTests':
                        columnDef.tooltipComponent = VehicleTestsTooltip
                        columnDef.tooltipField = 'vehicleTests'
                        columnDef.valueFormatter = val =>
                            val.value
                                ?.split(',')
                                .map((x: string) => x.split('::')[1])
                                .join(', ')
                        break

                    case 'reportedMileageAtEvent':
                        columnDef.filter = 'agNumberColumnFilter'
                        break

                    case 'noShow':
                        columnDef.editable = hasNoShowPermission
                        break

                    case 'structureWeek':
                        columnDef.filter = StructureWeekFilter
                        break

                    default:
                        break
                }

                return columnDef
            })
            .concat({
                checkboxSelection: true,
                colId: 'checkBoxSelect',
                lockPinned: true,
                lockPosition: true,
                maxWidth: 55,
                pinned: 'left',
                resizable: false,
                sortable: false,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                suppressFiltersToolPanel: true,
            })

        if (hasTwoWayComPermission) {
            rows.push(
                {
                    cellRenderer: MessageModal,
                    colId: 'messageBox',
                    headerName: '2-way com',
                    maxWidth: 160,
                    sortable: false,
                    suppressFiltersToolPanel: true,
                    pinned: 'right',
                },
                {
                    cellRenderer: (params: ICellRendererParams<Feedback>) =>
                        params.data.conversationStatus,
                    colId: 'ConversationStatus',
                    headerName: 'Conversation Status',
                    maxWidth: 160,
                    suppressFiltersToolPanel: true,
                    pinned: 'right',
                }
            )
        }

        return rows
    }, [
        schemas,
        hasTwoWayComPermission,
        hasNoShowPermission,
        textDisplaySetting,
        user.view,
        hasInlinePermission,
        conversationStatuses,
        causeRemarks,
    ])

    const sideBar: SideBarDef = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 225,
                    maxWidth: 225,
                    width: 225,
                    toolPanelParams: {
                        suppressPivotMode: true,
                        suppressRowGroups: true,
                        suppressValues: true,
                    },
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 180,
                    maxWidth: 400,
                    width: 250,
                },
                {
                    id: 'activeFilters',
                    labelDefault: 'Active Filters',
                    labelKey: 'activeFilters',
                    iconKey: 'filter',
                    toolPanel: ActiveFiltersPanel,
                },
            ],
        }
    }, [])

    return { defaultColDef, columnDefs, sideBar }
}

export default useFeedbackGrid
