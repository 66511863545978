import { Button, makeStyles } from '@fluentui/react-components'
import { MailRegular, OpenRegular } from '@fluentui/react-icons'

import CloseButton from 'components/UI/CloseButon'
import { IConversation } from 'types/conversations'
import { exportArrayToCsv } from 'helpers/exporters'

interface INavigationProps {
    item: IConversation
    toggleOpen: () => void
    refreshGrid: () => void
}

const useStyles = makeStyles({
    last: { marginLeft: 'auto !important', color: '#2A609D' },
    blue: { color: '#2A609D' },
})

const Navigation = ({ item, toggleOpen, refreshGrid }: INavigationProps) => {
    const classes = useStyles()

    const handleExportConversation = () => {
        const data = item.codev_ReportConversationMessages.map(x => {
            return {
                cdsid: x.cdsid,
                createdOn: x.createdOn,
                message: x.message,
                isUser: x.isUser.toString(),
            }
        })
        //For adding header to the csv file
        data?.unshift({
            cdsid: 'CDSID',
            createdOn: 'Created On',
            message: 'Message',
            isUser: 'Is User',
        })
        exportArrayToCsv(`conversation-${item.id}.csv`, data)
    }

    return (
        <div style={{ display: 'flex', gridGap: '8px', margin: '0 0 32px' }}>
            <Button
                appearance='transparent'
                icon={<MailRegular />}
                iconPosition='after'
                onClick={() =>
                    window.open(
                        'https://intranet.volvocars.net/sites/codev/SitePages/contact-driver.aspx',
                        '_blank'
                    )
                }
                className={classes.blue}
            >
                Request driver contact information
            </Button>
            <Button
                appearance='transparent'
                icon={<OpenRegular />}
                iconPosition='after'
                onClick={handleExportConversation}
                disabled={!item}
                className={classes.blue}
            >
                Share Conversation
            </Button>
            <CloseButton
                iconPosition='after'
                onClick={() => {
                    refreshGrid()
                    toggleOpen()
                }}
                className={classes.last}
            />
        </div>
    )
}

export default Navigation
