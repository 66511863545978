import { Add24Regular, Pen24Regular } from '@fluentui/react-icons'

import ActionsMenu from 'components/Layout/ActionsMenu'
import AddModal from './AddModal'
import BatchEditModal from './BatchEditModal'
import { IActionsMenu } from 'types/ActionsMenu'
import { selectArtId } from 'slices/categorySlice'
import { useAppSelector } from 'store'
import { useGrid } from 'contexts/GridContext'
import { useState } from 'react'

interface IActionsPanelProps {
    allArts: Art[]
    allBins: Bin[]
}

const ActionsPanel = ({ allArts, allBins }: IActionsPanelProps) => {
    const [isEditOpen, setEditOpen] = useState(false)
    const [isAddOpen, setAddOpen] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])

    const { gridRef } = useGrid()
    gridRef?.current?.api.addEventListener('selectionChanged', () => {
        setSelectedRows(gridRef?.current?.api.getSelectedRows())
    })

    const selectedArtId = useAppSelector(selectArtId)

    const items: IActionsMenu[] = [
        {
            label: 'New',
            icon: <Add24Regular />,
            disabled: selectedArtId === null,
            onClick: () => setAddOpen(true),
        },
        {
            label: 'Batch Edit',
            icon: <Pen24Regular />,
            onClick: () => setEditOpen(true),
            disabled: selectedRows.length === 0,
        },
    ]

    return (
        <>
            <ActionsMenu items={items} />

            <BatchEditModal
                isOpen={isEditOpen}
                toggleModal={() => setEditOpen(pre => !pre)}
                allArts={allArts}
                selectedRows={selectedRows}
            />
            <AddModal
                isOpen={isAddOpen}
                toggleModal={() => setAddOpen(pre => !pre)}
                allBins={allBins}
            />
        </>
    )
}

export default ActionsPanel
