import { useMemo, useState } from 'react'

import AutoSelectInput from 'components/UI/AutoSelectInput'
import { Button } from '@fluentui/react-components'
import DialogWithIconButton from 'components/UI/DialogWithIconButton'
import { GridApi } from 'ag-grid-community'
import { PenRegular } from '@fluentui/react-icons'
import { usePatchCauseRemarksMutation } from 'api/category'

interface ICauseRemarkEditModalProps {
    data: CauseRemark
    api: GridApi
    causeRemarks: CauseRemark[]
}

const CauseRemarkEditModal = ({
    data,
    api,
    causeRemarks,
}: ICauseRemarkEditModalProps) => {
    const [inputValue, setInputValue] = useState(data.name)

    const [patchCauseRemark, { isLoading }] = usePatchCauseRemarksMutation()

    const handleClick = async (toggleOpen: () => void) => {
        const result = await patchCauseRemark({
            id: data.id,
            data: [
                {
                    path: 'name',
                    value: inputValue,
                },
            ],
        })

        if (result) {
            toggleOpen()
            api.refreshCells()
        }
    }

    const isDuplicate = useMemo(() => {
        if (inputValue === '' || inputValue === data.name) {
            return false
        }
        return causeRemarks.map(s => s.name).includes(inputValue)
    }, [causeRemarks, data.name, inputValue])

    return (
        <DialogWithIconButton
            icon={<PenRegular />}
            dialogTitle='Edit Cause Remark'
            dialogContent={() => (
                <AutoSelectInput
                    title='Cause Remark'
                    callback={setInputValue}
                    validationMessage={
                        isDuplicate ? 'Cause Remark already exists' : null
                    }
                    inputValue={inputValue}
                />
            )}
            actionRender={toggleOpen => (
                <Button
                    appearance='primary'
                    disabled={
                        isLoading ||
                        isDuplicate ||
                        inputValue === '' ||
                        inputValue === data.name
                    }
                    onClick={() => handleClick(toggleOpen)}
                >
                    Edit
                </Button>
            )}
        />
    )
}

export default CauseRemarkEditModal
