import { MessageBar, MessageBarBody } from '@fluentui/react-components'

import { CSVLink } from 'react-csv'

interface ISuccesssBoxProps {
    csvData: unknown
    fileName: string
}

const SuccessBox = ({ csvData, fileName }: ISuccesssBoxProps) => (
    <div style={{ margin: '16px 0' }}>
        <MessageBar intent='success'>
            <MessageBarBody>Report created.</MessageBarBody>
        </MessageBar>
        <CSVLink
            data={csvData}
            target='_blank'
            filename={fileName}
            separator=';'
        >
            Download
        </CSVLink>
    </div>
)
export default SuccessBox
