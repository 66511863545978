/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
const Guidelines = () => {
    return (
        <div style={{ padding: '8px', margin: '10px' }}>
            <h4>BE PROFESSIONAL</h4>
            <p>
                When in contact with a driver - be polite and professional and
                treat them like customers.
                <br />
                <br />
                Start conversations by presenting what unit you represent. Thank
                them for taking the time to help us out before closing a
                conversation, and{' '}
                <b>do not forget to close conversations when you're done.</b>
                <br />
                <br />
                Practice a need-to-know basis when exchanging information or
                data. Do not share personal opinions or reflections that are not
                aligned with our company's best interest and policies.
                <br />
                <br />
                <b>Do not give your telephone number or ask for theirs.</b> If
                you need to contact the driver, use the Request Driver Contact
                button.
            </p>
            <h4>BE SAFE</h4>
            <p>
                <b>Do not share or ask for personal details.</b> Avoid
                discussing anything related to personal information, political,
                ethnical, sexual affiliations or health related matters.
                <br />
                <br />
                <b>If drivers share any sensitive or personal data,</b> or
                addresses or telephone numbers, please contact your admin at
                your unit.
            </p>
            <h4>BE CLEAR</h4>
            <p>
                Try to understand if the driver is technically oriented or not
                and try to pick a level of discussion that is suitable.{' '}
                <b>Be specific and to the point</b> and don't use technical
                jargon or acronyms that only you might understand.
            </p>
        </div>
    )
}

export default Guidelines
