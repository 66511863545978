import {
    Button,
    Checkbox,
    Field,
    Select,
    makeStyles,
    mergeClasses,
    shorthands,
} from '@fluentui/react-components'
import { Fragment, useMemo } from 'react'
import { selectUser, selectViews, setView, updateRole } from 'slices/authSlice'
import { useAppDispatch, useAppSelector } from 'store'

import Header from 'components/Layout/Header'
import { Key24Regular } from '@fluentui/react-icons'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles({
    wrapper: {
        width: '800px',
        ...shorthands.padding('8px'),
    },
    roles: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        marginBottom: '8px',
    },
    rolesDescription: {
        gridColumnStart: 1,
        gridColumnEnd: 3,
    },
    roleType: {
        fontWeight: 'bold',
        ...shorthands.margin('8px', 0, '4px'),
    },
})

const RolePicker = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const classes = useStyles()

    const user = useAppSelector(selectUser)
    const views = useAppSelector(selectViews)

    const options = useMemo(
        () =>
            views.map(v => ({
                key: v,
                text: v,
            })),
        [views]
    )

    const onChange = (_e, data) => {
        dispatch(setView(data.value as View))
    }

    const handleCheckboxChange = (id: string, checked: boolean | 'mixed') => {
        dispatch(updateRole({ key: id, isDisabled: !checked }))
    }

    const checkbox = r => (
        <Checkbox
            key={r.key}
            label={r.key}
            onChange={(_e, data) => handleCheckboxChange(r.key, data.checked)}
            defaultChecked={!r.isDisabled}
        />
    )

    return (
        <>
            <Header title='Role/View picker' icon={<Key24Regular />} />
            <div className={classes.wrapper} data-cy='view-picker-wrapper'>
                <Field label='View picker' style={{ marginBottom: '8px' }}>
                    <Select
                        value={user ? user.view : null}
                        onChange={onChange}
                        data-cy='view-picker-select'
                    >
                        {options.map(o => (
                            <option key={o.key} value={o.key}>
                                {o.text}
                            </option>
                        ))}
                    </Select>
                </Field>

                <Button
                    data-cy='go-to-startpage-button'
                    onClick={() => navigate('/')}
                >
                    Go to feedback grid
                </Button>

                <div className={classes.roles} data-cy='role-picker-wrapper'>
                    <p className={classes.rolesDescription}>
                        You have the following roles uncheck the roles you do
                        not want to use. You can always change this later.
                    </p>
                    {['View', 'Functions'].map(r => (
                        <Fragment key={r}>
                            <p
                                className={mergeClasses(
                                    classes.rolesDescription,
                                    classes.roleType
                                )}
                            >
                                {r}
                            </p>
                            {user.roles
                                .filter(
                                    a =>
                                        a.key?.includes('Trillion') &&
                                        a.key?.includes(r)
                                )
                                .sort((a, b) => a.key?.localeCompare(b.key))
                                .map(checkbox)}
                        </Fragment>
                    ))}
                </div>
            </div>
        </>
    )
}

export default RolePicker
