import {
    BLANK_FILTER,
    DELETED_FILTER,
    MAX_FILTER_LENGTH,
} from 'constants/common'
import { GridApi, ISetFilterParams } from 'ag-grid-community'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { useGetArtsQuery, useGetSolutionsQuery } from 'api/category'

import { SetFilter } from 'ag-grid-enterprise'

type ArtOption = Partial<Art & { disabled: boolean }>

const getSelectedSolutionId = (api: GridApi, solutions: Solution[]) => {
    const solutionFilter =
        api.getFilterInstance<SetFilter<Solution>>('solution')
    const selectedValues = solutionFilter?.getModel()?.values

    if (!selectedValues || selectedValues.length === 0) {
        return []
    }

    return solutions
        .filter(solution => !solution.isArchived)
        .filter(solution => selectedValues.includes(solution.name))
        .map(solution => solution.id)
}

const ArtFilter = forwardRef(
    ({ api, filterChangedCallback }: ISetFilterParams, ref) => {
        const { data: arts } = useGetArtsQuery()

        const { data: solutions = [] } = useGetSolutionsQuery()

        const [allOptions, setAllOptions] = useState<ArtOption[]>(
            arts
                .filter(art => !art.isArchived)
                .sort((a, b) => a.name.localeCompare(b.name))
        )
        const [options, setOptions] = useState<ArtOption[]>([])
        const [searchText, setSearchText] = useState<string>('')
        const [error, setError] = useState<Error>(null)

        let selectedSolutions = getSelectedSolutionId(api, solutions)

        const handleClear = () => {
            setOptions([])
            // this is needed to trigger the filterChangedCallback
            // after the options are cleared
            setTimeout(filterChangedCallback, 0)
        }

        const handleApply = () => {
            if (options.length > MAX_FILTER_LENGTH) {
                setError(
                    new Error(
                        // eslint-disable-next-line max-len
                        `I'm sorry but you can only select up to ${MAX_FILTER_LENGTH} options. You have selected ${options.length} options. Remove some options and try again.`
                    )
                )
                return
            }
            filterChangedCallback()
        }

        const handleSelectAll = () => {
            if (
                options.length ===
                allOptions.filter(o => !o.disabled).length + 1 // add one for the blank filter
            ) {
                setOptions([])
            } else {
                setOptions([
                    ...allOptions
                        .filter(o => !o.disabled)
                        .filter(option =>
                            option.name
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                        ),
                    { id: null, name: BLANK_FILTER },
                ])
            }
        }

        const handleSelectOption = (option: ArtOption) => {
            if (option.disabled) return

            if (options.includes(option)) {
                setOptions(prev => prev.filter(o => o.id !== option.id))
            } else {
                setOptions(prev => [...prev, option])
            }
        }

        const handleBlankFilter = () => {
            if (options.map(o => o.name).includes(BLANK_FILTER)) {
                setOptions(prev => prev.filter(o => o.name !== BLANK_FILTER))
            } else {
                setOptions(prev => [...prev, { id: null, name: BLANK_FILTER }])
            }
        }

        const handleDeletedFilter = () => {
            if (options.map(o => o.name).includes(DELETED_FILTER)) {
                setOptions(prev => prev.filter(o => o.name !== DELETED_FILTER))
            } else {
                setOptions(prev => [
                    ...prev,
                    { id: null, name: DELETED_FILTER },
                ])
            }
        }

        useImperativeHandle(ref, () => ({
            externalFilterPresent: null,

            isFilterActive() {
                return !!options?.length || this.externalFilterPresent
            },

            getModel() {
                if (!this.isFilterActive()) {
                    return null
                }

                return {
                    filterType: 'setOpt',
                    values: this.externalFilterPresent || options,
                }
            },

            setModel(model: { values: Art[] }) {
                if (model?.values) {
                    selectedSolutions = getSelectedSolutionId(api, solutions)
                    if (!selectedSolutions || selectedSolutions.length === 0) {
                        setAllOptions(prev =>
                            prev.map(o => ({ ...o, disabled: false }))
                        )
                    } else {
                        setAllOptions(prev =>
                            prev.map(o => ({
                                ...o,
                                disabled: !selectedSolutions.includes(
                                    o.solutionId
                                ),
                            }))
                        )
                    }

                    // set the options to the model values
                    // cast the ids to numbers and set disabled to false
                    setOptions(
                        model.values.map(v => ({
                            ...v,
                            id: parseInt(v.id?.toString(), 10),
                            solutionId: parseInt(v.solutionId?.toString(), 10),
                            disabled: false,
                        }))
                    )

                    // as the setOptions is not fast enough we have to this to
                    // make sure the filter knows that there is a filter present
                    this.externalFilterPresent = model.values
                } else {
                    handleClear()
                    this.externalFilterPresent = null
                }
            },

            refreshFilterValues() {
                selectedSolutions = getSelectedSolutionId(api, solutions)

                // find options that are not in the filter and remove them then run filterChangedCallback
                const filteredOptions = options.filter(
                    o =>
                        selectedSolutions.includes(o.solutionId) ||
                        isNaN(o.solutionId)
                )

                if (
                    selectedSolutions &&
                    filteredOptions.length !== options.length
                ) {
                    setOptions(filteredOptions)
                    setTimeout(filterChangedCallback, 0)
                    this.externalFilterPresent = null
                }

                if (!selectedSolutions || selectedSolutions.length === 0) {
                    setAllOptions(prev =>
                        prev.map(o => ({ ...o, disabled: false }))
                    )
                } else {
                    setAllOptions(prev =>
                        prev.map(o => ({
                            ...o,
                            disabled: !selectedSolutions.includes(o.solutionId),
                        }))
                    )
                }
            },
        }))

        const filterDuplicates = (
            value: Art,
            index: number,
            self: Art[]
        ): boolean => self.findIndex(t => t.name === value.name) === index

        return (
            <>
                <div className='ag-filter-body-wrapper ag-set-filter-body-wrapper'>
                    Filter options are filtered by the selected Solution. If no
                    Solution is selected, all options are available.
                    {error && (
                        <div style={{ padding: '4px 0 0 0', color: 'red' }}>
                            {error.message}
                        </div>
                    )}
                    <div className='ag-mini-filter'>
                        <input
                            className='ag-input-field-input ag-text-field-input'
                            type='text'
                            placeholder='Search...'
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                        />
                    </div>
                    <div className='ag-set-filter-list'>
                        <div className='ag-virtual-list-viewport ag-filter-virtual-list-viewport ag-focus-managed'>
                            <div
                                style={{ gridGap: '8px' }}
                                className='ag-virtual-list-container ag-filter-virtual-list-container'
                            >
                                <div className='ag-filter-virtual-list-item'>
                                    <div className='ag-set-filter-item'>
                                        <div
                                            className='ag-set-filter-item-checkbox ag-label-align-right'
                                            onClick={handleSelectAll}
                                        >
                                            <div className='ag-input-field-label ag-label ag-checkbox-label'>
                                                (Select All)
                                            </div>
                                            <div
                                                className={`ag-input-wrapper ag-checkbox-input-wrapper ${
                                                    options?.length ===
                                                    allOptions.length + 1
                                                        ? 'ag-checked'
                                                        : options?.length
                                                        ? 'ag-indeterminate'
                                                        : ''
                                                }`}
                                            ></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='ag-filter-virtual-list-item'>
                                    <div className='ag-set-filter-item'>
                                        <div
                                            className='ag-set-filter-item-checkbox ag-label-align-right'
                                            onClick={handleBlankFilter}
                                        >
                                            <div className='ag-input-field-label ag-label ag-checkbox-label'>
                                                {BLANK_FILTER}
                                            </div>
                                            <div
                                                className={`ag-input-wrapper ag-checkbox-input-wrapper ${
                                                    options
                                                        .map(o => o.name)
                                                        .includes(BLANK_FILTER)
                                                        ? 'ag-checked'
                                                        : ''
                                                }`}
                                            ></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='ag-filter-virtual-list-item'>
                                    <div className='ag-set-filter-item'>
                                        <div
                                            className='ag-set-filter-item-checkbox ag-label-align-right'
                                            onClick={handleDeletedFilter}
                                        >
                                            <div className='ag-input-field-label ag-label ag-checkbox-label'>
                                                {DELETED_FILTER}
                                            </div>
                                            <div
                                                className={`ag-input-wrapper ag-checkbox-input-wrapper ${
                                                    options
                                                        .map(o => o.name)
                                                        .includes(
                                                            DELETED_FILTER
                                                        )
                                                        ? 'ag-checked'
                                                        : ''
                                                }`}
                                            ></div>
                                        </div>
                                    </div>
                                </div>

                                {allOptions
                                    // remove options that don't match the search text
                                    .filter(option =>
                                        option.name
                                            .toLowerCase()
                                            .includes(searchText.toLowerCase())
                                    )
                                    // remove options that are not in the selected solutions
                                    .filter(o =>
                                        selectedSolutions.length === 0
                                            ? true
                                            : selectedSolutions.includes(
                                                  o.solutionId
                                              )
                                    )
                                    // remove options that are disabled, might be redundant
                                    .filter(option => !option.disabled)
                                    // remove duplicates options
                                    .filter(filterDuplicates)
                                    .map(option => (
                                        <div
                                            className='ag-filter-virtual-list-item'
                                            key={option.id}
                                        >
                                            <div className='ag-set-filter-item'>
                                                <div
                                                    className='ag-set-filter-item-checkbox ag-label-align-right'
                                                    onClick={() =>
                                                        handleSelectOption(
                                                            option
                                                        )
                                                    }
                                                >
                                                    <div className='ag-input-field-label ag-label ag-checkbox-label'>
                                                        {option.name}
                                                    </div>
                                                    <div
                                                        className={`ag-input-wrapper ag-checkbox-input-wrapper ${
                                                            options
                                                                .map(o => o.id)
                                                                ?.includes(
                                                                    option.id
                                                                )
                                                                ? 'ag-checked'
                                                                : ''
                                                        }`}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='ag-filter-apply-panel'>
                    <button
                        type='button'
                        className='ag-standard-button ag-filter-apply-panel-button'
                        onClick={handleClear}
                    >
                        Reset
                    </button>
                    <button
                        type='button'
                        className='ag-standard-button ag-filter-apply-panel-button'
                        onClick={handleApply}
                    >
                        Apply
                    </button>
                </div>
            </>
        )
    }
)

ArtFilter.displayName = 'Custom Art Filter'

export default ArtFilter
