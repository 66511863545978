import '../Layout/AgGridContainer.scss'

import { PropsWithChildren } from 'react'
import { selectDarkMode } from 'slices/preferenceSlice'
import { useAppSelector } from 'store'

interface IContainerProps extends PropsWithChildren<unknown> {
    hide?: boolean
    offsetHeight?: number
}

const AgGridContainer = ({ hide, offsetHeight, children }: IContainerProps) => {
    const darkMode = useAppSelector(selectDarkMode)
    return (
        <div
            className={darkMode ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'}
            style={{
                height: offsetHeight
                    ? `calc(100% - ${offsetHeight}px)`
                    : ' 100%',
                padding: '5px',
                backgroundColor: darkMode ? '#000000' : '#ededed',
                display: hide ? 'none' : 'block',
            }}
        >
            {children}
        </div>
    )
}

export default AgGridContainer
