import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
} from '@fluentui/react-components'
import { useMemo, useState } from 'react'

import AutoSelectInput from 'components/UI/AutoSelectInput'
import { selectBinId } from 'slices/categorySlice'
import { useAppSelector } from 'store'
import { usePostSubsystemsMutation } from 'api/category'

interface IProps {
    isOpen?: boolean
    toggleModal: () => void
    allSubsystems: Subsystem[]
}

const AddModal = ({ isOpen, toggleModal, allSubsystems }: IProps) => {
    const [inputValue, setInputValue] = useState<string>()

    const selectedBinId = useAppSelector(selectBinId)

    const [postSubsystem] = usePostSubsystemsMutation()

    const subsystems = useMemo(
        () => allSubsystems.filter(({ binId }) => binId === selectedBinId),
        [allSubsystems, selectedBinId]
    )

    const isDuplicate = useMemo(() => {
        if (inputValue === '') {
            return false
        }
        return subsystems.map(({ name }) => name).includes(inputValue)
    }, [inputValue, subsystems])

    const handleClick = async (toggleOpen: () => void) => {
        const response = await postSubsystem({
            name: inputValue,
            binId: selectedBinId,
        })

        if ('data' in response) {
            setInputValue('')
            toggleOpen()
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={toggleModal}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Add Subsystem</DialogTitle>
                    <DialogContent>
                        <AutoSelectInput
                            title='Subsystem'
                            inputValue={inputValue}
                            callback={name => setInputValue(name)}
                            validationMessage={
                                isDuplicate ? 'Subsystem already exists' : null
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance='primary'
                            disabled={!inputValue || isDuplicate}
                            onClick={() => handleClick(toggleModal)}
                        >
                            Save
                        </Button>
                        <Button onClick={toggleModal}>Cancel</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default AddModal
