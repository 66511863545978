import BinArchiveModal from './BinArchiveModal'
import BinDeleteModal from './BinDeleteModal'
import BinEditModal from './BinEditModal'
import { GridApi } from 'ag-grid-community'

interface IProps {
    data: Bin
    api: GridApi
    allBins: Bin[]
    allArts: Art[]
}

const BinActions = ({ data, api, allBins, allArts }: IProps) => (
    <div style={{ display: 'flex', gridGap: '8px' }}>
        <BinEditModal
            data={data}
            api={api}
            allBins={allBins}
            allArts={allArts}
        />
        <BinDeleteModal data={data} api={api} />
        <BinArchiveModal data={data} api={api} />
    </div>
)

export default BinActions
