import './subsystemManagementStyle.scss'

import { Checkbox, Field, makeStyles } from '@fluentui/react-components'
import {
    selectArchived,
    selectArtId,
    selectBinId,
    setActiveArtId,
    setActiveBinId,
    toggleArchiveState,
} from 'slices/categorySlice'
import { useAppDispatch, useAppSelector } from 'store'
import { useMemo, useRef } from 'react'

import AgGridContainer from 'components/Layout/AgGridContainer'
import { AgGridReact } from 'ag-grid-react'
import AutoSelectDropdown from 'components/UI/AutoSelectDropdown'
import { ColDef } from 'ag-grid-community'
import SubsystemActions from './cellRenderers/SubsystemActions'
import { getContextMenuItems } from 'helpers/dataGridHelper'
import { useGrid } from 'contexts/GridContext'

interface ISubsystemManagementProps {
    allArts: Art[]
    allBins: Bin[]
    allSubsystems: Subsystem[]
}

const useStyle = makeStyles({
    wrapper: {
        display: 'flex',
        gridGap: '16px',
        alignItems: 'center',
        marginBottom: '16px',
    },
})

const SubsystemManagement = ({
    allArts,
    allBins,
    allSubsystems,
}: ISubsystemManagementProps) => {
    const gridRef = useRef<AgGridReact>(null)
    const dispatch = useAppDispatch()

    const classes = useStyle()

    const selectedArtId = useAppSelector(selectArtId)
    const selectedBinId = useAppSelector(selectBinId)
    const showArchived = useAppSelector(selectArchived)

    const subsystems = useMemo(
        () =>
            allSubsystems.filter(
                s => s.binId === selectedBinId && s.isArchived === showArchived
            ),
        [allSubsystems, selectedBinId, showArchived]
    )

    const bins = useMemo(
        () =>
            allBins
                .filter(b => !b.isArchived && b.artId === selectedArtId)
                .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                ),
        [allBins, selectedArtId]
    )

    const arts = useMemo(
        () =>
            allArts
                .filter(a => !a.isArchived)
                .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                ),
        [allArts]
    )

    const { setGridRef } = useGrid()

    const customComparator = (a: string, b: string) => {
        return a.toLowerCase().localeCompare(b.toLowerCase())
    }

    const columnDefs = useMemo<ColDef[]>(
        () => [
            {
                checkboxSelection: true,
                colId: 'checkBoxSelect',
                lockPinned: true,
                lockPosition: true,
                maxWidth: 55,
                pinned: 'left',
                resizable: false,
                sortable: false,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                suppressFiltersToolPanel: true,
            },
            {
                field: 'name',
                flex: 1,
                sort: 'asc',
                comparator: customComparator,
            },
            {
                headerName: '',
                field: 'actions',
                suppressMenu: true,
                width: 140,
                flex: 0,
                editable: false,
                cellRenderer: SubsystemActions,
                cellRendererParams: {
                    allBins,
                    allSubsystems,
                },
            },
        ],
        [allBins, allSubsystems]
    )

    const handleArtChange = (option: Art) => {
        dispatch(setActiveArtId(option?.id))
        dispatch(setActiveBinId(null))
    }

    const handleBinChange = (option: Bin) => {
        dispatch(setActiveBinId(option?.id))
    }

    const handleGridReady = () => {
        setGridRef(gridRef)
    }

    return (
        <AgGridContainer offsetHeight={70}>
            <div className={classes.wrapper}>
                <div style={{ flexGrow: 2 }}>
                    <AutoSelectDropdown
                        title='Art'
                        data={arts}
                        selectedId={selectedArtId}
                        callback={handleArtChange}
                    />
                </div>
                <div style={{ flexGrow: 2 }}>
                    <AutoSelectDropdown
                        title='Bin'
                        data={bins}
                        selectedId={selectedBinId}
                        disabled={!selectedArtId}
                        callback={handleBinChange}
                    />
                </div>
                <Field label='Show Archived' style={{ flexGrow: 1 }}>
                    <Checkbox
                        checked={showArchived}
                        onChange={() => dispatch(toggleArchiveState())}
                        disabled={!selectedBinId}
                    />
                </Field>
            </div>
            <AgGridReact
                enableRangeSelection
                ref={gridRef}
                rowSelection='multiple'
                className='subsystemManagement'
                rowData={subsystems}
                columnDefs={columnDefs}
                getContextMenuItems={getContextMenuItems}
                onGridReady={handleGridReady}
            />
        </AgGridContainer>
    )
}

export default SubsystemManagement
