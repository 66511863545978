import DropdownCellEditor from './DropdownCellEditor'
import { Feedback } from 'types/feedback'
import { ICellRendererParams } from 'ag-grid-community'
import { forwardRef } from 'react'
import { useGetCauseRemarksQuery } from 'api/category'

type ICauseRemarkCellEditor = ICellRendererParams<Feedback> & {
    stopEditing: () => void
}

const CauseRemarkCellEditor = forwardRef(
    ({ data, stopEditing }: ICauseRemarkCellEditor, ref) => {
        const { data: causeRemarks = [] } = useGetCauseRemarksQuery()
        const filter = (item: CauseRemark) => !item.isDeleted

        return (
            <DropdownCellEditor<CauseRemark>
                data={data}
                filter={filter}
                dataKey='causeRemark'
                options={causeRemarks}
                forwardRef={ref}
                stopEditing={stopEditing}
            />
        )
    }
)

CauseRemarkCellEditor.displayName = 'Cause Remark Cell Editor'

export default CauseRemarkCellEditor
