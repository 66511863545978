import {
    Button,
    ButtonProps,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components'

import { IActionsMenu } from 'types/ActionsMenu'
import { Link } from 'react-router-dom'
import { selectDarkMode } from 'slices/preferenceSlice'
import { useAppSelector } from 'store'

const ActionsMenuItems = ({ item }: { item: IActionsMenu }) => {
    const darkMode = useAppSelector(selectDarkMode)

    const getButtonProps = (_item: IActionsMenu): ButtonProps => {
        return {
            appearance: _item.appearance || 'subtle',
            icon: _item.icon,
            disabled: _item.disabled,
            size: 'small',
        }
    }

    return item.children?.length > 0 ? (
        <>
            <Menu>
                <MenuTrigger>
                    <MenuButton
                        {...getButtonProps(item)}
                        style={{
                            color: darkMode ? '#FFFFFF' : '#000000',
                        }}
                    >
                        {item.label}
                    </MenuButton>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        {item.children.map(i => (
                            <MenuItem key={i.label}>
                                <Button
                                    {...getButtonProps(i)}
                                    onClick={() => i.onClick()}
                                >
                                    {i.label}
                                </Button>
                            </MenuItem>
                        ))}
                    </MenuList>
                </MenuPopover>
            </Menu>
        </>
    ) : (
        <Link to={item.link}>
            <Button
                {...getButtonProps(item)}
                onClick={item.onClick}
                style={{
                    color: darkMode ? '#FFFFFF' : '#000000',
                }}
            >
                {item.label}
            </Button>
        </Link>
    )
}

export default ActionsMenuItems
