export const IS_DEBUG_ON =
    process.env.REACT_APP_DEBUG_ON && process.env.REACT_APP_DEBUG_ON === 'true'

export const ENV_DEV = process.env.NODE_ENV === 'development'
export const ENV_PROD = process.env.NODE_ENV === 'production'

export const BLANK_FILTER = '(Blanks)'
export const DELETED_FILTER = '(Deleted)'
export const DATETIME_FORMAT = 'yyMMdd - HH:mm'
export const DATETIME_FORMAT_SMALL = 'yyMMdd'

export const MAX_FILTER_LENGTH = 200

export const TRILLION_SUPPORT_URL =
    'https://volvocars.service-now.com/kb_view.do?sysparm_article=KB0041990'

export const REFLECT_URL = 'https://reflect.volvocars.biz/'
export const RIVENDELL_URL = 'https://rivendell.volvocars.biz/'
export const PORTAL_URL = 'https://codevportal.volvocars.biz/'
export const SHAREPOINT_URL =
    'https://intranet.volvocars.net/sites/codev/SitePages/Home.aspx'
