import { PropsWithChildren } from 'react'

const Content = ({ children }: PropsWithChildren<unknown>) => {
    return (
        <div
            style={{
                height: 'calc(100vh - 110px)', // 60px header + 40px action + 10px padding
            }}
        >
            {children}
        </div>
    )
}

export default Content
