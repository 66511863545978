import { ITrillionGroup } from 'types/trillionGroup'
import { apiSlice } from './apiSlice'
import { lowerCaseKeys } from 'helpers/objectManipulation'

const groupMembersApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getGroupMembers: builder.query<ITrillionGroup[], void>({
            query: () => 'api/GroupMembers',
            transformResponse: (response: ITrillionGroup[]) =>
                lowerCaseKeys(response) as unknown as ITrillionGroup[],
        }),
    }),
})

export const { useGetGroupMembersQuery } = groupMembersApi
