import {
    Button,
    Combobox,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Field,
    Input,
    useComboboxFilter,
} from '@fluentui/react-components'
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useGetSubsystemsQuery, usePostSubsystemsMutation } from 'api/category'

import AddButton from 'components/UI/AddButton'
import CloseButton from 'components/UI/CloseButon'
import { Feedback } from 'types/feedback'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { ICellRendererParams } from 'ag-grid-community'
import { SetFilter } from 'ag-grid-enterprise'
import { triggerMessage } from 'slices/messageSlice'
import { useAppDispatch } from 'store'

type ISubsystemCellEditor = ICellRendererParams<Feedback> & {
    stopEditing: () => void
    subsystems: Subsystem[]
}

const SubsystemCellEditor = forwardRef(
    ({ data, stopEditing, api }: ISubsystemCellEditor, ref) => {
        const [postSubsystem] = usePostSubsystemsMutation()
        const inputRef = useRef<HTMLInputElement>()
        const dispatch = useAppDispatch()

        const { data: subsystems } = useGetSubsystemsQuery()

        useEffect(() => {
            inputRef.current?.focus()
        }, [inputRef])

        const options = useMemo(() => {
            if (data.binId) {
                return [...subsystems]
                    .filter(b => b.binId === data.binId && !b.isArchived)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(({ name }) => ({ value: name, children: name }))
            }
            return []
        }, [subsystems, data.binId])

        const [query, setQuery] = useState<string>('')

        const [selectedOptions, setSelectedOptions] = useState<string[]>([])

        // stop editing when an item is selected
        useEffect(() => {
            if (selectedOptions.length > 0) {
                setTimeout(stopEditing, 0)
            }
        }, [selectedOptions, stopEditing])

        // stop editing if no bin is selected
        useEffect(() => {
            if (!data.bin) {
                dispatch(
                    triggerMessage({
                        intent: 'warning',
                        message: 'Please select an Bin first.',
                    })
                )
                stopEditing()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [data.bin])

        // expose the getValue, isCancelBeforeStart and isCancelAfterEnd method to the grid
        useImperativeHandle(ref, () => ({
            getValue() {
                return selectedOptions?.[0] ?? data.subSystem
            },
            isCancelBeforeStart() {
                return false
            },
            isCancelAfterEnd() {
                return false
            },
        }))

        const onClose = () => {
            stopEditing()
        }

        const onAdd = async () => {
            const response = await postSubsystem({
                name: query,
                binId: data.binId,
            })

            if ('data' in response) {
                setSelectedOptions([response.data.name])

                api.applyTransaction({
                    update: [
                        {
                            ...data,
                            subSystem: response.data.name,
                            subSystemId: response.data.id,
                        },
                    ],
                })
                const filter = api.getFilterInstance<
                    SetFilter<Subsystem> & {
                        addOptionsToFilter: (newOption: Subsystem) => void
                    }
                >('subSystem')
                filter?.addOptionsToFilter(response.data)
            } else {
                dispatch(
                    triggerMessage({
                        intent: 'error',
                        message: (response.error as FetchBaseQueryError)
                            .data as string,
                    })
                )
                stopEditing()
            }
        }

        return (
            <>
                <Dialog>
                    <DialogTrigger>
                        <AddButton />
                    </DialogTrigger>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>Add new Subsystem</DialogTitle>
                            <DialogContent>
                                <Field label='Subsystem Name' required>
                                    <Input
                                        defaultValue={query}
                                        onChange={(_e, { value }) =>
                                            setQuery(value)
                                        }
                                    />
                                </Field>
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger>
                                    <Button onClick={onClose}>Cancel</Button>
                                </DialogTrigger>
                                <Button
                                    appearance='primary'
                                    onClick={onAdd}
                                    disabled={!query && query === ''}
                                >
                                    Add
                                </Button>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>

                <CloseButton onClick={stopEditing} />

                <Combobox
                    open
                    ref={inputRef}
                    size='large'
                    value={query}
                    onChange={ev => setQuery(ev.target.value)}
                    placeholder='Search...'
                    onOptionSelect={(_ev, d) => {
                        setSelectedOptions(d.selectedOptions)
                        setQuery(d.optionText ?? '')
                    }}
                >
                    {useComboboxFilter(query, options, {
                        noOptionsMessage: 'No match your search...',
                    })}
                </Combobox>
            </>
        )
    }
)

SubsystemCellEditor.displayName = 'Subsystem Cell Editor'

export default SubsystemCellEditor
