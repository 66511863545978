import {
    useGetArtsQuery,
    useGetBinsQuery,
    useGetSubsystemsQuery,
} from 'api/category'

import ActionsPanel from 'components/SubsystemManagement/actionPanels/ActionsPanel'
import Content from 'components/Layout/Content'
import { DocumentDatabase24Regular } from '@fluentui/react-icons'
import Header from 'components/Layout/Header'
import Loader from 'components/UI/Loader'
import SubsystemManagement from 'components/SubsystemManagement/SubsystemManagement'

const Subsystems = () => {
    const { data: allSubsystems, isLoading: subsystemsLoading } =
        useGetSubsystemsQuery()
    const { data: allBins, isLoading: binsLoading } = useGetBinsQuery()
    const { data: allArts, isLoading: artsLoading } = useGetArtsQuery()

    if (subsystemsLoading || binsLoading || artsLoading) {
        return <Loader text='Loading...' />
    }
    return (
        <>
            <Header
                title='Subsystem Management'
                icon={<DocumentDatabase24Regular />}
            />
            <ActionsPanel allBins={allBins} allSubsystems={allSubsystems} />
            <Content>
                <SubsystemManagement
                    allArts={allArts}
                    allBins={allBins}
                    allSubsystems={allSubsystems}
                />
            </Content>
        </>
    )
}

export default Subsystems
