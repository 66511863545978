import { RootState } from 'store'
import { createSlice } from '@reduxjs/toolkit'
import { loadState } from 'helpers/localStorage'

interface IPreferenceState {
    darkMode: boolean
    textDisplaySetting: 'hover' | 'inline'
    defaultViews?: [{ path: string; id: PersonalView['id'] }]
}

// load persisted state from local storage
const persistedState = loadState()
const initialState: IPreferenceState = {
    textDisplaySetting:
        persistedState?.preference?.textDisplaySetting ?? 'hover',
    darkMode: persistedState?.preference?.darkMode ?? false,
    defaultViews: persistedState?.preference?.defaultViews,
}

const preferenceSlice = createSlice({
    name: 'preference',
    initialState,
    reducers: {
        toggleDarkMode: state => {
            state.darkMode = !state.darkMode
        },
        setTextDisplaySetting: (state, action) => {
            state.textDisplaySetting = action.payload
        },
        setDefaultPersonalView: (state, action) => {
            if (!state.defaultViews) {
                state.defaultViews = [
                    { path: action.payload.path, id: action.payload.id },
                ]
            } else {
                const view = state.defaultViews.find(
                    dv => dv.path === action.payload.path
                )
                if (view) {
                    view.id = action.payload.id
                } else {
                    state.defaultViews.push({
                        path: action.payload.path,
                        id: action.payload.id,
                    })
                }
            }
        },
    },
})

export const { toggleDarkMode, setTextDisplaySetting, setDefaultPersonalView } =
    preferenceSlice.actions

export const selectTextDisplaySetting = (state: RootState) =>
    state.preference.textDisplaySetting
export const selectDarkMode = (state: RootState) => state.preference.darkMode
export const selectDefaultViews = (state: RootState) =>
    state.preference.defaultViews

export default preferenceSlice.reducer
