import { BLANK_FILTER, MAX_FILTER_LENGTH } from 'constants/common'
import {
    BOOLEAN_COLUMNS,
    CSV_COLUMNS,
    DATE_COLUMNS,
    MEDIA_COLUMNS,
    NUMBER_COLUMNS,
    SET_COLUMNS,
    STRUCTUREWEEK_COLUMNS,
} from 'constants/gridColumns'

import { ExtendedOdataProvider } from './dataGridHelper'
import FilterModelEnum from 'constants/FilterModelEnum'
import createYearWeekFilterString from './createYearWeekString'
import formatISO9075 from 'date-fns/formatISO9075'

type CustomFilter = {
    [index: string]: (
        colName: string,
        col: unknown,
        isCaseSensitiveStringFilter: boolean,
        provider: ExtendedOdataProvider
    ) => string
}

/**
 * filter function for columns that have the columnType 'setfilter'
 * @param colName string
 * @param col { values: string[] }
 * @returns string
 */
const customSetFilterFunction = (
    colName: string,
    col: { values: string[] },
    _isCaseSensitive: boolean,
    provider: ExtendedOdataProvider
) => {
    if (col.values.length) {
        return provider.odataOperator.inWithNullAndUrlEncode(
            colName,
            col.values
        )
    }
    return ''
}

/**
 * filter functions for Art, Bin and Subsystem columns
 * because they have a different structure than the other setfilter columns
 * @param colName string
 * @param col { values: unkonwn[] }
 * @returns string
 */
const customObjectSetFilterFunction = (
    colName: string,
    col: { values: Art[] | Bin[] | Subsystem[] },
    _isCaseSensitive: boolean,
    provider: ExtendedOdataProvider
) => {
    // if we try to filter for to many values the url will be too long and the request will fail
    if (col.values.length > 0 && col.values.length < MAX_FILTER_LENGTH) {
        return provider.odataOperator.inWithNullAndUrlEncode(
            colName,
            col.values.map(({ name }) => name)
        )
    }
    return ''
}

const customBooleanFilterFunction = (
    colName: string,
    col: { values: string[] },
    _isCaseSensitive: boolean,
    provider: ExtendedOdataProvider
) =>
    col.values && col.values.length > 0
        ? provider.odataOperator.inBooleanValues(colName, col.values)
        : false

/**
 * filter function for columns that have the columnType 'date'
 * @param colName string
 * @param col { dateFrom: string; dateTo: string; type: string; filterType: string }
 * @param _isCaseSensitive boolean
 * @param provider ExtendedOdataProvider
 * @returns string
 */
const customDateFilterFunction = (
    colName: string,
    col: { dateFrom: string; dateTo: string; type: string; filterType: string },
    _isCaseSensitive: boolean,
    provider: ExtendedOdataProvider
) => {
    const operator = provider.odataOperator
    const from = new Date(col.dateFrom)
    const to = new Date(col.dateTo)

    const format = (date: Date) =>
        date ? formatISO9075(date, { representation: 'date' }) : ''

    switch (col.type) {
        case 'blank':
            return operator.blank(colName, '', false, col.filterType)
        case 'notBlank':
            return operator.notBlank(colName, '', false, col.filterType)
        case 'inRange': {
            if (from.getTime() === to.getTime()) {
                return operator.equals(colName, `date(${format(from)})`)
            } else if (from.getTime() < to.getTime()) {
                return operator.inRange(
                    colName,
                    `date(${format(from)})`,
                    `date(${format(to)})`
                )
            }
            return operator.inRange(
                colName,
                `date(${format(to)})`,
                `date(${format(from)})`
            )
        }
        default:
            return operator[col.type](colName, `date(${format(from)})`)
    }
}

/**
 * filter function for columns that have the columnType 'number'
 * @param colName string
 * @param col { filterType: string; filterTo: string; filter: number; type: string }
 * @param _isCaseSensitive boolean
 * @param provider ExtendedOdataProvider
 * @returns string
 */
const customNumberFilterFunction = (
    colName: string,
    col: { filterType: string; filterTo: string; filter: number; type: string },
    _isCaseSensitive: boolean,
    provider: ExtendedOdataProvider
) => {
    if (col.type === 'blank') {
        return provider.odataOperator.blank(colName, '', false, col.filterType)
    }
    if (col.type === 'notBlank') {
        return provider.odataOperator.notBlank(
            colName,
            '',
            false,
            col.filterType
        )
    }
    return provider.odataOperator[col.type](colName, col.filter, col.filterTo)
}

/**
 * filter function for mediaBlobPath columns
 * @param colName string
 * @param col { values: string[] }
 * @returns string
 */
const mediaFilterFunction = (colName: string, col: { values: string[] }) => {
    // if both values are selected, return empty string
    if (col.values.length === 2) {
        return ''
    }
    if (col.values.includes(BLANK_FILTER)) {
        // eslint-disable-next-line max-len, quotes
        return "(mediaBlobPath eq null AND (images eq null OR images eq '') AND (sounds eq null OR sounds eq '') AND (videos eq null OR videos eq '') AND (HasCdpMedia eq null OR HasCdpMedia eq false))"
    }
    if (col.values.includes('Has Media')) {
        // eslint-disable-next-line quotes, max-len
        return "(mediaBlobPath ne null OR (images ne null AND images ne '') OR (sounds ne null AND sounds ne '') OR (videos ne null AND videos ne '') OR (HasCdpMedia ne null AND HasCdpMedia eq true))"
    }
    return ''
}

/**
 * filter function for the custom csv filter
 * @param colName string
 * @param col { value: string | { value: string } }
 * @returns string
 */
const csvFilterFunction = (
    colName: string,
    col: { values: string[]; ranges: string[] },
    _isCaseSensitive: boolean,
    provider: ExtendedOdataProvider
) => {
    const { values, ranges } = col

    if (!values && !ranges) return false

    return provider.odataOperator.csvFilter(colName, values, ranges)
}

/**
 * filter function for the custom structureWeek filter
 * @param colName string
 * @param col { values: string[] }
 * @returns string
 */
const StructureWeekFilterFunction = (
    colName: string,
    col: {
        values: string[]
        type: string
        structureWeekFrom: string
        structureWeekIn: string[]
        structureWeekTo: string
    },
    _isCaseSensitive: boolean,
    provider: ExtendedOdataProvider
) => {
    if (
        col.type === FilterModelEnum.In &&
        col.structureWeekIn &&
        col.structureWeekIn.length > 0
    ) {
        return provider.odataOperator.inWithNullAndUrlEncode(
            colName,
            col.structureWeekIn
        )
    }
    if (col.type === FilterModelEnum.Equals && col.structureWeekFrom) {
        return provider.odataOperator.equals(
            colName,
            `'${createYearWeekFilterString(new Date(col.structureWeekFrom))}'`
        )
    }
    if (col.type === FilterModelEnum.GreaterThan && col.structureWeekFrom) {
        return provider.odataOperator.greaterThan(
            colName,
            `'${createYearWeekFilterString(new Date(col.structureWeekFrom))}'`
        )
    }
    if (col.type === FilterModelEnum.LessThan && col.structureWeekFrom) {
        return provider.odataOperator.lessThan(
            colName,
            `'${createYearWeekFilterString(new Date(col.structureWeekFrom))}'`
        )
    }
    if (
        col.type === FilterModelEnum.InRange &&
        col.structureWeekFrom &&
        col.structureWeekTo
    ) {
        return provider.odataOperator.inRange(
            colName,
            `'${createYearWeekFilterString(new Date(col.structureWeekFrom))}'`,
            `'${createYearWeekFilterString(new Date(col.structureWeekTo))}'`
        )
    }
    return false
}

/**
 * array of filter functions with keys
 */
const filters = [
    {
        keys: ['art', 'bin', 'subSystem'],
        filter: customObjectSetFilterFunction,
    },
    {
        keys: BOOLEAN_COLUMNS,
        filter: customBooleanFilterFunction,
    },
    {
        keys: SET_COLUMNS,
        filter: customSetFilterFunction,
    },
    {
        keys: DATE_COLUMNS,
        filter: customDateFilterFunction,
    },
    {
        keys: NUMBER_COLUMNS,
        filter: customNumberFilterFunction,
    },
    {
        keys: MEDIA_COLUMNS,
        filter: mediaFilterFunction,
    },
    {
        keys: CSV_COLUMNS,
        filter: csvFilterFunction,
    },
    {
        keys: STRUCTUREWEEK_COLUMNS,
        filter: StructureWeekFilterFunction,
    },
]

/**
 * returns an object with the keys of the columns and the filter functions as values
 */
export const customFilters = filters.reduce<CustomFilter>(
    (accumulation, { keys, filter }) => {
        keys.forEach(key => {
            accumulation[key] = filter
        })
        return accumulation
    },
    {}
)
