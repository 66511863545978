import ActionsPanel from 'components/Feedbacks/actionPanel/ActionsPanel'
import Content from 'components/Layout/Content'
import FeedbackGrid from 'components/Feedbacks/FeedbackGrid'
import FilterButtons from 'components/SlidingRangeFilter/FilterButtons'
import Header from 'components/Layout/Header'
import RefreshTimer from 'components/RefreshTimer'
import { VehicleCar24Regular } from '@fluentui/react-icons'

const Home = () => {
    return (
        <>
            <Header
                title='Home'
                personalViewType='Trillion'
                icon={<VehicleCar24Regular />}
            />
            <ActionsPanel />
            <Content>
                <FeedbackGrid />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '8px',
                        padding: '0 5px',
                    }}
                >
                    <RefreshTimer />
                    <FilterButtons />
                </div>
            </Content>
        </>
    )
}

export default Home
