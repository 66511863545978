const STATE_NAME = 'trillion_state'

export const saveState = state => {
    try {
        const serialState = JSON.stringify(state)
        localStorage.setItem(STATE_NAME, serialState)
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
    }
}

export const loadState = () => {
    try {
        const serialState = localStorage.getItem(STATE_NAME)
        if (serialState === null) {
            return undefined
        }
        return JSON.parse(serialState)
    } catch (err) {
        return undefined
    }
}

export const clearState = () => {
    try {
        localStorage.removeItem(STATE_NAME)
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
    }
}
