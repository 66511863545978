import { Button, makeStyles, shorthands } from '@fluentui/react-components'
import { ReactNode, useState } from 'react'

import AppsPanel from './AppsPanel'
import { GridDots28Regular } from '@fluentui/react-icons'
import Menu from 'components/Layout/Menu'
import PersonalViewsMenu from 'components/PersonalViews/PVMenu'
import PreferencesPanel from 'components/PreferencesPanel'
import SupportButton from 'components/Support/SupportButton'
import TrillionLogo from 'components/UI/Logo/TrillionLogo'
import { selectDarkMode } from 'slices/preferenceSlice'
import { useAppSelector } from 'store'
import { useGrid } from 'contexts/GridContext'
import UserMenu from './UserMenu'

interface IHeader {
    title?: string
    icon?: JSX.Element
    personalViewType?: PersonalViewType
    children?: ReactNode
}

const useStyle = makeStyles({
    header: {
        display: 'flex',
        height: '60px',
        backgroundColor: '#007FA6',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...shorthands.padding('0', '5px'),
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        gridGap: '16px',
    },
    gap8: {
        display: 'flex',
        gridGap: '8px',
    },
})

const Header = ({ title, icon, personalViewType, children }: IHeader) => {
    const darkMode = useAppSelector(selectDarkMode)
    const classes = useStyle()

    const [isAppsDrawerOpen, setAppsDrawerOpen] = useState(false)

    const { gridRef } = useGrid()

    return (
        <div
            className={classes.header}
            style={{
                backgroundColor: darkMode ? '#000000' : '#007FA6',
            }}
        >
            <div className={classes.flex}>
                <Button
                    style={{
                        marginLeft: '10px',
                    }}
                    appearance='transparent'
                    icon={<GridDots28Regular color='white' />}
                    onClick={() => setAppsDrawerOpen(true)}
                />
                <TrillionLogo />
                <Menu title={title} icon={icon} />
            </div>

            <div className={classes.gap8}>
                {children}
                {personalViewType && gridRef.current && (
                    <PersonalViewsMenu viewType={personalViewType} />
                )}
                <PreferencesPanel />
                <SupportButton />
                <UserMenu />
            </div>

            <AppsPanel
                isOpen={isAppsDrawerOpen}
                toggleOpen={() => setAppsDrawerOpen(false)}
            />
        </div>
    )
}

export default Header
