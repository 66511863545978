import { Spinner } from '@fluentui/react-components'
import { useGetGroupMembersQuery } from 'api/groupMembers'
import Group from 'components/GroupMembers/Group'
import Content from 'components/Layout/Content'
import Header from 'components/Layout/Header'
import { useEffect, useState } from 'react'

const Members = () => {
    const { data: groupsAndMembers = [] } = useGetGroupMembersQuery()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (groupsAndMembers && loading) {
            setLoading(false)
        }
    }, [groupsAndMembers, loading])

    if (loading) {
        return <Spinner size='large' label='Loading Trillion Members ...' />
    }

    return (
        <>
            <Header title='Members' />

            <Content>
                {groupsAndMembers &&
                    groupsAndMembers.map((group, index) => (
                        <div key={index}>
                            <Group group={group} />
                        </div>
                    ))}
            </Content>
        </>
    )
}

export default Members
