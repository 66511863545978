import './causeRemarkManagementStyle.scss'

import { ColDef } from 'ag-grid-community'
import { useMemo, useRef } from 'react'

import AgGridContainer from 'components/Layout/AgGridContainer'
import { AgGridReact } from 'ag-grid-react'
import CauseRemarkActions from './cellRenderers/CauseRemarkActions'
import { getContextMenuItems } from 'helpers/dataGridHelper'

import { useGrid } from 'contexts/GridContext'

interface ICauseRemarkManagementProps {
    causeRemarks: CauseRemark[]
}
const CauseRemarkManagement = ({
    causeRemarks,
}: ICauseRemarkManagementProps) => {
    const { setGridRef } = useGrid()
    const gridRef = useRef<AgGridReact>(null)

    const customComparator = (a: string, b: string) => {
        return a.toLowerCase().localeCompare(b.toLowerCase())
    }

    const columnDefs = useMemo<ColDef[]>(
        () => [
            {
                checkboxSelection: true,
                colId: 'checkBoxSelect',
                lockPinned: true,
                lockPosition: true,
                maxWidth: 55,
                pinned: 'left',
                resizable: false,
                sortable: false,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                suppressFiltersToolPanel: true,
            },
            {
                field: 'name',
                flex: 1,
                sort: 'asc',
                comparator: customComparator,
            },
            {
                headerName: '',
                field: 'actions',
                suppressMenu: true,
                width: 100,
                flex: 0,
                editable: false,
                cellRenderer: CauseRemarkActions,
                cellRendererParams: {
                    causeRemarks,
                },
            },
        ],
        [causeRemarks]
    )

    const handleGridReady = () => {
        setGridRef(gridRef)
    }

    return (
        <AgGridContainer>
            <AgGridReact
                animateRows
                ref={gridRef}
                rowSelection='multiple'
                className='causeManagement'
                rowData={causeRemarks}
                columnDefs={columnDefs}
                enableRangeSelection
                getContextMenuItems={getContextMenuItems}
                onGridReady={handleGridReady}
            />
        </AgGridContainer>
    )
}

export default CauseRemarkManagement
