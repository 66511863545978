import {
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { acquireApiAccessToken } from 'authInstance'

const appendQueryStringParam = (
    args: string | FetchArgs,
    key: string,
    value: string
): string | FetchArgs => {
    let urlEnd = typeof args === 'string' ? args : args.url

    if (urlEnd.indexOf('?') < 0) urlEnd += '?'
    else urlEnd += '&'

    urlEnd += `${key}=${value}`

    return typeof args === 'string' ? urlEnd : { ...args, url: urlEnd }
}

const customQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const adjustedArgs = appendQueryStringParam(args, 'api-version', '2')

    return await fetchBaseQuery({
        baseUrl: process.env.REACT_APP_CODEV_BACKEND_API,
        prepareHeaders: async headers => {
            const accessToken = await acquireApiAccessToken()
            headers.set('Authorization', `Bearer ${accessToken}`)
            return headers
        },
    })(adjustedArgs, api, extraOptions)
}

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: customQuery,
    endpoints: () => ({}),
})

export const reflectApi = createApi({
    reducerPath: 'reflectApi',
    baseQuery: customQuery,
    endpoints: () => ({}),
})
