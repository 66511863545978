import { Button, Card, CardHeader, Slot } from '@fluentui/react-components'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
} from '@fluentui/react-components/unstable'
import { PORTAL_URL, REFLECT_URL, SHAREPOINT_URL } from 'constants/common'

import { Dismiss24Regular } from '@fluentui/react-icons'
import Portal from 'components/UI/AppIcons/Portal'
import Reflect from 'components/UI/AppIcons/Reflect'
import Sharepoint from 'components/UI/AppIcons/Sharepoint'
import { selectDarkMode } from 'slices/preferenceSlice'
import { useAppSelector } from 'store'

interface IAppItem {
    link: string
    label: string
    icon: Slot<'div', 'img'>
}

interface IAppsPanelProps {
    isOpen: boolean
    toggleOpen: () => void
}

const AppsPanel = ({ isOpen, toggleOpen }: IAppsPanelProps) => {
    const darkMode = useAppSelector(selectDarkMode)

    const appItems: IAppItem[] = [
        { icon: <Reflect />, link: REFLECT_URL, label: 'Reflect' },
        { icon: <Portal />, link: PORTAL_URL, label: 'Codev Driver Portal' },
        {
            icon: <Sharepoint />,
            link: SHAREPOINT_URL,
            label: 'CoDev Sharepoint',
        },
    ]

    return (
        <Drawer
            open={isOpen}
            onOpenChange={() => toggleOpen()}
            style={{ backgroundColor: darkMode ? '#000' : '#FFF' }}
        >
            <DrawerHeader>
                <DrawerHeaderTitle
                    style={{
                        color: darkMode ? '#FFF' : '#000',
                    }}
                    action={
                        <Button
                            appearance='subtle'
                            icon={
                                <Dismiss24Regular
                                    color={darkMode ? '#FFF' : '#000'}
                                />
                            }
                            onClick={() => toggleOpen()}
                        />
                    }
                >
                    Apps
                </DrawerHeaderTitle>
            </DrawerHeader>

            <DrawerBody style={{ backgroundColor: darkMode ? '#000' : '#FFF' }}>
                {appItems.map(item => (
                    <Card
                        onClick={() => window.open(item.link, '_blank')}
                        style={{
                            margin: '10px',
                            backgroundColor: darkMode ? '#000' : '#FFF',
                        }}
                        key={item.link}
                    >
                        <CardHeader
                            image={item.icon}
                            header={item.label}
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                color: darkMode ? '#FFF' : '#000',
                            }}
                        />
                    </Card>
                ))}
            </DrawerBody>
        </Drawer>
    )
}

export default AppsPanel
