import { Feedback } from 'types/feedback'

export const HIDDEN_COLUMNS: Array<keyof Feedback> = [
    'artId',
    'artEntity',
    'binId',
    'binEntity',
    'causeRemarkId',
    'causeRemarkEntity',
    'frequencyId',
    'images',
    'solutionId',
    'solutionEntity',
    'severityId',
    'sounds',
    'subSystemId',
    'subSystemEntity',
    'vehicleId',
    'videos',
    'hasCdpMedia',

    // TODO: remove this when we are ready for CDP v2
    'sourceId',
    'reportSource',

    // TODO: remove this when the Codev data team implements the translation feature
    'isTranslated',
]

export const EDITABLE_COLUMNS_CMQ: Array<keyof Feedback> = [
    'art',
    'bin',
    'causeRemark',
    'comment',
    'noShow',
    'solution',
    'subSystem',
    'swHw',
]

export const EDITABLE_COLUMNS_CODEV: Array<keyof Feedback> = [
    'title',
    'description',
]

export const NONE_BLANK_COLUMNS: Array<keyof Feedback> = [
    'noShow',
    'isRepeated',
    'swHw',
    'isTranslated',
]

// these are most of the columns with the columnType 'setfilter'
export const SET_COLUMNS: Array<keyof Feedback> = [
    'assemblyPlant',
    'baselineVersion',
    'carCategory',
    'carUsage',
    'causeRemark',
    'cdbMarket',
    'color',
    'colorCode',
    'engineDescription',
    'equipmentType',
    'frequency',
    'fuelType',
    'gearboxCode',
    'gearboxDescription',
    'infotainmentType',
    'marketingCode',
    'model',
    'modelYear',
    'otaVersion',
    'platform',
    'severity',
    'solution',
    'sssVersion',
    'swHw',
    'swStructureWeek',
    'upholsteryCode',
    'vehicleStatus',
    'weather',
    'conversationStatus',
]

export const MEDIA_COLUMNS: Array<keyof Feedback> = ['mediaBlobPath']
export const CSV_COLUMNS: Array<keyof Feedback> = ['id', 'vin']
export const STRUCTUREWEEK_COLUMNS: Array<keyof Feedback> = ['structureWeek']

// these are all the columns with the columnType 'date'
export const DATE_COLUMNS: Array<keyof Feedback> = [
    'carOnlineDate',
    'customDelivered',
    'customReturned',
    'dateInitiated',
    'dateSubmitted',
    'dateUploaded',
    'dateUploaded',
    'driverModifiedDate',
    'factoryComplete',
    'insertedDate',
    'installationDate',
    'reportedDateAtEvent',
    'reportedDateAtEvent',
    'uninstallationDate',
    'scheduledDelivery',
    'scheduledReturn',
    'vehicleDelivered',
    'vehicleReturned',
]

// these are all the columns with the columnType 'number'
export const NUMBER_COLUMNS: Array<keyof Feedback> = ['reportedMileageAtEvent']

// these are all the columns with the columnType 'boolean'
export const BOOLEAN_COLUMNS: Array<keyof Feedback> = [
    'workshopAssistanceRequired',
    'reportedPreviously',
    'isTranslated',
    'isModified',
    'noShow',
]
