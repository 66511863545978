import HttpRequestHelper from './HttpRequestHelper'
import { acquireApiAccessToken } from 'authInstance'
import axios from 'axios'
import consoleErrorData from 'helpers/consoleErrorData'

const reflectApiAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_CODEV_BACKEND_API,
})

/**
 * Request interceptor for API calls
 */
reflectApiAxiosInstance.interceptors.request.use(
    async config => {
        const accessToken = await acquireApiAccessToken()
        config.headers.set('Authorization', `Bearer ${accessToken}`)
        config.params = {
            ...config.params,
            'api-version': '2',
        }

        return config
    },
    error => {
        Promise.reject(error)
    }
)

reflectApiAxiosInstance.interceptors.response.use(response => {
    // This is a good place to do something with the response
    return response
})

reflectApiAxiosInstance.interceptors.response.use(
    response => response,
    error => {
        HttpRequestHelper.GetGlobalErrorMessage(
            error.response.status,
            `${error.response.config.baseURL}${error.response.config.url}`,
            error.response.data
        )
        consoleErrorData(error)

        return Promise.reject(error)
    }
)

export default reflectApiAxiosInstance
