import {
    makeStyles,
    mergeClasses,
    shorthands,
} from '@fluentui/react-components'

import ActionsMenuItems from './ActionsMenuItems'
import { IActionsMenu } from 'types/ActionsMenu'
import Restricted from 'components/Restricted'
import { selectDarkMode } from 'slices/preferenceSlice'
import { useAppSelector } from 'store'

interface IActionsMenuProps {
    items?: IActionsMenu[]
}

const useStyles = makeStyles({
    root: {
        height: '40px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '10px',
        justifyContent: 'space-between',
        ...shorthands.gap('8px'),
    },
    buttons: { display: 'flex' },
    separator: {
        height: '5px',
        backgroundColor: '#ededed',
    },
    darkStyles: {
        backgroundColor: '#000000',
    },
})

const ActionsMenu = ({ items }: IActionsMenuProps) => {
    const darkMode = useAppSelector(selectDarkMode)
    const classes = useStyles()

    const actionMenuItem = (item: IActionsMenu) => {
        return item.restricted ? (
            <Restricted key={item.label} to={item.restricted}>
                <ActionsMenuItems item={item} />
            </Restricted>
        ) : (
            <ActionsMenuItems key={item.label} item={item} />
        )
    }

    return (
        <>
            <div
                data-cy='actions-menu'
                className={
                    darkMode
                        ? mergeClasses(classes.root, classes.darkStyles)
                        : classes.root
                }
            >
                {items.map(actionMenuItem)}
            </div>
        </>
    )
}

export default ActionsMenu
