enum ConversationStatusEnum {
    Created = 'Created',
    RespondedByDriver = 'RespondedByDriver',
    RespondedByTrillion = 'RespondedByTrillion',
    ReadByDriver = 'ReadByDriver',
    ReadByTrillion = 'ReadByTrillion',
    Ended = 'Ended',
}

export default ConversationStatusEnum
