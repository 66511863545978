import getISOWeek from 'date-fns/getISOWeek'
import getISOWeekYear from 'date-fns/getISOWeekYear'

const isoWeekString = (isoWeek: number) =>
    isoWeek <= 9 ? `0${isoWeek}` : isoWeek

const createYearWeekFilterString = (date: Date, readable = false) => {
    const useDate = new Date(date)
    const isoWeek = getISOWeek(useDate)
    const isoYear = getISOWeekYear(useDate)
    return readable
        ? `Y${isoYear} W${isoWeekString(isoWeek)}`
        : `${isoYear}${isoWeekString(isoWeek)}`
}

export default createYearWeekFilterString
