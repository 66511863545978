import { Button, Text } from '@fluentui/react-components'

import { DeleteRegular } from '@fluentui/react-icons'
import DialogWithIconButton from 'components/UI/DialogWithIconButton'
import { GridApi } from 'ag-grid-community'
import { useDeleteSubsystemsMutation } from 'api/category'

interface ISubsystemDeleteModalProps {
    data: Subsystem
    api: GridApi
}

const SubsystemDeleteModal = ({ data, api }: ISubsystemDeleteModalProps) => {
    const [deleteSubsystem, { isLoading }] = useDeleteSubsystemsMutation()

    const handleClick = async (toggleOpen: () => void) => {
        await deleteSubsystem(data.id)

        toggleOpen()
        api.refreshCells()
    }

    return (
        <DialogWithIconButton
            icon={<DeleteRegular />}
            dialogTitle='Delete this?'
            dialogContent={() => (
                <Text>
                    Are you sure you want to delete this Subsystem? This action
                    cannot be undone.
                </Text>
            )}
            actionRender={toggleOpen => (
                <Button
                    appearance='primary'
                    disabled={isLoading}
                    onClick={() => handleClick(toggleOpen)}
                >
                    Confirm
                </Button>
            )}
        />
    )
}

export default SubsystemDeleteModal
