import {
    CellValueChangedEvent,
    FilterChangedEvent,
    FirstDataRenderedEvent,
    GridReadyEvent,
} from 'ag-grid-community'
import {
    ExtendedOdataProvider,
    getContextMenuItemsWithSelectionFilter,
} from 'helpers/dataGridHelper'
import { Feedback, IFeedback } from 'types/feedback'
import { permissions, selectUser } from 'slices/authSlice'
import { setLastOdataQuery, setRowCount } from 'slices/gridSlice'
import { useAppDispatch, useAppSelector } from 'store'
import { useEffect, useRef } from 'react'
import {
    useGetArtsQuery,
    useGetBinsQuery,
    useGetCauseRemarksQuery,
    useGetSolutionsQuery,
    useGetSubsystemsQuery,
} from 'api/category'

import AgGridContainer from 'components/Layout/AgGridContainer'
import { AgGridReact } from 'ag-grid-react'
import GridHasFilterMessage from 'components/Feedbacks/statusBar/GridHasFilterMessage'
import Loader from 'components/UI/Loader'
import RowCounter from 'components/Feedbacks/statusBar/RowCounter'
import { SetFilter } from 'ag-grid-enterprise'
import { customFilters } from 'helpers/customFilters'
import { fetchOdataAPI } from 'api/genericFunctions'
import { selectTextDisplaySetting } from 'slices/preferenceSlice'
import useFeedbackGrid from 'hooks/useFeedbackGrid'
import { useGrid } from 'contexts/GridContext'
import useGridFilterUrl from 'hooks/useGridFilterUrl'
import { usePatchFeedbackMutation } from 'api/feedback'

const FeedbackGrid = () => {
    const gridRef = useRef<AgGridReact>(null)

    const dispatch = useAppDispatch()

    const { setGridRef } = useGrid()
    const [pushFiltersToHistory, setFiltersFromHistory] = useGridFilterUrl()

    const textDisplaySetting = useAppSelector(selectTextDisplaySetting)
    const textDisplaySettingRef = useRef(textDisplaySetting)

    const { data: solutions = [], isSuccess: solutionsLoaded } =
        useGetSolutionsQuery()
    const { data: arts, isSuccess: artsLoaded } = useGetArtsQuery()
    const { data: bins, isSuccess: binsLoaded } = useGetBinsQuery()
    const { data: subsystems, isSuccess: subsystemsLoaded } =
        useGetSubsystemsQuery()
    const { data: causeRemarks = [], isSuccess: causeRemarksLoaded } =
        useGetCauseRemarksQuery()

    const { columnDefs, defaultColDef, sideBar } = useFeedbackGrid()

    const [patchFeedback] = usePatchFeedbackMutation()

    const user = useAppSelector(selectUser)
    const hasPermission = useAppSelector(permissions).noShow

    const onGridReady = (params: GridReadyEvent) => {
        const gridApi = params.api

        setGridRef(gridRef)

        gridApi.setServerSideDatasource(
            new ExtendedOdataProvider({
                callApi: async options => {
                    let response: { count: number; data: Feedback[] }
                    // order by latest first if the user hasn't made any sorting
                    if (!options.includes('&$orderby=')) {
                        options = options + '&$orderby=dateSubmitted desc'
                    }
                    if (user.view === 'CoDev') {
                        response = await fetchOdataAPI<IFeedback>(
                            'odata/Feedback',
                            options
                        )
                    } else if (user.view === 'CMQ') {
                        response = await fetchOdataAPI<IFeedback>(
                            'odata/CMQSummaryViews',
                            options
                        )
                    } else if (user.view === 'America') {
                        response = await fetchOdataAPI<IFeedback>(
                            'odata/AmericaView',
                            options
                        )
                    } else if (user.view === 'China') {
                        response = await fetchOdataAPI<IFeedback>(
                            'odata/ChinaView',
                            options
                        )
                    }

                    dispatch(setRowCount(response.count))
                    dispatch(setLastOdataQuery(options))

                    return {
                        '@odata.count': response.count,
                        value: response.data,
                    }
                },
                customFilters: customFilters,
                beforeRequest: query => {
                    query['count'] = true

                    if (!hasPermission) {
                        query['filter'] = query['filter'] || []
                        query['filter'].push('NoShow eq false')
                    }
                },
            })
        )
    }

    useEffect(() => {
        if (textDisplaySetting !== textDisplaySettingRef.current) {
            gridRef?.current?.api?.refreshServerSide({ purge: true })
            textDisplaySettingRef.current = textDisplaySetting
        }
    }, [textDisplaySetting])

    const onCellValueChanged = ({
        colDef,
        data,
        newValue,
        node,
        oldValue,
    }: CellValueChangedEvent<Feedback>) => {
        const cascadeFields = []
        let path = colDef.field,
            value = newValue

        switch (colDef.field) {
            case 'solution':
                path = 'SolutionId'
                value =
                    solutions.filter(s => s.name === newValue)[0]?.id || null
                if (newValue !== oldValue) {
                    cascadeFields.push({
                        OperationType: 'Remove',
                        path: 'artId',
                    })
                    cascadeFields.push({
                        OperationType: 'Remove',
                        path: 'binId',
                    })
                    cascadeFields.push({
                        OperationType: 'Remove',
                        path: 'causeRemarkId',
                    })
                    cascadeFields.push({
                        OperationType: 'Remove',
                        path: 'subSystemId',
                    })
                    data.solutionId = value
                    node.setDataValue('art', null)
                    node.setDataValue('bin', null)
                    node.setDataValue('causeRemark', null)
                    node.setDataValue('subSystem', null)
                }
                break
            case 'art':
                if (newValue === null) {
                    // Prevent api calls during cascade
                    return
                }
                path = 'artId'
                value =
                    arts.filter(
                        a =>
                            a.name === newValue &&
                            a.solutionId === data.solutionId
                    )[0]?.id || null
                if (newValue !== oldValue) {
                    cascadeFields.push({
                        OperationType: 'Remove',
                        path: 'binId',
                    })
                    cascadeFields.push({
                        OperationType: 'Remove',
                        path: 'causeRemarkId',
                    })
                    cascadeFields.push({
                        OperationType: 'Remove',
                        path: 'subSystemId',
                    })
                    data.artId = value
                    node.setDataValue('bin', null)
                    node.setDataValue('causeRemark', null)
                    node.setDataValue('subSystem', null)
                }
                break
            case 'bin':
                if (newValue === null) {
                    // Prevent api calls during cascade
                    return
                }
                path = 'binId'
                value =
                    bins.filter(
                        b => b.name === newValue && b.artId === data.artId
                    )[0]?.id || null

                if (newValue !== oldValue) {
                    cascadeFields.push({
                        OperationType: 'Remove',
                        path: 'causeRemarkId',
                    })
                    cascadeFields.push({
                        OperationType: 'Remove',
                        path: 'subSystemId',
                    })
                    data.binId = value
                    node.setDataValue('causeRemark', null)
                    node.setDataValue('subSystem', null)
                }
                break
            case 'subSystem':
                if (newValue === null) {
                    // Prevent api calls during cascade
                    return
                }
                path = 'subSystemId'
                value =
                    subsystems.filter(
                        s => s.name === newValue && s.binId === data.binId
                    )[0]?.id || null
                if (newValue !== oldValue) {
                    data.subSystemId = value
                }
                break
            case 'causeRemark':
                if (newValue === null) {
                    // Prevent api calls during cascade
                    return
                }
                path = 'causeRemarkId'
                value =
                    causeRemarks.filter(c => c.name === newValue)[0]?.id || null
                break
            case 'acceptSolution':
            case 'reportedPreviously':
                value = newValue === 'true'
                break
            default:
                break
        }

        patchFeedback({
            id: data.id,
            data: cascadeFields.concat([
                {
                    operationType: value === null ? 'Remove' : 'Add',
                    path: path,
                    value: value,
                },
            ]),
        })
    }

    const refreshFilters = (
        event: FilterChangedEvent,
        filterKeys: string[]
    ) => {
        filterKeys.forEach(key => {
            const filter = event.api.getFilterInstance<SetFilter<unknown>>(key)

            if (typeof filter?.refreshFilterValues === 'function') {
                filter.refreshFilterValues()
            }
        })
    }

    const handelFilterChange = (event: FilterChangedEvent) => {
        const colIds = event.columns.map(c => c.getColId())

        if (colIds.includes('solution')) {
            refreshFilters(event, ['art', 'bin', 'subSystem'])
        }
        if (colIds.includes('art')) {
            refreshFilters(event, ['bin', 'subSystem'])
        }
        if (colIds.includes('bin')) {
            refreshFilters(event, ['subSystem'])
        }

        // Refresh the header to show which columns have active filters
        event.api.refreshHeader()

        // clear the selected rows on filter change
        event.api.deselectAll()

        // push the filters to the history
        pushFiltersToHistory(event.api)
    }

    const onFirstDataRendered = (event: FirstDataRenderedEvent<unknown>) => {
        setFiltersFromHistory(event.api)
    }

    if (
        ![
            solutionsLoaded,
            artsLoaded,
            binsLoaded,
            causeRemarksLoaded,
            subsystemsLoaded,
        ].every(loaded => loaded)
    ) {
        return <Loader text='Loading...' />
    }

    return (
        <AgGridContainer offsetHeight={50}>
            <AgGridReact<Feedback>
                ref={gridRef}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                enableBrowserTooltips
                enableRangeSelection
                getContextMenuItems={getContextMenuItemsWithSelectionFilter}
                onFilterChanged={handelFilterChange}
                onCellValueChanged={onCellValueChanged}
                getRowId={params => params.data.id.toString()}
                onFirstDataRendered={onFirstDataRendered}
                animateRows
                tooltipShowDelay={0}
                onGridReady={onGridReady}
                rowModelType='serverSide'
                rowSelection='multiple'
                maxConcurrentDatasourceRequests={1}
                sideBar={sideBar}
                suppressCsvExport
                suppressExcelExport
                serverSideInfiniteScroll
                blockLoadDebounceMillis={250}
                statusBar={{
                    statusPanels: [
                        {
                            statusPanel: RowCounter,
                            align: 'left',
                        },
                        {
                            statusPanel: 'agSelectedRowCountComponent',
                            align: 'left',
                        },
                        {
                            statusPanel: GridHasFilterMessage,
                            align: 'right',
                        },
                    ],
                }}
            />
        </AgGridContainer>
    )
}

export default FeedbackGrid
