/**
 *This function takes two parameters: filename, which is the name of the file, and rows,
 *which is an array of arrays, representing the rows and columns of the data.
 *The function first processes each row by converting it into a CSV-formatted string,
 *and then creates a Blob object containing the string.
 *If the browser supports the navigator.msSaveBlob method, the function uses that to trigger a download,
 *otherwise it creates an anchor element with a download attribute and simulates a click on that element to trigger the download
 */
export const exportArrayToCsv = (filename, rows) => {
    const processRow = function (row) {
        let finalVal = ''
        for (const j in row) {
            if (!Object.hasOwn(row, j)) return

            let innerValue = row[j] === null ? '' : row[j].toString()
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString()
            }
            let result = innerValue.replace(/"/g, '""')
            if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"'
            finalVal += result + ';'
        }
        return finalVal + '\n'
    }

    let csvFile = ''
    for (let i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i])
    }

    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
    if (navigator['msSaveBlob']) {
        // IE 10+
        navigator['msSaveBlob'](blob, filename)
    } else {
        const link = document.createElement('a')
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', filename)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }
}
