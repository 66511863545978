/* eslint-disable max-len */

import { makeStyles } from '@fluentui/react-components'

const useStyles = makeStyles({
    svg: {
        height: '50px',
        width: '50px',
    },
})

const Portal = () => {
    const classes = useStyles()
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 128 123.65'
            className={classes.svg}
        >
            <path
                fill='#f15a24'
                strokeWidth={0}
                d='m56.56,97.83c0-25.79,0-51.59,0-77.38.02-.08.04-.15.05-.23.12-1.49.55-2.89,1.34-4.15,2.1-3.39,5.21-5.19,9.17-5.26,5.88-.1,11.76-.03,17.64-.03,5.79,0,11.58,0,17.37,0,1.95,0,3.79.49,5.46,1.5,3.37,2.04,5.2,5.01,5.21,8.99.02,5.36,0,10.71,0,16.07,0,19.88,0,39.77,0,59.65,0,.98-.08,1.95-.37,2.89-1.25,3.98-4.01,6.35-8.01,7.35-.55.14-1.13.19-1.69.28h-36.04c-.07-.01-.14-.04-.21-.04-1.26-.1-2.48-.39-3.63-.92-3.12-1.44-5.2-3.76-6.03-7.13-.13-.52-.18-1.05-.26-1.58Zm51.99-38.82c0-10,0-20.01,0-30.01,0-.12,0-.23,0-.35-.01-.66-.37-1.2-1-1.42-.27-.09-.58-.12-.87-.12-11.06,0-22.12,0-33.18,0-3.65,0-7.31,0-10.96,0-1.07,0-1.72.65-1.72,1.7,0,20.13,0,40.27,0,60.4,0,1.03.66,1.69,1.7,1.69,14.78,0,29.55,0,44.33,0,.13,0,.27,0,.4-.03.83-.17,1.3-.77,1.3-1.69,0-10.06,0-20.11,0-30.17Zm-19.02,40.15c0-2.68-2.16-4.82-4.84-4.82-2.69,0-4.86,2.17-4.85,4.85.01,2.67,2.2,4.82,4.89,4.82,2.64,0,4.81-2.19,4.8-4.85Zm-4.86-82.11c-2.38,0-4.76,0-7.13,0-.15,0-.31,0-.46.02-.48.07-.85.43-.87.85-.02.43.29.81.78.92.1.02.21.04.32.04,4.92,0,9.84,0,14.75,0,.24,0,.52-.1.73-.23.52-.34.5-1.01,0-1.38-.26-.19-.56-.22-.87-.22-2.41,0-4.83,0-7.24,0Z'
            />
            <path
                fill='#f7931e'
                strokeWidth={0}
                d='m89.53,99.16c0-2.68-2.16-4.82-4.84-4.82-2.69,0-4.86,2.17-4.85,4.85.01,2.67,2.2,4.82,4.89,4.82,2.64,0,4.81-2.19,4.8-4.85Z'
            />
            <path
                fill='#f7931e'
                strokeWidth={0}
                d='m84.67,17.05c-2.38,0-4.76,0-7.13,0-.15,0-.31,0-.46.02-.48.07-.85.43-.87.85-.02.43.29.81.78.92.1.02.21.04.32.04,4.92,0,9.84,0,14.75,0,.24,0,.52-.1.73-.23.52-.34.5-1.01,0-1.38-.26-.19-.56-.22-.87-.22-2.41,0-4.83,0-7.24,0Z'
            />
            <path
                fill='#f7931e'
                strokeWidth={0}
                d='m108.55,29c0-.12,0-.23,0-.35-.01-.66-.37-1.2-1-1.42-.27-.09-.58-.12-.87-.12-11.06,0-22.12,0-33.18,0-3.65,0-7.31,0-10.96,0-1.07,0-1.72.65-1.72,1.7,0,3.43,0,6.85,0,10.28h47.73v-10.08Z'
            />
            <path
                fill='#f7cb97'
                strokeWidth={0}
                d='m60.82,89.21c0,1.03.66,1.69,1.7,1.69,14.78,0,29.55,0,44.33,0,.13,0,.27,0,.4-.03.83-.17,1.3-.77,1.3-1.69,0-4.79,0-9.58,0-14.38h-47.73c0,4.8,0,9.6,0,14.4Z'
            />
            <path
                fill='#f7ae59'
                strokeWidth={0}
                d='m108.55,57.89h-47.73c0,5.64,0,11.28,0,16.92h47.73c0-5.27,0-10.53,0-15.8v-1.13Z'
            />
            <path
                fill='#f79e36'
                strokeWidth={0}
                d='m108.55,39.09h-47.73v18.8h47.73c0-6.27,0-12.53,0-18.8Z'
            />
            <path
                fill='#c64646'
                opacity={0.38}
                strokeWidth={0}
                d='m72.91,26.64h-16.41v70.97h17.15c3.21,0,5.82-2.65,5.82-5.91v-59.15c0-3.27-2.6-5.91-6.55-5.91Z'
            />
            <path
                fill='#824b2f'
                opacity={0.76}
                strokeWidth={0}
                d='m68.52,31.42h-11.96v61.42h12.72c3.31,0,6-2.29,6-5.12v-51.19c0-2.83-2.69-5.12-6.76-5.12Z'
            />
            <path
                fill='#f15a24'
                strokeWidth={0}
                d='m20.01,32.91h47.9c3.25,0,5.4,2.18,5.4,4.87v48.71c0,2.69-2.14,4.87-4.79,4.87H20.61c-2.64,0-4.79-2.18-4.79-4.87v-48.71c0-2.69,2.14-4.87,4.18-4.87Z'
            />
            <text
                fill='#fff'
                transform='translate(27.37 84.14)'
                fontFamily="SegoeUI-Bold, 'Segoe UI'"
                fontSize='57.63px'
                fontWeight={700}
            >
                <tspan x='0' y='0'>
                    P
                </tspan>
            </text>
        </svg>
    )
}

export default Portal
