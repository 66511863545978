import { DATETIME_FORMAT } from 'constants/common'
import { IMessage } from 'types/conversations'
import { Text } from '@fluentui/react-components'
import { format } from 'date-fns'
import { useMessageStyles } from 'components/styles/messages'

interface IMessageItemProps {
    message: IMessage
}

const MessageItem = ({ message }: IMessageItemProps) => {
    const {
        incoming,
        incomingInner,
        outgoing,
        outgoingInner,
        twoWayComMedia,
        twoWayComMediaInner,
        textRow,
    } = useMessageStyles()

    const is2WayComMedia = message.message.includes('Driver has uploaded')

    return (
        <div
            className={
                message.isUser
                    ? is2WayComMedia
                        ? twoWayComMedia
                        : incoming
                    : outgoing
            }
        >
            <div className={textRow} style={{ marginBottom: '4px' }}>
                <Text>{message.isUser ? 'User' : message.cdsid}</Text>
                <Text>
                    {format(new Date(message.createdOn), DATETIME_FORMAT)}
                </Text>
            </div>
            <div
                className={
                    message.isUser
                        ? is2WayComMedia
                            ? twoWayComMediaInner
                            : incomingInner
                        : outgoingInner
                }
            >
                <Text>{message.message}</Text>
            </div>
        </div>
    )
}

export default MessageItem
