import 'react-toastify/dist/ReactToastify.css'

import {
    FluentProvider,
    teamsDarkTheme,
    teamsLightTheme,
} from '@fluentui/react-components'

import EnvironmentBar from 'components/EnvironmentBar'
import { GridContextProvider } from 'contexts/GridContext'
import Messages from 'components/UI/Messages'
import { Outlet } from 'react-router'
import { ToastContainer } from 'react-toastify'
import { selectDarkMode } from 'slices/preferenceSlice'
import { useAppSelector } from 'store'

const Layout = () => {
    const darkMode = useAppSelector(selectDarkMode)

    return (
        <FluentProvider theme={darkMode ? teamsDarkTheme : teamsLightTheme}>
            <GridContextProvider>
                <EnvironmentBar />
                <Outlet />

                <Messages />
                <ToastContainer
                    position='bottom-center'
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    style={{ boxShadow: 'none' }}
                />
            </GridContextProvider>
        </FluentProvider>
    )
}

export default Layout
