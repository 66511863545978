import { Button, makeStyles, shorthands } from '@fluentui/react-components'

const useStyle = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh',
        backgroundImage:
            'linear-gradient(180deg, #f2f3ee 0%, #f2f3ee 50%, #A7C5C5 50%, #A7C5C5 100%)',
    },
    header: {
        fontSize: '15rem',
        fontWeight: 700,
        ...shorthands.margin(0, 0, '1rem'),
        lineHeight: 1,
        color: '#A7C5C5',
    },
    subheader: {
        fontSize: '3rem',
        fontWeight: 400,
        lineHeight: 1,
        ...shorthands.margin(0, 0, '1rem'),
        color: '#f2f3ee',
    },
    text: {
        fontSize: '1.50rem',
        fontWeight: 400,
        lineHeight: 1,
        ...shorthands.margin(0),
        color: '#f2f3ee',
        maxWidth: '40%',
    },
    button: {
        marginTop: '2rem',
        textTransform: 'uppercase',
        ...shorthands.borderColor('#f2f3ee'),
        color: '#f2f3ee',
    },
})

const NoViews = () => {
    const classes = useStyle()

    return (
        <div className={classes.root}>
            <h1 className={classes.header}>Hmm.</h1>
            <p className={classes.subheader}>
                It seems like you don&apos;t have any views!
            </p>
            <p className={classes.text}>
                Please contact your administrator for more information regarding
                your account. Thank you!
            </p>
            <Button
                appearance='outline'
                className={classes.button}
                onClick={() => {
                    window.open(
                        // eslint-disable-next-line max-len
                        'https://volvocars.service-now.com/now/nav/ui/classic/params/target/kb_view.do%3Fsys_kb_id%3Dccbc2771478de91446d20c92e36d43cd%26',
                        '_blank',
                        'noopener,noreferrer'
                    )
                }}
            >
                Support
            </Button>
        </div>
    )
}

export default NoViews
