import { BLANK_FILTER, DELETED_FILTER } from 'constants/common'

import ColumnType from 'constants/columnTypeEnum'
import { GetContextMenuItemsParams } from 'ag-grid-community'
import OdataProvider from 'ag-grid-odata'
import qs from 'qs'

export class ExtendedOdataProvider extends OdataProvider {
    odataOperator = {
        ...this.odataOperator,
        csvFilter: (col: string, values: string[], ranges: string[]) => {
            let filter = '('
            if (values && values.length > 0) {
                filter += `${col} in (${values.map(x => `'${x}'`).join(',')})`
            }
            if (ranges && ranges.length > 0) {
                if (values && values.length > 0) {
                    filter += ' or '
                }
                filter += ranges
                    .map(r => {
                        const rv = r.split('-').map(x => x.trim())
                        if (rv.some(x => !x.length)) {
                            return null
                        }
                        return `(${col} ge ${rv[0]} and ${col} le ${rv[1]})`
                    })
                    .filter(Boolean)
                    .join(' or ')
            }
            filter += ')'
            return filter
        },
        blank: (
            col: string,
            _value: string,
            _isCaseSensitive: boolean,
            type = 'string'
        ) => {
            if (type === 'string') {
                return `(${col} eq null or ${col} eq '')`
            } else if (type === 'number' || type === 'date') {
                return `(${col} eq null)`
            }
            return ''
        },
        notBlank: (
            col: string,
            _value: string,
            _isCaseSensitive: boolean,
            type = 'string'
        ) => {
            if (type === 'string') {
                return `(${col} ne null and ${col} ne '')`
            } else if (type === 'number' || type === 'date') {
                return `(${col} ne null)`
            }
            return ''
        },
        inWithNullAndUrlEncode: (col: string, values: string[]) => {
            const filter = [
                `${col} in (${values
                    .filter(x => x !== DELETED_FILTER)
                    .map(x =>
                        x !== null && x !== BLANK_FILTER
                            ? `'${encodeURIComponent(x)}'`
                            : // eslint-disable-next-line quotes
                              "null, ' '"
                    )
                    .join()})`,
            ]
            if (values.includes(DELETED_FILTER)) {
                filter.push(`(${col}Entity/IsDeleted eq true)`)
            }
            return `(${filter.join(' or ')})`
        },
        inBooleanValues: (col: string, values: string[]) => {
            return `${col} in (${values
                .map(x =>
                    x !== null && x !== BLANK_FILTER
                        ? `${encodeURIComponent(x)}`
                        : 'null'
                )
                .join()})`
        },
    }
    caseSensitiveColumns?: string[] = ['swHw']
}

const textFilterOptions = [
    'contains',
    'notContains',
    'equals',
    'notEqual',
    'startsWith',
    'endsWith',
    {
        displayKey: 'blanks',
        displayName: 'Blanks',
        hideFilterInput: true,
        predicate: inputValue => inputValue === undefined,
    },
]

const numberFilterOptions = [
    'equals',
    'notEqual',
    'lessThan',
    'lessThanOrEqual',
    'greaterThan',
    'greaterThanOrEqual',
    'inRange',
]

export const getFilterOptions = (type?: string) => {
    switch (type) {
        case ColumnType.Text:
        case ColumnType.BigText:
            return textFilterOptions
        case ColumnType.Number:
            return numberFilterOptions
        default:
            return undefined
    }
}

/**
 * Built In Menu Items
 * The following is a list of all the default built in menu items with the rules about when they are shown.
 *
 ** autoSizeAll: Auto-size all columns. Not shown by default.
 ** expandAll: When set, it's only shown if grouping by at least one column. Not shown by default.
 ** contractAll: Collapse all groups. When set, it's only shown if grouping by at least one column. Not shown by default.
 ** copy: Copy selected value to clipboard. Shown by default.
 ** copyWithHeaders: Copy selected value to clipboard with headers. Shown by default.
 ** copyWithGroupHeaders: Copy selected value to clipboard with headers and header groups. Shown by default.
 ** paste: Always disabled (see note in clipboard section). Always disabled. Shown by default.
 ** resetColumns: Reset all columns. Not shown by default.
 ** export: Export sub menu (containing csvExport and excelExport). Shown by default.
 ** csvExport: Export to CSV using all default export values. Shown by default.
 ** excelExport: Export to Excel (.xlsx) using all default export values. Shown by default.
 ** chartRange: Chart a range of selected cells. Only shown if charting is enabled.
 */
export const getContextMenuItems = () => ['copy', 'copyWithHeaders']

export const getContextMenuItemsWithSelectionFilter = ({
    api,
}: GetContextMenuItemsParams) => {
    const selectedRows = api.getSelectedRows()
    return [
        selectedRows.length > 0 && {
            name: 'Filter by selection',
            icon: '<span class="ag-icon ag-icon-filter" role="presentation"></span>',
            tooltip: 'Will filter out the selected rows based on the row Id.',
            action: () => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                api.getFilterInstance('id', (ctx: any) => {
                    const ids = selectedRows.map(row => row.id).join(',')
                    ctx.filterBySelection(ids)
                })
            },
        },
        selectedRows.length > 0 && {
            name: 'Share selection',
            icon: '<span class="ag-icon ag-icon-copy" role="presentation"></span>',
            tooltip:
                'Will copy the url with the selected id filter to the clipboard.',
            action: () => {
                const filters = {
                    id: {
                        filterType: 'valueRange',
                        values: selectedRows.map(row => row.id).join(','),
                    },
                }
                const search = qs.stringify(filters, { encode: false })
                const param = new URLSearchParams()
                param.set('filters', search)
                const url = `${window.location.origin}/?${param.toString()}`
                navigator.clipboard.writeText(url)
            },
        },
        selectedRows.length > 0 && 'separator',
        'copy',
        'copyWithHeaders',
        selectedRows.length > 0 && {
            name: 'Copy selected rows',
            icon: '<span class="ag-icon ag-icon-copy" role="presentation"></span>',
            tooltip: 'Copy selected rows to clipboard',
            action: () => {
                api.copySelectedRowsToClipboard()
            },
        },
        selectedRows.length > 0 && {
            name: 'Copy selected rows with headers',
            icon: '<span class="ag-icon ag-icon-copy" role="presentation"></span>',
            tooltip: 'Copy selected rows to clipboard with headers',
            action: () => {
                api.copySelectedRowsToClipboard({ includeHeaders: true })
            },
        },
    ]
}
