import {
    Dialog,
    DialogContent,
    DialogSurface,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    SelectTabData,
    SelectTabEvent,
    Spinner,
    Tab,
    TabList,
    TabValue,
    makeStyles,
    shorthands,
} from '@fluentui/react-components'
import { useEffect, useState } from 'react'

import Conversation from 'components/Messages/Conversation'
import ConversationHistory from 'components/Messages/ConversationHistory'
import ConversationStatusEnum from 'constants/ConversationStatusEnum'
import { Feedback } from 'types/feedback'
import Guidelines from 'components/Messages/Guidelines'
import { ICellRendererParams } from 'ag-grid-community'
import { IConversation } from 'types/conversations'
import Navigation from 'components/Messages/Navigation'
import ReportDetail from 'components/Messages/ReportDetail'
import TwoWayComButton from './TwoWayComButton'
import { useGetMessagesQuery } from 'api/messages'

const useStyles = makeStyles({
    wrapper: {
        maxWidth: '80%',
        width: '900px',
        minHeight: '700px',
        maxHeight: '90%',
        ...shorthands.overflow('auto'),
    },
    content: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        minHeight: '590px',
        gridGap: '16px',
    },
    tabList: {
        width: '400px',
    },
})

const MessageModal = ({
    data,
    api: gridApi,
}: ICellRendererParams<Feedback>) => {
    const [selectedConvo, setSelectedConvo] = useState<IConversation | null>()
    const [open, setOpen] = useState(false)

    const classes = useStyles()

    const { data: conversations = [], isLoading } = useGetMessagesQuery(
        data.id,
        { skip: !open }
    )

    useEffect(() => {
        if (conversations.length > 0) {
            const activeConversations = conversations.filter(
                conversation =>
                    conversation.statusId !== ConversationStatusEnum.Ended
            )
            const sortedConversations = activeConversations.sort(
                (a, b) =>
                    new Date(b.startedDate).getTime() -
                    new Date(a.startedDate).getTime()
            )
            setSelectedConvo(sortedConversations[0])
        }
    }, [conversations])

    const [selectedValue, setSelectedValue] = useState<TabValue>('tab1')

    const onTabSelect = (_event: SelectTabEvent, tab: SelectTabData) => {
        setSelectedValue(tab.value)
    }

    const TWO_WAY_COM_ENABLED = data.reportSource === 'CdpEu'

    const onServerSideChange = () => {
        gridApi.refreshServerSide()
    }

    const hasActiveConversation = () =>
        conversations.some(
            x =>
                x.statusId === ConversationStatusEnum.Created ||
                x.statusId === ConversationStatusEnum.RespondedByDriver ||
                x.statusId === ConversationStatusEnum.RespondedByTrillion ||
                x.statusId === ConversationStatusEnum.ReadByDriver ||
                x.statusId === ConversationStatusEnum.ReadByTrillion
        )

    return (
        <div
            style={{
                display: 'flex',
                placeContent: 'center',
                paddingTop: '4px',
            }}
        >
            <Dialog open={open} onOpenChange={(_e, d) => setOpen(d.open)}>
                <TwoWayComButton
                    disabled={!TWO_WAY_COM_ENABLED}
                    status={data.conversationStatus}
                />
                <DialogSurface className={classes.wrapper}>
                    {(data.vehicleReturned &&
                        new Date(data.vehicleReturned) > new Date()) ||
                    data.vehicleStatus === 'Ended' ? (
                        <MessageBar intent='warning'>
                            <MessageBarBody>
                                <MessageBarTitle>
                                    This vehicle has been returned.
                                </MessageBarTitle>
                                You can no longer send messages.
                            </MessageBarBody>
                        </MessageBar>
                    ) : null}

                    <Navigation
                        item={selectedConvo}
                        toggleOpen={() => setOpen(pre => !pre)}
                        refreshGrid={() => onServerSideChange()}
                    />

                    {isLoading ? (
                        <Spinner label='Loading media...' />
                    ) : (
                        <DialogContent className={classes.content}>
                            <Conversation
                                vehicleStatus={data.vehicleStatus}
                                conversation={selectedConvo}
                                hasActiveConversation={hasActiveConversation()}
                                feedback={data}
                                onNewConversation={() => {
                                    setSelectedConvo(null)
                                }}
                            />
                            <div className={classes.tabList}>
                                <TabList
                                    selectedValue={selectedValue}
                                    onTabSelect={onTabSelect}
                                >
                                    <Tab value='tab1'>Report details</Tab>
                                    <Tab value='tab2'>History</Tab>
                                    <Tab value='tab3'>Guidelines</Tab>
                                </TabList>

                                {selectedValue === 'tab1' && (
                                    <ReportDetail data={data} />
                                )}
                                {selectedValue === 'tab2' && (
                                    <ConversationHistory
                                        items={conversations}
                                        selectedItem={selectedConvo}
                                        handleSelection={conversation =>
                                            setSelectedConvo(conversation)
                                        }
                                    />
                                )}
                                {selectedValue === 'tab3' && <Guidelines />}
                            </div>
                        </DialogContent>
                    )}
                </DialogSurface>
            </Dialog>
        </div>
    )
}

export default MessageModal
